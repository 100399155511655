import { ActionType } from 'typesafe-actions'
import ActionTypes from './const'
import * as appActions from './actions'
import globalActionTypes from 'src/constants'
import { IChats } from './types'

export interface State {
  chats: IChats[]
  messages?: any
  users: any
  currentUser: any
  activeChat?: number
}

const initialState: State = {
  chats: [],
  messages: undefined,
  users: [],
  currentUser: {},
  activeChat: undefined
}

function reducer(state = { ...initialState }, action: ActionType<typeof appActions>) {
  switch (action.type) {
    case ActionTypes.LOAD_CHATS + globalActionTypes.SUCCESS:
      return {
        ...state,
        chats: action.payload.data
      }

    case ActionTypes.LOAD_CURRENT_USER + globalActionTypes.SUCCESS:
      return {
        ...state,
        currentUser: action.payload
      }

    case ActionTypes.LOAD_ALL_CLUB_USERS + globalActionTypes.SUCCESS:
      let newUsers = [...state.users]

      for (let i = 0; i < action.payload.length; i++) {
        if (!state.users?.some((item: any) => item.id === action.payload[i].id)) {
          newUsers.push(action.payload[i]);
        }
      }

      return {
        ...state,
        users: newUsers
      }

    case ActionTypes.LOAD_MESSAGES + globalActionTypes.SUCCESS:
      return {
        ...state,
        messages: action.payload.messages.reverse(),
        activeChat: action.payload.chatId
      }

    case ActionTypes.CREATE_CHAT + globalActionTypes.SUCCESS:
      return {
        ...state,
        chats: [action.payload, ...state.chats],
        messages: [],
        activeChat: action.payload.id
      }

    case ActionTypes.DELETE_CHAT + globalActionTypes.SUCCESS:
      const newChats = state.chats.filter((item: any) => item.id !== action.payload.id)

      return {
        ...state,
        chats: newChats,
        messages: null,
        activeChat: null
      }

    case ActionTypes.CREATE_MESSAGE + globalActionTypes.SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      }

    case ActionTypes.UPDATE_MESSAGE_READ_STATE + globalActionTypes.SUCCESS:

      return {
        ...state,
      }

    default:
      return state
  }
}

export default reducer
