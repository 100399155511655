import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import success from '../assets/images/success/success.png'
import fail from '../assets/images/fail/fail.png'

const StatusField = ({
  source, record = {}, labels = {
    created: 'Incomplete',
    opened: 'Open',
    closed: 'Closed',
    in_progress: 'In progress',
    completed: 'Completed',
    error: 'Error',
    passed: 'passed',
    failed: 'failed',
    uncompleted: 'Incomplete'
  }, colors = {
    created: 'error',
    in_progress: 'warning',
    completed: 'success',
    error: 'error',
    opened: 'error',
    closed: 'success',
    passed: 'success',
    failed: 'error',
    uncompleted: 'error'
  },
  isIcon = false
}: any) => {
  // console.log(record, 'record[source]')
  if (isIcon) {
    return <div style={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    }
    }>
      <img
        src={colors[record[source]] !== 'success'
          ? record[source] === true
            ? success
            : fail
          : success}
        style={{ width: '20px', height: '20px' }}
        alt={'alt'}
      />
    </div >
  } else {
    return (<Typography variant={'body2'} color={colors[record[source]] || ''}>{labels[record[source]] || ''}</Typography>)
  }
}

StatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isIcon: PropTypes.bool
}

export default StatusField
