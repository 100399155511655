import React, { ReactNode } from 'react'
import { useMediaQuery } from '@material-ui/core'

interface Props {
  children: ReactNode
}

function Container(props: Props) {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const width = isXSmall ? 'calc(100vw - 32px)' : 'calc(100vw - 330px)'

  return (
    <div style={{ margin: '0 auto'}}>
      <div style={{ overflow: 'auto', width: width, padding: '0 16px' }}>
        {props.children}
      </div>
    </div>
  )
}

export default Container
