import React from 'react'
import { TableCell, IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { grey, green, red } from '@material-ui/core/colors'
import { isFuture, isToday } from 'date-fns'
import { IPerson } from 'src/types'
import CellSpinner from 'src/components/CellSpinner'

interface Props {
  cleaner?: IPerson
  inspector?: IPerson
  date: Date
  spinner?: boolean
  onAdd: () => void
  onRemove: () => void
  isActive?: boolean
}

CleanersCell.defaultProps = {
  isActive: true
};

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

function CleanersCell(props: Props) {
  let bg = props.cleaner ? green[100] : props.inspector ? red[100] : ''

  if (props.spinner) {
    bg = grey[200]
  }

  return (
    <TableCell style={{ border: 'none', minWidth: 100, background: bg, paddingRight: 5, paddingLeft: 10 }}>
      {props.spinner ? (
        <CellSpinner />
      ) : (
        <>
          {props.cleaner && (
            <div style={styles.row}>
              <div>{props.cleaner.name}</div>
              <IconButton onClick={props.onRemove} size="small" style={{ color: red[500] }}>
                <HighlightOffIcon />
              </IconButton>
            </div>
          )}
          {props.inspector && (
            <div style={styles.row}>
              <div>{props.inspector.name}</div>
              <IconButton onClick={props.onRemove} size="small" style={{ color: red[500] }}>
                <HighlightOffIcon />
              </IconButton>
            </div>
          )}
          {!props.cleaner && !props.inspector && (isFuture(props.date) || isToday(props.date)) && (
            <>
              {props.isActive ? <IconButton onClick={props.onAdd} size="small" style={{ color: green[500] }}>
                <ControlPointIcon />
              </IconButton> : <></>}
            </>
          )}
        </>
      )}
    </TableCell>
  )
}

export default CleanersCell
