import { stringify } from "querystring";
import React from "react";
import { Admin, Resource } from "react-admin";
import { AccountCreate, AccountEdit, AccountList } from "src/resources/account";
import AssignCleanersPage from "src/resources/AssignCleanersPage";
import { ClubSupplyCreate, ClubSupplyEdit } from "src/resources/clubSupply";
import DashboardPage from "src/resources/DashboardPage";
import { HistoryList, HistoryShow } from "src/resources/inspections";
import {
  IssueCreate,
  IssueEdit,
  IssueList,
  IssueShow,
} from "src/resources/issue";
import {
  SupplierCreate,
  SupplierEdit,
  SupplierList,
} from "src/resources/supplier";
import { SupplyCreate, SupplyEdit, SupplyList } from "src/resources/supply";
import {
  SupplyRequestEdit,
  SupplyRequestList,
  SupplyRequestShow,
} from "src/resources/supplyRequest";
import {
  TimeTrackingCheckInTimesList,
  TimeTrackingCreate,
  TimeTrackingEarlyCheckoutList,
  TimeTrackingLateCheckinList,
  TimeTrackingOutOfBoundsList,
  timeTrackingShow,
} from "src/resources/timeTracking";
import customRoutes from "./customRoutes";
import customSagas from "./sagas";
import customReducers from "./reducers";
import { history } from "./history";
import "./index.css";
import dataProvider from "./common/dataProvider";
import MyLayout from "./MyLayout";
import MyLoginPage from "./resources/login";
import { AuthProvider } from "./common/AuthProvider";
import { UserCreate, UserEdit, UserList } from "./resources/users";
import { ClubCreate, ClubEdit, ClubList, ClubShow } from "./resources/clubs";
import BusinessIcon from "@material-ui/icons/Business";
import {
  CleaningListCreate,
  CleaningListEdit,
  CleaningListShow,
  CleaningList,
} from "./resources/cleaningList";
import {
  InspectionStepCreate,
  InspectionStepEdit,
} from "./resources/inspectionStep";
import {
  CleaningTaskStepCreate,
  CleaningTaskStepEdit,
} from "./resources/cleaningTaskStep";
import { CleaningListTaskShow } from "./resources/cleaningListTask/CleaningListTaskShow";
import {
  CleaningListTaskCreate,
  CleaningListTaskEdit,
} from "./resources/cleaningListTask";
import { theme } from "src/style";
import Clubs from "./components/clubs/Clubs";
import Users from "./components/users/Users";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import SearchIcon from "@material-ui/icons/Search";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import WarningIcon from "@material-ui/icons/Warning";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import MailIcon from "@material-ui/icons/Mail";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import { Messages } from "./resources/Messages";
/*const dataProvider = fakeDataProvider({
  'browser-session': [
    { id: 0, title: 'Hello, world!' },
    { id: 1, title: 'FooBar' },
  ],
  'import': [
    { id: 0, title: 'Hello, world!' },
    { id: 1, title: 'FooBar' },
  ]
})
*/
// const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

// const convertFileToBase64 = (file: any) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file.rawFile);

//     reader.onload = () => resolve(reader.result);
//     reader.onerror = reject;
//   });

const App = () => {
  return (
    <Admin
      history={history()}
      theme={theme}
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      customRoutes={customRoutes}
      customSagas={customSagas}
      customReducers={customReducers}
      loginPage={MyLoginPage}
      layout={MyLayout}
    >
      <Clubs />
      <Users />
      <Resource path={"country"} name="country" />

      <Resource
        path={"dashboard"}
        name="dashboard"
        list={DashboardPage}
        icon={InsertChartIcon}
        options={{ label: "Dashboard" }}
      />
      <Resource
        path={"user"}
        name="user"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={EmojiPeopleIcon}
        options={{ label: "Users" }}
      />
      <Resource
        path={"account"}
        name="account"
        list={AccountList}
        edit={AccountEdit}
        create={AccountCreate}
        options={{ hidden: true }}
      />
      <Resource
        path={"club-supply"}
        name="club-supply"
        edit={ClubSupplyEdit}
        create={ClubSupplyCreate}
      />
      <Resource
        path={"club"}
        name="club"
        list={ClubList}
        create={ClubCreate}
        show={ClubShow}
        edit={ClubEdit}
        icon={FitnessCenterIcon}
        options={{
          label: "Clubs",
          items: [
            {
              name: "Clubs",
              to: {
                pathname: "/club",
                search: stringify({
                  page: 1,
                  perPage: 100,
                  sort: "name",
                  order: "ASC",
                  filter: JSON.stringify({
                    type: "club",
                    isTemplate: false,
                  }),
                  type: "club",
                }),
              },

              icon: FitnessCenterIcon,
            },
            {
              name: "Company settings",
              to: {
                pathname: "/account",
              },
              icon: BusinessIcon,
            },
          ],
        }}
      />

      <Resource
        path={"cleaning-list-task"}
        name="cleaning-list-task"
        create={CleaningListTaskCreate}
        edit={CleaningListTaskEdit}
        show={CleaningListTaskShow}
      />
      <Resource
        path={"cleaning-task-step"}
        name="cleaning-task-step"
        create={CleaningTaskStepCreate}
        edit={CleaningTaskStepEdit}
      />
      <Resource
        path={"inspection-step"}
        name="inspection-step"
        create={InspectionStepCreate}
        edit={InspectionStepEdit}
      />
      <Resource
        path={"cleaning-list"}
        name="cleaning-list"
        list={CleaningList}
        create={CleaningListCreate}
        edit={CleaningListEdit}
        show={CleaningListShow}
        options={{
          label: "Checklists",
          items: [
            {
              name: "Cleaning checklist",
              to: {
                pathname: "/cleaning-list",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({
                    type: "cleaning",
                    isTemplate: false,
                  }),
                  type: "cleaning",
                }),
              },
            },
            {
              name: "Inspection checklist",
              to: {
                pathname: "/cleaning-list",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({
                    type: "inspection",
                    isTemplate: false,
                  }),
                  type: "inspection",
                }),
              },
            },
            {
              name: "One Off checklist",
              to: {
                pathname: "/cleaning-list",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({
                    type: "maintenance",
                    isTemplate: false,
                  }),
                  type: "maintenance",
                }),
              },
            },
            {
              name: "Templates",
              to: {
                pathname: "/cleaning-list",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({ isTemplate: true }),
                  // type: "template",
                }),
              },
            },
          ],
          ad: 1,
        }}
      ></Resource>
      <Resource path={"cleaning-list-job-task"} name="cleaning-list-job-task" />
      <Resource
        path={"cleaning-list-job-check"}
        name="cleaning-list-job-check"
      />
      <Resource path={"inspection-step-job"} name="inspection-step-job" />
      <Resource
        path={"cleaning-list-job"}
        name="cleaning-list-job"
        list={HistoryList}
        show={HistoryShow}
        icon={SearchIcon}
        options={{
          label: "History",
          items: [
            {
              name: "Cleaning checklist",
              to: {
                pathname: "/cleaning-list-job",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({
                    "cleaningList.type||$in": ["cleaning"],
                  }),
                  type: "cleaning",
                }),
              },
              icon: SearchIcon,
            },
            {
              name: "Inspection checklist",
              to: {
                pathname: "/cleaning-list-job",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({
                    "cleaningList.type||$in": ["inspection", "cleaning"],
                  }),
                  type: "inspection",
                }),
              },
              icon: SearchIcon,
            },
            {
              name: "One off checklist",
              to: {
                pathname: "/cleaning-list-job",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({
                    "cleaningList.type||$in": ["maintenance"],
                  }),
                  type: "maintenance",
                }),
              },
              icon: SearchIcon,
            },
            {
              name: "Templates",
              to: {
                pathname: "/cleaning-list-job",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({
                    '"cleaningList".type||$in': ["template"],
                  }),
                  type: "template",
                }),
              },
              icon: SearchIcon,
            },
          ],
        }}
      />
      <Resource
        path={"history"}
        name="history"
        icon={SearchIcon}
        options={{ label: "History" }}
        show={HistoryShow}
      />

      <Resource
        path={"time-tracking/checkin-times"}
        name="time-tracking/checkin-times"
        icon={SearchIcon}
        list={TimeTrackingCheckInTimesList}
        options={{ hidden: true }}
        show={timeTrackingShow}
        create={TimeTrackingCreate}
      />
      <Resource
        path={"time-tracking/late-checkins"}
        name="time-tracking/late-checkins"
        icon={SearchIcon}
        list={TimeTrackingLateCheckinList}
        options={{ hidden: true }}
        show={timeTrackingShow}
        create={TimeTrackingCreate}
      />
      <Resource
        path={"time-tracking/early-checkouts"}
        name="time-tracking/early-checkouts"
        icon={SearchIcon}
        list={TimeTrackingEarlyCheckoutList}
        options={{ hidden: true }}
        show={timeTrackingShow}
        create={TimeTrackingCreate}
      />
      <Resource
        path={"time-tracking/out-of-bounds"}
        name="time-tracking/out-of-bounds"
        icon={SearchIcon}
        list={TimeTrackingOutOfBoundsList}
        options={{ hidden: true }}
        show={timeTrackingShow}
        create={TimeTrackingCreate}
      />

      <Resource
        path={"scheduling"}
        name="scheduling"
        list={AssignCleanersPage}
        icon={WatchLaterIcon}
        options={{ label: "Scheduling" }}
      />

      <Resource
        path={"issue"}
        name="issue"
        create={IssueCreate}
        edit={IssueEdit}
        list={IssueList}
        show={IssueShow}
        icon={WarningIcon}
        options={{
          label: "Issues",
          items: [
            {
              name: "Report an issue",
              to: {
                pathname: "/issue/create",
              },
            },
            {
              name: "Open issues",
              to: {
                pathname: "/issue",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({ status: "opened" }),
                  type: "opened",
                }),
              },
            },
            {
              name: "Closed issues",
              to: {
                pathname: "/issue",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({ status: "closed" }),
                  type: "closed",
                }),
              },
            },
          ],
        }}
      />

      <Resource
        path={"Time tracking"}
        name="time-tracking"
        icon={WatchLaterIcon}
        list={TimeTrackingCheckInTimesList}
        show={timeTrackingShow}
        create={TimeTrackingCreate}
        options={{
          label: "Time Tracking",
          items: [
            {
              name: "Check-in Times",
              to: {
                pathname: "/time-tracking/checkin-times",
              },
            },
            {
              name: "Late check-ins",
              to: {
                pathname: "/time-tracking/late-checkins",
              },
            },
            {
              name: "Early check-outs",
              to: {
                pathname: "/time-tracking/early-checkouts",
              },
            },
            {
              name: "Out of bounds",
              to: {
                pathname: "/time-tracking/out-of-bounds",
              },
            },
          ],
        }}
      />

      <Resource
        path={"supplier"}
        name="supplier"
        create={SupplierCreate}
        edit={SupplierEdit}
        list={SupplierList}
        icon={ShoppingBasketIcon}
        options={{ label: "Supplier", hidden: true }}
      />
      <Resource
        path={"supply-request"}
        name="supply-request"
        edit={SupplyRequestEdit}
        list={SupplyRequestList}
        show={SupplyRequestShow}
        icon={ShoppingBasketIcon}
        options={{ label: "Supply requests", hidden: true }}
      />
      <Resource path={"supply-request-item"} name="supply-request-item" />

      <Resource
        path={"supply"}
        name="supply"
        create={SupplyCreate}
        edit={SupplyEdit}
        list={SupplyList}
        icon={ShoppingBasketIcon}
        options={{
          label: "Supplies",
          items: [
            {
              name: "Suppliers",
              to: {
                pathname: "/supplier",
              },
            },
            {
              name: "Supplies",
              to: {
                pathname: "/supply",
              },
            },
            {
              name: "Open Supply Requests",
              to: {
                pathname: "/supply-request",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({ status: "opened" }),
                }),
              },
            },
            {
              name: "Closed Supply Requests",
              to: {
                pathname: "/supply-request",
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: "id",
                  order: "DESC",
                  filter: JSON.stringify({ status: "closed" }),
                }),
              },
            },
          ],
        }}
      ></Resource>

      <Resource
        path={"message"}
        name="messages"
        list={Messages}
        icon={MailIcon}
        options={{ label: "Messages" }}
      />
    </Admin>
  );
};

export default App;
