import { takeLatest, put, takeEvery } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'

import requestGen from "src/utils/requestGen";
import ActionTypes from './const'
import { createChat, createMessage, deleteChat, loadAllClubUsers, loadChats, loadCurrentUser, loadMessages, updateMessageReadState } from "./actions";
import globalActionTypes from 'src/constants'

const queryString = require('query-string')

function* chatSaga() {
  yield takeLatest(ActionTypes.LOAD_CHATS, function* (action: ActionType<typeof loadChats>) {
    try {
      const payload = action.payload;

      const res = yield requestGen({
        url: `/api/chat?${queryString.stringify(payload)}`,
        method: 'GET',
      })

      yield put({
        type: ActionTypes.LOAD_CHATS + globalActionTypes.SUCCESS,
        payload: res.data
      })
    } catch (err) {
      yield put({
        type: ActionTypes.LOAD_CHATS + globalActionTypes.FAIL,
        payload: err
      })
    }
  })

  yield takeEvery(ActionTypes.LOAD_ALL_CLUB_USERS, function* (action: ActionType<typeof loadAllClubUsers>) {
    try {
      const payload = action.payload;

      const res = yield requestGen({
        url: `/api/user?clubId=${payload.clubId}`,
        method: 'GET',
      })

      const deniedRole = payload.role === 'cleaner' || payload.role === 'maintenance'
        ? ['areaManager', 'owner', 'superAdmin', 'manager', 'assistantManager', 'clubStaff']
        : payload.role === 'clubStaff'
          ? ['owner', 'superAdmin']
          : payload.role === 'superAdmin'
            ? [] : ['superAdmin']

      const tempResult = res.data.data?.filter((item: any) => item.role !== deniedRole?.[0] && item.role !== deniedRole?.[1] && item.role !== deniedRole?.[2] && item.role !== deniedRole?.[3] && item.role !== deniedRole?.[4] && item.role !== deniedRole?.[5])

      const result = tempResult.map((item: any) => { return { ...item, clubId: payload.clubId } })

      yield put({
        type: ActionTypes.LOAD_ALL_CLUB_USERS + globalActionTypes.SUCCESS,
        payload: result
      })
    } catch (err) {
      yield put({
        type: ActionTypes.LOAD_ALL_CLUB_USERS + globalActionTypes.FAIL,
        payload: err
      })
    }
  })

  yield takeLatest(ActionTypes.LOAD_CURRENT_USER, function* (action: ActionType<typeof loadCurrentUser>) {
    try {
      const res = yield requestGen({
        url: `/api/user/me`,
        method: 'GET',
      })

      yield put({
        type: ActionTypes.LOAD_CURRENT_USER + globalActionTypes.SUCCESS,
        payload: res.data
      })
    } catch (err) {
      yield put({
        type: ActionTypes.LOAD_CURRENT_USER + globalActionTypes.FAIL,
        payload: err
      })
    }
  })

  yield takeLatest(ActionTypes.LOAD_MESSAGES, function* (action: ActionType<typeof loadMessages>) {
    try {
      const payload = action.payload;

      const res = yield requestGen({
        url: `/api/chat/${payload.chatId}${payload.fromThisChat ? '' : '/messages'}`,
        method: 'GET',
      })

      yield put({
        type: ActionTypes.LOAD_MESSAGES + globalActionTypes.SUCCESS,
        payload: {
          messages: payload.fromThisChat
            ? res.data.messages.data
            : res.data.data,

          chatId: payload.fromThisChat
            ? res.data.id
            : res.data?.data?.[0]?.chatId
              ? res.data?.data?.[0]?.chatId
              : payload.chatId
        }
      })
    } catch (err) {
      yield put({
        type: ActionTypes.LOAD_MESSAGES + globalActionTypes.FAIL,
        payload: err
      })
    }
  })

  yield takeLatest(ActionTypes.CREATE_CHAT, function* (action: ActionType<typeof createChat>) {
    try {
      const payload = action.payload;
      console.log('payload: ', payload)
      const res = yield requestGen({
        url: `/api/chat`,
        method: 'POST',
        data: { ...payload.chatInfo },
      })

      yield put({
        type: ActionTypes.CREATE_CHAT + globalActionTypes.SUCCESS,
        payload: res.data
      })

      if (payload.callback) {
        payload.callback(res.data)
      }
    } catch (err) {
      yield put({
        type: ActionTypes.CREATE_CHAT + globalActionTypes.FAIL,
        payload: err
      })
    }
  })

  yield takeLatest(ActionTypes.DELETE_CHAT, function* (action: ActionType<typeof deleteChat>) {
    try {
      const payload = action.payload;

      const res = yield requestGen({
        url: `/api/chat/${payload}`,
        method: 'DELETE',
      })

      yield put({
        type: ActionTypes.DELETE_CHAT + globalActionTypes.SUCCESS,
        payload: res.data
      })
    } catch (err) {
      yield put({
        type: ActionTypes.DELETE_CHAT + globalActionTypes.FAIL,
        payload: err
      })
    }
  })

  yield takeLatest(ActionTypes.CREATE_MESSAGE, function* (action: ActionType<typeof createMessage>) {
    try {
      const payload = action.payload;

      const res = yield requestGen({
        url: '/api/chat/messages',
        method: 'POST',
        data: {
          chatId: payload.chatId,
          message: payload.newMessage,
          files: payload.files
        }
      })

      yield put({
        type: ActionTypes.CREATE_MESSAGE + globalActionTypes.SUCCESS,
        payload: res.data
      })
    } catch (err) {
      yield put({
        type: ActionTypes.CREATE_MESSAGE + globalActionTypes.FAIL,
        payload: err
      })
    }
  })

  yield takeLatest(ActionTypes.UPDATE_MESSAGE_READ_STATE, function* (action: ActionType<typeof updateMessageReadState>) {
    try {
      const payload = action.payload;

      const res = yield requestGen({
        url: '/api/chat/messages/state',
        method: 'POST',
        data: { ids: payload, read: true }
      })

      yield put({
        type: ActionTypes.UPDATE_MESSAGE_READ_STATE + globalActionTypes.SUCCESS,
        payload: res.data
      })
    } catch (err) {
      yield put({
        type: ActionTypes.UPDATE_MESSAGE_READ_STATE + globalActionTypes.FAIL,
        payload: err
      })
    }
  })

}

export default chatSaga
