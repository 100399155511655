import ActionTypes from './const'
import { action } from 'typesafe-actions'
import { IClub } from './types'
import { stringify } from "querystring";
export const fetchList = (userId: any) => action(ActionTypes.FETCH_LIST, {
  api: `/admin/api/club?${stringify({
    sort: ['name,ASC'],
    filter: `_users.id||$eq||${userId}`
  })}`,
})

export const setFilter = (club: IClub) => action(ActionTypes.SET_FILTER, club)
