import React from 'react'
import {
  List,
  Datagrid,
  Filter,
  SelectInput,
  Show,
  ReferenceManyField,
  ReferenceField,
  SimpleShowLayout,
  DateField,
  TextField,
  TextInput,
  ReferenceInput,
  DateInput,
  useListParams,
  Pagination,
} from 'react-admin'
import { useLocation } from 'react-router-dom';
import ListActions from 'src/components/ListActions'
import EmptyList from 'src/components/EmptyList'
import NameWithImageField from "src/components/NameWithImageField";
import RelatedList from "src/components/RelatedList";
import S3ImageField from "src/components/S3ImageField";
import ScoreField from "src/components/ScoreField";
import StatusField from "src/components/StatusField";
import DurationField from 'src/components/DurationField';

const CleaningFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="cleaningList.name||$contL" alwaysOn={true} variant={'outlined'} fullWidth={true} label={'Search'} resettable={true} />

    <DateInput source="scheduleDate||lte" label="Older than" />
    <DateInput source="scheduleDate||gte" label="Newer than" />

    <ReferenceInput label="Club" source="cleaningList.clubId" reference="club" variant={'outlined'} fullWidth={true}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Cleaner" source="cleanerId" reference="user" variant={'outlined'} fullWidth={true}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Inspector" source="inspectorId" reference="user" variant={'outlined'} fullWidth={true}>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)


const TitleList = (props: any) => {
  const location = useLocation();
  const [query] = useListParams({ ...props, location });
  const { filterValues } = query;
  const type = filterValues && filterValues['"cleaningList".type||$in'] ? filterValues['"cleaningList".type||$in'][0] : null;
  const TitleMap = {
    'cleaning': 'Cleaning Checklist History',
    'inspection': 'Inspection Checklist History',
    'maintenance': 'One Off Checklist History'
  }

  return (
    <span>
      {TitleMap[type] ? TitleMap[type] : 'History'}
    </span>
  )
}

export const HistoryList = (props: any) => {
  const location = useLocation();

  return (
    <List
      {...props}
      title={<TitleList {...props} />}
      filters={<CleaningFilter />}
      actions={<ListActions showCreate={false} />}
      sort={{ field: 'scheduleDate', order: 'DESC' }}
      bulkActionButtons={false}
      empty={(
        <EmptyList title={'Cleanings not found'} description={'You can add a Cleaning'} buttonText={'Create Cleaning'} />
      )}
    >
      <Datagrid rowClick={(id: number) => `cleaning-list-job/${id}/show?type=${new URLSearchParams(location.search).get('type')}`}>
        <ReferenceField label="Club" source="cleaningList.clubId" reference="club" linkType={false}>
          <NameWithImageField isLink={false} />
        </ReferenceField>
        <TextField source="cleaningList.name" label={'Checklist'} />
        <StatusField source="status" label={'Status'} />
        <DateField source={'scheduleDate'} label={'Date'} />
        <ScoreField source={'inspectionScore'} label={'Inspection Score'} />
        <StatusField source="inspectionStatus" label={'Inspection'} />

        <ReferenceField label="Cleaner" source="cleanerId" reference="user" linkType={false}>
          <NameWithImageField isLink={false} />
        </ReferenceField>
        <ReferenceField label="Inspector" source="inspectorId" reference="user" linkType={false}>
          <NameWithImageField isLink={false} />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      History for {record.cleaningList.name} {record.scheduleDate}
    </span>
  )
}

export const HistoryShow = (props: any) => {
  const location = useLocation();
  const listType = new URLSearchParams(location.search).get('type');

  return (
    <Show {...props} title={<TitleEdit />}>
      <SimpleShowLayout>
        {/* {listType === 'inspection' &&
          <RoundGraph source={""} />
        } will be later */}

        <ReferenceField label="Club" source="cleaningList.clubId" reference="club">
          <NameWithImageField />
        </ReferenceField>
        <TextField source="cleaningList.name" label={'Checklist'} />
        <StatusField source="status" label={'Status'} />
        <DateField source={'scheduleDate'} label={'Date'} />

        <ReferenceManyField reference="cleaning-list-job-task" target="cleaningListJobId" addLabel={false} pagination={<Pagination />} perPage={25}>
          <RelatedList
            expand={<HistoryItemShow />}
            emptyTitle={'Checklist Tasks empty'}
            hasCustomCreate={false}>
            <S3ImageField source="files" src={'path'} label={'Image'} />

            <TextField source="cleaningListTask.name" label={'Task name'} />

            {listType !== 'inspection' &&
              <StatusField source="inspectionStatus" label={'Status'} />
            }

            {listType !== 'inspection' &&
              <StatusField source="hasInspection" label={'Has inspection'} isIcon={true} />
            }

            {listType !== 'inspection' &&
              <DurationField sourceStartTime="startTime" sourceEndTime='endTime' label={'Duration'} />
            }

            <StatusField source={'inspectionStatus'} label={'Inspection result'} isIcon={true} />

            {listType === 'cleaning' &&
              <ReferenceField label="Cleaner" source="cleanerId" reference="user">
                <NameWithImageField />
              </ReferenceField>
            }

            {listType === 'maintenance' &&
              <ReferenceField label="Maintenance" source="cleanerId" reference="user">
                <NameWithImageField />
              </ReferenceField>
            }

            <ReferenceField label="Inspector" source="inspectorId" reference="user">
              <NameWithImageField />
            </ReferenceField>

          </RelatedList>

        </ReferenceManyField>

      </SimpleShowLayout>
    </Show>
  )
}

export const HistoryItemShow = (props: any) => (
  <Show {...props} >
    <SimpleShowLayout>

      <ReferenceManyField reference="inspection-step-job" target="cleaningListJobTaskId" addLabel={false} pagination={<Pagination />} perPage={50}>
        <RelatedList
          emptyTitle={'Inspections steps history empty'}
          hasCreate={false}>

          <TextField source="inspectionStep.name" label={'Inspection Step Name'} />
          <StatusField source="status" label={'Status'} />
          <DateField showTime={true} source="createdAt" label={'Time'} />
          <ReferenceField label="Inspector" source="inspectorId" reference="user">
            <NameWithImageField />
          </ReferenceField>
        </RelatedList>

      </ReferenceManyField>
      <ReferenceManyField reference="issue" target="jobTaskId" addLabel={false} pagination={<Pagination />} perPage={50}>
        <RelatedList
          emptyTitle={'Issues empty'}
          hasCreate={false}>
          <S3ImageField source="files" src={'path'} label={'Image'} />
          <TextInput source="spotName" label={'Issue Location'} />
          <StatusField source="status" label={'Status'} />

          <ReferenceInput label="Responsable person" source="responsableId" reference="user" variant={'outlined'}
            fullWidth={true}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput label="Author" source="authorId" reference="user" variant={'outlined'} fullWidth={true}>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </RelatedList>

      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)
