import React from 'react'
import { useSelector } from "react-redux";
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer, Tooltip } from 'recharts'
import { AppState } from "src/types";
import DashboardCard from './DashboardCard'
import { colors } from 'src/style'


interface Props {
  title: string
}

interface TooltipProps {
  active: boolean,
  payload: any,
  label: string
}

function IssuesByLocation(props: Props) {
  const dashboard = useSelector((state: AppState) => state.dashboard)

  return (
    <DashboardCard title={props.title}>
      <ResponsiveContainer aspect={2.3}>
        <BarChart data={dashboard.totalIssuesByLocation.map((item) => { return { ...item, count: parseInt(item.count) } })} layout="vertical" barSize={10}>
          <XAxis type="number" dataKey="count" interval={0} padding={{ right: 10 }} />
          <YAxis dataKey="clubName" type="category" tick={{ fontSize: 12 }} width={100} interval={0} />
          <Tooltip />
          <Bar dataKey="count" fill={colors.primary} />
        </BarChart>
      </ResponsiveContainer>
    </DashboardCard>
  )
}

export default IssuesByLocation
