import React from "react";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Show,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  TextInput,
  ReferenceInput,
  ImageField,
  useListParams,
  Pagination,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import { useLocation } from "react-router-dom";
import ListActions from "src/components/ListActions";
import EmptyList from "src/components/EmptyList";
import NameWithImageField from "src/components/NameWithImageField";
import RelatedList from "src/components/RelatedList";
import S3ImageInput from "src/components/S3FileUpload/S3ImageUpload";
import StatusField from "src/components/StatusField";

const CleaningFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="status"
      label={"Status"}
      choices={[
        { id: "opened", name: "Open" },
        { id: "closed", name: "Closed" },
      ]}
    />
    <ReferenceInput
      label="Club"
      source="clubId"
      reference="club"
      variant={"outlined"}
      fullWidth={true}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="spotName" label={"Issue Location"} />

    <ReferenceInput
      label="Responsable person"
      source="responsableId"
      reference="user"
      variant={"outlined"}
      fullWidth={true}
      style={{ width: "200px" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Author"
      source="authorId"
      reference="user"
      variant={"outlined"}
      fullWidth={true}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
const TitleList = (props: any) => {
  const location = useLocation();
  const [query] = useListParams({ ...props, location });
  const { filterValues } = query;
  const type = filterValues && filterValues.status ? filterValues.status : null;
  const TitleMap = {
    opened: "Open Issues",
    closed: "Closed Issues",
  };
  // console.log("TitleList", type, filterValues)

  return <span>{TitleMap[type] ? TitleMap[type] : "Issues"}</span>;
};
export const IssueList = (props: any) => {
  const location = useLocation();
  return (
    <List
      {...props}
      title={<TitleList {...props} />}
      filters={<CleaningFilter />}
      actions={<ListActions />}
      sort={{ field: "createdAt", order: "DESC" }}
      bulkActionButtons={false}
      empty={
        <EmptyList
          title={"Cleanings not found"}
          description={"You can add a Cleaning"}
          buttonText={"Create Cleaning"}
        />
      }
    >
      <Datagrid
        rowClick={(id: number) =>
          `issue/${id}/edit?type=${new URLSearchParams(location.search).get(
            "type"
          )}`
        }
      >
        <TextField source="id" />
        <NameWithImageField
          source={"clubId"}
          sourceName={"club.name"}
          sourceImage={"club.avatar"}
          isLink={false}
        />
        <TextField source="spotName" label={"Issue Location"} />
        <StatusField source="status" label={"Status"} />
        <NameWithImageField
          source={"responsableId"}
          sourceName={"responsable.name"}
          sourceImage={"responsable.avatar"}
          isLink={false}
        />
        <NameWithImageField
          source={"authorId"}
          sourceName={"author.name"}
          sourceImage={"author.avatar"}
          isLink={false}
        />

        <DateField source={"createdAt"} label={"Created"} showTime />
      </Datagrid>
    </List>
  );
};

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Issue {record.spotName} {record.createAt}
    </span>
  );
};

export const IssueEdit = (props: any) => {
  const location = useLocation();

  return (
    <Edit {...props} title={<TitleEdit />} undoable={false}>
      <SimpleForm
        redirect={"list"}
        initialValues={{
          status: `${new URLSearchParams(location.search).get("type")}`,
        }}
      >
        <ReferenceInput
          label="Club"
          source="clubId"
          reference="club"
          variant={"outlined"}
          fullWidth={true}
          filter={{ isGroup: false }}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="spotName"
          label={"Issue Location"}
          variant={"outlined"}
          fullWidth={true}
        />

        <ReferenceArrayInput
          label="Responsable Person"
          source="responsable"
          reference="user"
          variant={"outlined"}
          fullWidth={true}
          sort={{ field: "firstName", order: "ASC" }}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <SelectInput
          source="status"
          label={"Status"}
          choices={[
            { id: "opened", name: "Open" },
            { id: "closed", name: "Closed" },
          ]}
          variant={"outlined"}
          fullWidth={true}
        />

        <TextInput
          multiline
          source="description"
          label={"Description"}
          variant={"outlined"}
          fullWidth={true}
        />

        <S3ImageInput
          source={`photos`}
          fileCoverImg="someImgURL"
          label={"Image"}
          multiple={true}
          uploadOptions={{
            multiple: true,
          }}
        >
          <ImageField source="src" />
        </S3ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const IssueCreate = (props: any) => {
  return (
    <Create title="Report an Issue" {...props}>
      <SimpleForm redirect={"list"} initialValues={{ status: "opened" }}>
        <ReferenceInput
          label="Club"
          source="clubId"
          reference="club"
          variant={"outlined"}
          fullWidth={true}
          filter={{ isGroup: false }}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <TextInput
          source="spotName"
          label={"Issue Location"}
          variant={"outlined"}
          fullWidth={true}
        />

        <ReferenceArrayInput
          label="Responsable Person"
          source="responsable"
          reference="user"
          variant={"outlined"}
          fullWidth={true}
          sort={{ field: "firstName", order: "ASC" }}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <SelectInput
          source="status"
          label={"Status"}
          choices={[
            { id: "opened", name: "Open" },
            { id: "closed", name: "Closed" },
          ]}
          variant={"outlined"}
          fullWidth={true}
        />

        <TextInput
          multiline
          source="description"
          label={"Description"}
          variant={"outlined"}
          fullWidth={true}
        />

        <S3ImageInput
          source="photos"
          fileCoverImg="someImgURL"
          label={"Image"}
          multiple={true}
          uploadOptions={{
            multiple: true,
          }}
        >
          <ImageField source="src" />
        </S3ImageInput>
      </SimpleForm>
    </Create>
  );
};

export const IssueShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" />
      </Tab>
      <Tab label="tasks" path="tasks">
        <ReferenceManyField
          reference="cleaning-list-task"
          target="cleaningListId"
          addLabel={false}
          pagination={<Pagination />}
          perPage={50}
        >
          <RelatedList
            rowClick={"show"}
            emptyTitle={"Checklist Tasks empty"}
            createButtonLabel={"Create cleaning list task"}
            createButtonTo={
              props.id
                ? `/cleaning-list-task/create?cleaningListId=${props.id}`
                : null
            }
          >
            <TextField source="name" label={"Title"} />
            <EditButton />
          </RelatedList>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
