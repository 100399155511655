import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'src/types'
import { setFilter } from 'src/components/clubs/actions'

interface Props { onChange?: Function }

function ClubFilter(props: Props) {
  const clubsData = useSelector((state: AppState) => state.clubs)
  const dispatch = useDispatch();

  return (
    <FormControl fullWidth>
      <InputLabel>Select Gym</InputLabel>
      <Select
        value={clubsData.clubFilter?.id}
        onChange={(e) => {
          const id = e?.target?.value as number

          props?.onChange(id)

          if (id) {
            dispatch(setFilter(clubsData.list.find(item => item.id === id)))
          } else {
            dispatch(setFilter(null))
          }
        }}
      >
        <MenuItem value={null}>Show all</MenuItem>
        {clubsData.list.sort((a, b) => a.name.localeCompare(b.name)).map(club => (
          <MenuItem key={club.id} value={club.id}>{club.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ClubFilter
