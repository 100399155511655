import React from 'react'
import PropTypes from 'prop-types'

const DistanceField = ({
  source, record = {},
}: any) => {
  const value = parseInt(record[source]);
  return (
    <>
      {value > 0 ? (value / 1000) : null}
    </>)
}
DistanceField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default DistanceField
