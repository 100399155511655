import React, { useEffect, useState } from "react";
import {
  required,
  ReferenceInput,
  RadioButtonGroupInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import {
  assignPeriodClose,
  assignPeriodStart,
} from "src/resources/AssignCleanersPage/actions";
import { AppState } from "src/types";
import { Dialog, DialogTitle } from "@material-ui/core";
import ModalForm from "src/components/ModalForm";
import { DateInput } from "src/components/DateInput";
import { dateToStr } from "src/utils/dateUtil";
interface Props {}

const customValidation = (value: any) => {
  if (!value || value === "") {
    return "This field is required";
  }
  return undefined;
};

function AssignModal(props: Props) {
  const dispatch = useDispatch();
  const {
    assignOpen,
    assignPeriodSaving,
    assignPeriodListId,
    isInspectionChosen,
    clubIdForModal,
  } = useSelector((state: AppState) => state.assignCleaners);

  const [radioBtnValue, setRadioBtnValue] = useState("");

  useEffect(() => {
    if (isInspectionChosen === true) {
      setRadioBtnValue("inspector");
    } else if (isInspectionChosen === false) {
      setRadioBtnValue("cleaner");
    }
    // eslint-disable-next-line
  }, [assignOpen]);

  const handleCloseClick = () => {
    dispatch(assignPeriodClose());
  };
  const handleSave = (values: any) => {
    dispatch(assignPeriodStart(assignPeriodListId, values));
  };

  return (
    <Dialog
      open={assignOpen}
      onClose={handleCloseClick}
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle>Assign</DialogTitle>
      <ModalForm
        handleClose={handleCloseClick}
        save={handleSave}
        saveButtonLabel={"Assign"}
        saving={assignPeriodSaving}
      >
        <RadioButtonGroupInput
          source="type"
          label={"Type"}
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
          choices={
            isInspectionChosen
              ? [{ id: "inspector", name: "Inspector" }]
              : [
                  { id: "cleaner", name: "Cleaner" },
                  { id: "inspector", name: "Inspector" },
                ]
          }
          defaultValue={isInspectionChosen ? "inspector" : "cleaner"}
          onChange={(value: string) => {
            setRadioBtnValue(value);
          }}
        />
        {radioBtnValue === "cleaner" ? (
          <ReferenceInput
            label="User"
            source="user"
            reference="user"
            variant={"outlined"}
            validate={required()}
            fullWidth={true}
            sort={{
              field: "firstName",
              order: "ASC",
              forSelector: true,
              clubId: clubIdForModal,
            }}
            // filter={{ roleSchedule: 'cleaner,maintenance,clubStaff,assistantManager,manager,areaManager,owner,superAdmin' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : radioBtnValue === "inspector" ? (
          <ReferenceInput
            label="User"
            source="user"
            reference="user"
            variant={"outlined"}
            validate={required()}
            fullWidth={true}
            sort={{
              field: "firstName",
              order: "ASC",
              forSelector: true,
              clubId: clubIdForModal,
            }}
            // filter={{ roleSchedule: 'cleaner,maintenance,clubStaff,assistantManager,manager,areaManager,owner' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : (
          <></>
        )}
        <DateInput
          label="From"
          source="startDate"
          validate={[customValidation]}
          variant={"outlined"}
          fullWidth={true}
          parse={(val: any) => dateToStr(new Date(val))}
        />
        <BooleanInput source="endDateInfinite" label={"Infinite end time"} />
        <FormDataConsumer fullWidth={true} variant={"outlined"}>
          {({ formData, ...rest }) =>
            !formData.endDateInfinite && (
              <DateInput
                label="To"
                source="endDate"
                variant={"outlined"}
                fullWidth={true}
                parse={(val: any) => dateToStr(new Date(val))}
              />
            )
          }
        </FormDataConsumer>
      </ModalForm>
    </Dialog>
  );
}

export default AssignModal;
