import React from 'react'
import { useSelector } from "react-redux";
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts'
import { AppState } from "src/types";
import DashboardCard from './DashboardCard'
import { colors } from 'src/style'

interface Props {
  title: string
}

function InspectionsScore(props: Props) {
  const dashboard = useSelector((state: AppState) => state.dashboard)

  return (
    <DashboardCard title={props.title}>
      <ResponsiveContainer aspect={2.3}>
        <BarChart data={dashboard.inspectionScore.map((item: any) => { return { ...item, score: parseInt(item.score) } })} layout="vertical" barSize={10}>
          <XAxis type="number" dataKey="score" interval={0} tickFormatter={(tick: any) => `${tick}%`} padding={{ right: 10 }} />
          <YAxis dataKey="clubName" type="category" tick={{ fontSize: 12 }} width={150} interval={0} />
          <Bar dataKey="score" fill={colors.primary} />
        </BarChart>
      </ResponsiveContainer>
    </DashboardCard>
  )
}

export default InspectionsScore
