import makeStyles from "@material-ui/core/styles/makeStyles";
import React from 'react'
import { useSelector } from "react-redux";
import LeaderBoardGymItem from "src/resources/DashboardPage/components/LeaderBoardGymItem";
import { AppState } from "src/types";
import { formatTimeDurationAvg } from "src/utils/dateUtil";
import DashboardCard from './DashboardCard'

const useStyles = makeStyles({
  root: {
    overflowY: 'auto',
    height: 245,
    paddingRight: 30,
    paddingLeft: 10,
  },
})

interface Props {
  title: string
}
function TimeToCloseIssuesByLocation(props: Props) {
  const classes = useStyles();
  const dashboard = useSelector((state: AppState) => state.dashboard)

  return (
    <DashboardCard title={props.title}>
      <div className={classes.root}>
        {dashboard.timeToCloseByLocation.map( item =>  <LeaderBoardGymItem name={item.clubName} type={'stat'} rate={`${formatTimeDurationAvg(item.duration)}`} image={item.clubImage}/>)}
      </div>
    </DashboardCard>
  )
}

export default TimeToCloseIssuesByLocation
