enum ActionTypes {
  GET_USER_BY_INVITE = 'RegistrationPage/GET_USER_BY_INVITE',
  GET_USER_BY_INVITE_FINISHED = 'RegistrationPage/GET_USER_BY_INVITE_FINISHED',
  GET_USER_BY_INVITE_FAILED = 'RegistrationPage/GET_USER_BY_INVITE_FAILED',
  REGISTRATION = 'RegistrationPage/REGISTRATION',
  REGISTRATION_FINISHED = 'RegistrationPage/REGISTRATION_FINISHED',
  REGISTRATION_FAILED= 'RegistrationPage/REGISTRATION_FAILED',
}

export default ActionTypes
