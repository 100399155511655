import React from 'react'
import { Typography, makeStyles, Paper } from '@material-ui/core'

interface Props {
  title: string
  children: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title:{
    fontWeight: 'bold'
  }

}))

function DashboardCard(props: Props) {
  const classes = useStyles()

  return (
    <div>
      <Typography classes={{subtitle1: classes.title}} variant="subtitle1" color="textSecondary" gutterBottom>
        {props.title}
      </Typography>
      <Paper className={classes.paper} elevation={2}>
        {props.children}
      </Paper>
    </div>
  )
}

export default DashboardCard
