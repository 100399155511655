import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
  required,
  ReferenceInput,
  RadioButtonGroupInput,
  AutocompleteInput,
  FormDataConsumer,
  BooleanInput,
  SelectInput,
} from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import {
  detachPeriodClose,
  detachPeriodStart,
} from 'src/resources/AssignCleanersPage/actions'
import { AppState } from 'src/types'
import { Dialog, DialogTitle } from '@material-ui/core'
import ModalForm from 'src/components/ModalForm'
import { DateInput } from 'src/components/DateInput'
import { dateToStr } from 'src/utils/dateUtil'

interface Props {
}

function DetachModal(props: Props) {
  const dispatch = useDispatch()
  const users = useSelector((state: AppState) => state.users)
  const {
    detachOpen,
    detachPeriodSaving,
    clubId,
    detachPeriodListId,
    detachPeriodUser,
    detachPeriodDate,
    detachPeriodType,
  } = useSelector((state: AppState) => state.assignCleaners)
  const [userList, setUserList] = useState([])
  const handleCloseClick = () => {
    dispatch(detachPeriodClose())
  }
  const handleSave = (values) => {
    dispatch(detachPeriodStart(detachPeriodListId, { ...values, ...(values.endDateInfinite ? { endDate: null } : {}) }))
  }
  useEffect(() => {
    let userListNew = users.list

    if (clubId) {
      userListNew = userListNew.filter((userItem: any) => userItem.clubs.some((clubItem: any) => clubItem.id === clubId))
    }

    const sortedList = userListNew.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

    setUserList(sortedList)
  }, [users, clubId])

  return (
    <Dialog
      open={detachOpen}
      onClose={handleCloseClick}
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle>Detach</DialogTitle>
      <ModalForm
        handleClose={handleCloseClick}
        save={handleSave}
        saveButtonLabel={'Detach'}
        saving={detachPeriodSaving}
        initialValues={{
          user: detachPeriodUser?.id,
          type: detachPeriodType,
          startDate: detachPeriodDate ? format(detachPeriodDate, 'yyyy-MM-dd') : null,
          endDate: detachPeriodDate ? format(detachPeriodDate, 'yyyy-MM-dd') : null,
        }}
      >
        <RadioButtonGroupInput
          source="type"
          label={'Detach as'}
          variant={'outlined'}
          fullWidth={true}
          validate={required()}
          choices={[
            { id: 'cleaner', name: 'Cleaner' },
            { id: 'inspector', name: 'Inspector' },
          ]}
        />
        {/*<FormDataConsumer>*/}
        {/*  {({ formData, ...rest }) => (*/}
        {/*    <>*/}
        {/*    <SelectInput*/}
        {/*      label="User"*/}
        {/*      source="user"*/}
        {/*      choices={userList}*/}
        {/*      optionText="name"*/}
        {/*      optionValue="id"*/}
        {/*      {...rest}*/}
        {/*    />*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*</FormDataConsumer>*/}
        {/*<ReferenceInput label="User" source="user" reference="user" validate={required()} fullWidth>*/}
        {/*  <AutocompleteInput optionText="name" choices={userList} />*/}
        {/*</ReferenceInput>*/}
        <DateInput
          label="From"
          source="startDate"
          validate={required()}
          variant={'outlined'}
          fullWidth={true}
          parse={(val) => dateToStr(new Date(val))}
        />
        <BooleanInput
          source="endDateInfinite"
          label={'Infinite end time'}
        />
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData, ...rest }) => !formData.endDateInfinite && (
            <DateInput
              label="To"
              source="endDate"
              variant={'outlined'}
              fullWidth={true}
              parse={(val) => dateToStr(new Date(val))}
            />
          )}
        </FormDataConsumer>

      </ModalForm>
    </Dialog>
  )
}

export default DetachModal
