import * as React from 'react';
import { useCallback, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { FieldTitle, useInput, InputProps, ChoicesProps } from 'ra-core';
import CheckboxGroupInputItem from './CheckboxGroupInputItem'
import defaultSanitizeRestProps from '../sanitizeRestProps';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';

const sanitizeRestProps = ({
  setFilter,
  setPagination,
  setSort,
  loaded,
  ...rest
}: any) => defaultSanitizeRestProps(rest);

const useStyles = makeStyles(
  theme => ({
    root: {},
    table: {
      minWidth: 650,
    },
    label: {
      transform: 'translate(0, 8px) scale(0.75)',
      transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'
        }`,
    },
  }),
  { name: 'RaUserNotificationsInput' }
);

/**
 * An Input component for a checkbox group, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * The expected input must be an array of identifiers (e.g. [12, 31]) which correspond to
 * the 'optionValue' of 'choices' attribute objects.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *     { id: 12, name: 'Ray Hakt' },
 *     { id: 31, name: 'Ann Gullar' },
 *     { id: 42, name: 'Sean Phonee' },
 * ];
 * <CheckboxGroupInput source="recipients" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi' },
 *    { _id: 456, full_name: 'Jane Austen' },
 * ];
 * <CheckboxGroupInput source="recipients" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <CheckboxGroupInput source="recipients" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <CheckboxGroupInput source="recipients" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'programming', name: 'myroot.category.programming' },
 *    { id: 'lifestyle', name: 'myroot.category.lifestyle' },
 *    { id: 'photography', name: 'myroot.category.photography' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <CheckboxGroupInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <Checkbox> components
 */
const NotificationInput: FunctionComponent<ChoicesProps & InputProps<CheckboxProps> & FormControlProps> = props => {
  const {
    classes: classesOverride,
    format,
    helperText,
    label,
    margin = 'dense',
    onBlur,
    onChange,
    onFocus,
    optionText,
    optionValue,
    options,
    parse,
    resource,
    row,
    values,
    columns,
    source,
    translate,
    translateChoice,
    validate,
    ...rest
  } = props;
  const classes = useStyles(props);


  const {
    input: { onChange: finalFormOnChange, value },
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const handleCheck = useCallback(
    (event, isChecked) => {
      let newValue;
      try {
        // try to convert string value to number, e.g. '123'
        newValue = JSON.parse(event.target.value);
      } catch (e) {
        // impossible to convert value, e.g. 'abc'
        newValue = event.target.value;
      }
      const [rowId, columnId] = newValue.split('||')
      newValue = { ...value };
      if (!newValue[rowId]) {
        newValue[rowId] = {}
      }
      newValue[rowId][columnId] = isChecked;
      console.log("newValue", newValue, rowId, columnId)
      finalFormOnChange(newValue);
      // finalFormOnBlur();
    },
    [value, finalFormOnChange]
  );



  return (
    <FormControl component="fieldset"
      margin={margin}
      error={touched && !!error}
      {...sanitizeRestProps(rest)}
    >
      <FormLabel component="legend" className={classes.label}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </FormLabel>
      <FormGroup row={row}>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columns.map(({ name }) =>
                  <TableCell align="right">{name}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map(({ id: rowId, name: rowName }) => (
                <TableRow key={rowId}>
                  <TableCell component="th" scope="row">
                    {rowName}
                  </TableCell>
                  {columns.map(({ id, name }) => <TableCell align="right">
                    <CheckboxGroupInputItem
                      key={`${rowId}||${id}`}
                      choice={{ id: `${rowId}||${id}` }}
                      id={id}
                      onChange={(event, isChecked) => handleCheck(event, isChecked)}
                      value={(value && value[rowId] && value[rowId][id] && value[rowId][id]) || false}
                    />
                  </TableCell>
                  )}

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


      </FormGroup>
      <FormHelperText>

      </FormHelperText>
    </FormControl>
  );
};

NotificationInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  optionValue: PropTypes.string,
  row: PropTypes.bool,
  resource: PropTypes.string,
  translateChoice: PropTypes.bool,
};

NotificationInput.defaultProps = {
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true,
  fullWidth: true,
  values: [],
  columns: [],
  row: true,
};

export default NotificationInput;
