import React from 'react'
import { Box, Grid } from '@material-ui/core'

interface Props {
  left: React.ReactNode
  right: React.ReactNode
}

function DashboardSection(props: Props) {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item md={6}>
          {props.left}
        </Grid>
        <Grid item md={6}>
          {props.right}
        </Grid>
      </Grid>
      <Box m={6} />
    </div>
  )
}

export default DashboardSection
