import React from 'react'
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  CheckboxGroupInput,
  required,
  BooleanInput,
  FormDataConsumer,
  SaveButton,
} from 'react-admin'
// import { parse } from 'query-string'

const TitleEdit = ({record}: any) => {
  return (
    <span>
      Checklist {record.name}
    </span>
  )
}
export const CleaningListTaskEdit = props => {
  const {location} = props

  const redirect = location.state && location.state.previous ? location.state.previous : false
  const checkMonth = (val) => {
    return ['yearly'].indexOf(val) >= 0
  }
  const checkWeek = (val) => {
    return ['yearly', 'monthly'].indexOf(val) >= 0
  }
  const checkDayOfWeek = (val) => {
    return ['yearly', 'monthly', 'weekly'].indexOf(val) >= 0
  }

  let newProps = JSON.parse(JSON.stringify(props));
  newProps.basePath = redirect;

  return (
    <Edit {...newProps} title={<TitleEdit/>} undoable={false}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="cleaningListId"
          reference="cleaning-list"
          allowEmpty
          variant={'outlined'}
          fullWidth={true}
          validate={required()}
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>

        <TextInput source="name" label={'Task name'} fullWidth={true} variant={'outlined'}/>
        <TextInput multiline source="description" label={'Description'} fullWidth={true} variant={'outlined'}/>
        <BooleanInput
          source={`isPhotoRequired`}
          label="Photo Required"
        />
        <BooleanInput
          source={`isTimerRequired`}
          label="Timer Required"
        />
        <BooleanInput
          source="hasInspection"
          label="Has inspection"
        />
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({formData, ...rest}) => formData.hasInspection && (
            <SelectInput
              source="inspectionFrequency"
              label={'Inspection frequency'}
              variant={'outlined'}

              choices={[
                {id: 1, name: 'each clean'},
                {id: 2, name: '2nd clean'},
                {id: 3, name: '3d clean'},
                {id: 4, name: '4th clean'}
              ]}
              {...rest}
            />
          )}
        </FormDataConsumer>


        <RadioButtonGroupInput
          source="frequency"
          label={'Frequency'}
          variant={'outlined'}
          fullWidth={true}
          choices={[
            {id: 'daily', name: 'Daily'},
            {id: 'weekly', name: 'Weekly'},
            {id: 'monthly', name: 'Monthly'},
            {id: 'yearly', name: 'Yearly'},
          ]}
        />
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({formData, ...rest}) => checkMonth(formData.frequency) && (
            <SelectInput
              source="month"
              label={'In this month'}
              variant={'outlined'}
              choices={[
                {id: 1, name: 'January'},
                {id: 2, name: 'February'},
                {id: 3, name: 'March'},
                {id: 4, name: 'April'},
                {id: 5, name: 'May'},
                {id: 6, name: 'June'},
                {id: 7, name: 'July'},
                {id: 8, name: 'August'},
                {id: 9, name: 'September'},
                {id: 10, name: 'October'},
                {id: 11, name: 'November'},
                {id: 12, name: 'December'},
              ]}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({formData, ...rest}) => checkWeek(formData.frequency) && (
            <SelectInput
              source="week"
              label={'On this week of the month'}
              variant={'outlined'}
              choices={[
                {id: 1, name: '1st week'},
                {id: 2, name: '2nd week'},
                {id: 3, name: '3rd week'},
                {id: 4, name: '4th week'},
                {id: 5, name: 'Last week'},
              ]}
              {...rest}
            />
          )}
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({formData, ...rest}) => checkDayOfWeek(formData.frequency) && (
            <CheckboxGroupInput
              source="dayOfWeek"
              variant={'outlined'}
              label={'On this day or shift of the week'}
              choices={[
                {id: 1, name: 'Monday'},
                {id: 2, name: 'Tuesday'},
                {id: 3, name: 'Wednesday'},
                {id: 4, name: 'Thursday'},
                {id: 5, name: 'Friday'},
                {id: 6, name: 'Saturday'},
                {id: 7, name: 'Sunday'},
              ]}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
