import ActionTypes from './const'
import { action } from 'typesafe-actions'
import { IUser, IRole } from './types'
import { stringify, parse } from 'querystring'


export const fetchList = () => action(ActionTypes.FETCH_LIST, {
  api: `/admin/api/user`,
})

export const setFilter = (user: IUser) => action(ActionTypes.SET_FILTER, user)
export const selectUser = (role?: IRole, clubId?: number) => action(ActionTypes.SELECT_USER, { role, clubId })
export const userSelected = (user: IUser) => action(ActionTypes.USER_SELECTED, user)
export const closeModal = () => action(ActionTypes.CLOSE_MODAL)
