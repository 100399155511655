import makeStyles from "@material-ui/core/styles/makeStyles";
import React from 'react'
import { useSelector } from "react-redux";
import LeaderBoardGymItem from "src/resources/DashboardPage/components/LeaderBoardGymItem";
import { AppState } from "src/types";
import DashboardCard from './DashboardCard'

const useStyles = makeStyles({
  root: {
    overflowY: 'auto',
    height: 245,
    paddingRight: 30,
    paddingLeft: 10,
  },
})

interface Props {
  title: string
}
function InspectionCompletedByUser(props: Props) {
  const classes = useStyles();
  const dashboard = useSelector((state: AppState) => state.dashboard)

  return (
    <DashboardCard title={props.title}>
      <div className={classes.root}>
        {dashboard.inspectionCompletionByUser.map((item, index) => <LeaderBoardGymItem name={item.userName} type={'stat'} rate={`${item.completed}%`} image={item.avatar} key={`InspectionCompletedByUser_${index}`} />)}

      </div>
    </DashboardCard>
  )
}

export default InspectionCompletedByUser
