import PropTypes from "prop-types";
import React from 'react'
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  ImageField,
  TextInput,
  required,
} from 'react-admin'
import { parse } from 'query-string'
import S3ImageInput from 'src/components/S3FileUpload/S3ImageUpload'

const Title = ({ record }: any) => {
  return (
    <span>
      Create cleaning task Inspection instruction
    </span>
  )
}


export const InspectionStepCreateForm = ({ isNested, ...rest }) => {
  const source = rest.source || '';
  return (<div style={{ marginTop: isNested ? '20px' : '0px' }}>
    {!isNested ? <ReferenceInput
      source={`${source}cleaningTaskId`}
      reference="cleaning-list-task"
      allowEmpty
      validate={required()}
      variant={'outlined'}
      fullWidth={true}
    >
      <SelectInput optionText="name" />
    </ReferenceInput> : null}

    <TextInput source={`${source}name`} label={'Instruction name'} variant={'outlined'}
      fullWidth={true} />
    <TextInput multiline source={`${source}description`} label={'Note'} variant={'outlined'} fullWidth={true} />
    <S3ImageInput
      source={`${source}image`}
      fileCoverImg="someImgURL" // cover img for non-img files
      label={'Image'}
      uploadOptions={{
        multiple: false, // for selecting multiple files from file system
      }}
    >
      <ImageField source="src" />
    </S3ImageInput>
  </div>)
}
InspectionStepCreateForm.propTypes = {
  isNested: PropTypes.bool,
}

InspectionStepCreateForm.defaultProps = {
  isNested: false
}

export const InspectionStepCreate = props => {
  const { cleaningTaskId: cleaningTaskIdString } = parse(props.location.search)
  const { location } = props

  const cleaningTaskId = cleaningTaskIdString ? parseInt(cleaningTaskIdString, 10) : ''

  const redirect = location.state && location.state.previous ? location.state.previous : false

  return (
    <Create {...props} title={<Title />}>
      <SimpleForm
        defaultValue={{ cleaningTaskId }}
        redirect={redirect}
      >
        <InspectionStepCreateForm isNested={false} />
      </SimpleForm>
    </Create>
  )
}
