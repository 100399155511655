import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request'

import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin'

function covertEmptyValues(obj: any) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === 'object') covertEmptyValues(obj[key]); //recursive for objects
    else if (obj[key] === "" && typeof obj[key] === 'string') obj[key] = null;         //remove empty properties
  });
  return obj;
};

export default (apiUrl: any, httpClient: any) => {
  const composeFilter = (paramsFilter: any) => {
    let paramsFilterObj = paramsFilter

    if (paramsFilterObj === '' || (typeof paramsFilterObj.q !== 'undefined' && paramsFilterObj.q === '')) {
      paramsFilterObj = {}
    }

    const flatFilter = fetchUtils.flattenObject(paramsFilterObj)

    let filter = Object.keys(flatFilter).map(key => {
      const splitKeyOr = key?.split('&')
      let res = {};
      for (const keySplit of splitKeyOr) {
        const splitKey = keySplit?.split('||')
        const ops = splitKey[1] ? splitKey[1] : 'eq'
        let field = splitKey[0]
        if (field.indexOf('_') === 0 && field.indexOf('.') > -1) {
          field = field?.split(/\.(.+)/)[1]
        }

        if (key !== 'roleSchedule' && key !== 'userIdForClubs') {
          const currentFilter = { field, operator: ops, value: flatFilter[key] };
          if (splitKeyOr.length > 1) {
            if (!res["$or"]) {
              res["$or"] = [];
            }
            res["$or"].push(currentFilter)
          } else {
            res = currentFilter
          }
        } else {
          res = null
        }
      }

      return res;
    })

    filter = filter.filter((item: any) => item)

    return filter
  }

  const convertDataRequestToHTTP = (type: any, resource: any, params: any) => {
    let url = ''
    const options: any = {}

    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination
        const filter: any = composeFilter(params.filter)

        const qb = RequestQueryBuilder.create();
        for (const item of filter) {
          if (item["$or"]) {
            qb.setOr(item["$or"])
          } else {
            if (!resource.includes('time-tracking')) {
              qb.setFilter(item);
            }
          }
        }

        let query = qb.setLimit(perPage)
          .setPage(page)
          .sortBy(params.sort)
          .setOffset((page - 1) * perPage)
          .query()
        if (resource.includes('time-tracking')) {
          for (const item of filter) {
            query = query + '&' + item.field + '=' + item.value
          }
        }

        if (params.sort?.forSelector) {
          query = query.split('&').filter((item: string) => !item.includes('limit')).join('&')
        }

        if (params.sort?.clubId) {
          query += `&filter=_clubs.id||$eq||${params.sort?.clubId}`
        }

        if (params.filter?.userIdForClubs) {
          query += `&filter=_users.id||$eq||${params.filter?.userIdForClubs}`
        }

        if (params.filter.hasOwnProperty('roleSchedule')) {
          query += `&filter=role||$in||${params.filter.roleSchedule}`
        }

        if (resource === 'club' && params.sort.field && params.sort.order) {
          query = query.split('&').filter((item: string) => !item.includes('sort')).join('&')
          query += `&sort=${params.sort.order}&sortBy=${params.sort.field}`
        }

        if(resource === "user"){
          const roles = ['cleaner', 'maintenance', 'clubStaff', 'assistantManager', 'manager', 'areaManager', 'owner', 'superAdmin']
          let rolesHierarchy = [];
          let isAdmin = false;
          for(let i = 0; i< roles.length; i++){
            if(roles[i] === "superAdmin"){
              isAdmin = true;
            }

            if(roles[i] !== localStorage.getItem("permission")){
              rolesHierarchy.push(roles[i]);
            }else{
              rolesHierarchy.push(roles[i]);
              break;
            }
          }

          if(isAdmin == false){
            query += `&filter=role||$in||${rolesHierarchy}`;
          }
        }


        url = `${apiUrl}/${resource}?${query}`;


        break
      }
      case GET_ONE: {
        if (resource.includes('time-tracking')) {
          url = `${apiUrl}/${resource}?id=${params.id}`
        } else {
          url = `${apiUrl}/${resource}/${params.id}`
        }

        break
      }
      case GET_MANY: {
        const query = RequestQueryBuilder
          .create()
          .setFilter({
            field: 'id',
            operator: CondOperator.IN,
            value: `${params.ids}`,
          })
          .query()

        url = `${apiUrl}/${resource}?${query}`


        break
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination
        const filter: any = composeFilter(params.filter)

        filter.push({
          field: params.target,
          operator: CondOperator.EQUALS,
          value: params.id,
        })

        const query = RequestQueryBuilder
          .create({
            filter,
          })
          .sortBy(params.sort)
          .setLimit(perPage)
          .setOffset((page - 1) * perPage)
          .query()

        url = `${apiUrl}/${resource}?${query}`

        break
      }
      case UPDATE: {
        let newApiUrl: any;
        if (resource === 'issue') {
          newApiUrl = apiUrl.replace('admin/', '');
        }

        url = `${!!newApiUrl ? newApiUrl : apiUrl}/${resource}/${params.id}`

        options.method = 'PUT'
        if (params.data instanceof FormData) {
          options.body = covertEmptyValues(params.data)
        } else {
          options.body = JSON.stringify(covertEmptyValues(params.data))
        }
        break
      }
      case CREATE: {
        let newResource: any;
        if (resource.includes('time-tracking')) {
          newResource = 'cleaning-list-job-check';
        }

        url = `${apiUrl}/${!!newResource ? newResource : resource}`
        options.method = 'POST'

        if (params.data instanceof FormData) {
          options.body = covertEmptyValues(params.data)
        } else {
          options.body = JSON.stringify(params.data)
        }
        break
      }
      case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'DELETE'
        break
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  const convertHTTPResponse = (response: any, type: any, resource: any, params: any) => {
    const { json } = response
    switch (type) {
      case GET_LIST:
        if (resource === 'cleaning-list') {

          let newData = [];

          for (let i = 0; i < json.data.length; i++) {
            newData.push(json.data[i])

            if (!!json.data[i].startTime) {
              const tempStartTime = json.data[i].startTime?.split('+');

              const tempStartTimeHour = tempStartTime[0]?.split(':');

              const startTimePostfix = tempStartTimeHour[0] < 12 ? 'AM' : 'PM';

              newData[newData.length - 1].startTime = `${tempStartTimeHour[0] % 12 || 12}:${tempStartTimeHour[1]}${startTimePostfix}`;
            }

            if (!!json.data[i].endTime) {
              const tempEndTime = json.data[i].endTime?.split('+');

              const tempEndTimeHour = tempEndTime[0]?.split(':');

              const endTimePostfix = tempEndTimeHour[0] < 12 ? 'AM' : 'PM';

              newData[newData.length - 1].endTime = `${tempEndTimeHour[0] % 12 || 12}:${tempEndTimeHour[1]}${endTimePostfix}`
            }
          }

          return {
            data: newData || json,
            total: json.total,
          }
        } else {
          let newData: any = [];

          if (!!json.data) {
            newData = json.data
          } else if (!!json.result) {
            newData = json.result

            if (params.filter.isGroup) {
              newData = newData.filter((item: any) => item.isGroup)
            }

            if (params.filter.excludeId) {
              newData = newData.filter((item: any) => item.id !== params.filter.excludeId)
            }

          } else if (!!json.parent && !!json.nodeChildren) {
            newData = [...json.parent]

            for (let i = 0; i < json.nodeChildren.length; i++) {
              newData = [...newData, ...json.nodeChildren[i]]
            }
          } else {
            newData = json
          }


          if (params.filter['name||$contL']) {
            newData = newData.filter((filterItem: any) => filterItem.name.toLowerCase().includes(params.filter['name||$contL'].toLowerCase()) && !filterItem.isGroup)
          }

          if (resource === 'time-tracking/checkin-times' && !!json?.overAllTime) {
            for (let i = 0; i < newData.length; i++) {
              newData[i].worked = json.overAllTime?.actualMiliseconds
            }
          }

          return {
            data: newData,
            total: json.total,
          }
        }
      case GET_MANY_REFERENCE:
      case GET_MANY:
        return {
          data: json?.data || json?.result || json,
          total: json.total,
        }
      case CREATE:
        return { data: { ...params.data, id: json.id } }
      case DELETE:
        return { data: {} }
      case GET_ONE:

        if (resource.includes('time-tracking')) {
          return { data: json }
        } else if (resource.includes('issue')) {
          const newData = { ...json, responsable: json.responsable.map((responsableItem: any) => responsableItem.id) }
          return { data: newData };
        } else {
          return { data: json }
        }
      default:
        return { data: json }
    }
  }

  return (type: any, resource: any, params: any) => {
    if (type === 'move') {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify({ sort: params.sort }),
      })
        .then((response: any) => ({
          data: response.json
        }))
    }

    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })),
      )
        .then(responses => ({
          data: responses.map((response: any) => response.json),
        }))
    }
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })),
      ).then(responses => ({
        data: responses.map((response: any) => response.json),
      }))
    }

    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params,
    )
    return httpClient(url, options).then(
      response => convertHTTPResponse(response, type, resource, params),
    )
  }
}
