import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { periodFrom, periodTo, fetchList, setClubId } from '../actions'
import { AppState } from 'src/types'
import ClubFilter from 'src/components/clubs/ClubFilter'

interface Props { }

function Controls(props: Props) {
  const dispatch = useDispatch()
  const assignCleaners = useSelector((state: AppState) => state.assignCleaners)

  useEffect(() => {
    dispatch(fetchList({ startDate: assignCleaners.periodFrom, endDate: assignCleaners.periodTo }))
    // eslint-disable-next-line
  }, [])

  return (
    <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <ClubFilter onChange={(id: any) => {
          dispatch(setClubId(id));
          dispatch(fetchList({ startDate: assignCleaners.periodFrom, endDate: assignCleaners.periodTo }))
        }} />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            format="dd/MM/yyyy"
            label="From"
            value={assignCleaners.periodFrom}
            onChange={(date) => {
              if (date.toString() !== 'Invalid Date') {
                dispatch(periodFrom(date));
                dispatch(fetchList({ startDate: date, endDate: assignCleaners.periodTo }))
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            maxDate={assignCleaners.periodTo}
            fullWidth
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            format="dd/MM/yyyy"
            label="To"
            value={assignCleaners.periodTo}
            onChange={(date) => {
              if (date.toString() !== 'Invalid Date') {
                dispatch(periodTo(date));
                dispatch(fetchList({ startDate: assignCleaners.periodFrom, endDate: date }))
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            minDate={assignCleaners.periodFrom}
            fullWidth
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}

export default Controls
