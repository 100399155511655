import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Avatar from "src/components/Avatar";
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get';
const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      "&:hover": {
        "& $text": {
          textDecoration: 'underline'
        }
      }
    },
    text: {
      marginLeft: '0.5rem',

    },

  },
  { name: 'RaImageField' },
)
const NameWithImageField = ({
  sourceImage,
  sourceName,
  firstLetter,
  record = {},
  isLink,
  onUserChange = () => { }
}: any) => {
  const classes = useStyles()

  useEffect(() => {
    if (!!record[sourceName]) {
      onUserChange(record[sourceName])
    }
    // eslint-disable-next-line
  }, [sourceName])


  return (<div className={classes.root}>
    {!!(record[sourceImage] || firstLetter || record?.avatar)
      ? <Avatar image={(get(record, record[sourceImage] ? sourceImage : 'avatar'))} name={get(record, sourceName) || record['title']} />
      : null}
    {get(record, sourceName)
      ? <div className={isLink ? classes.text : ''}> {get(record, sourceName)}</div>
      : null}
  </div>)
}

NameWithImageField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  firstLetter: PropTypes.bool,
  source: PropTypes.string,
  sourceName: PropTypes.string.isRequired,
  sourceImage: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
  onUserChange: PropTypes.func
}

NameWithImageField.defaultProps = {
  firstLetter: true,
  sourceName: 'name',
  sourceImage: 'image',
  isLink: true
};
export default NameWithImageField
