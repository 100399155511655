import React, { useEffect } from 'react'
import { Filter, ReferenceInput, SelectInput } from "react-admin";
import { useDispatch } from 'react-redux'
import DateRangeInput from "src/components/DateRangeInput";
import { fetchList, setFilter } from '../actions'
import { State } from '../reducer'

interface Props {
  dashboard: State
}

function Controls(props: Props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchList())
    // eslint-disable-next-line
  }, [])

  return (
    <Filter setFilters={(val: any) => {
      console.log("setFilters", val)
      dispatch(setFilter(val));
      dispatch(fetchList());
    }}>
      <ReferenceInput label="Club" source="clubId" reference="club" variant={'outlined'} fullWidth={true} alwaysOn={true} sort={{ order: 'ASC', field: 'name', forSelector: true }}>
        <SelectInput />
      </ReferenceInput>
      <ReferenceInput label="User" source="userId" reference="user" variant={'outlined'} fullWidth={true} alwaysOn={true} sort={{ order: 'ASC', field: 'firstName', forSelector: true }} >
        <SelectInput />
      </ReferenceInput>
      <DateRangeInput source={'date'} sourceStart={'startDate'} sourceEnd={'endDate'} label="Date Range" alwaysOn={true} />
    </Filter>
  )
}

export default Controls
