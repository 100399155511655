enum ActionTypes {
  PERIOD_FROM = 'AssignCleanersPage/PERIOD_FROM',
  PERIOD_TO = 'AssignCleanersPage/PERIOD_TO',
  FETCH_LIST = 'AssignCleanersPage/FETCH_LIST',
  ASSIGN = 'AssignCleanersPage/ASSIGN',
  ASSIGN_PERIOD = 'AssignCleanersPage/ASSIGN_PERIOD',

  ASSIGN_PERIOD_CLOSE = 'AssignCleanersPage/ASSIGN_PERIOD_CLOSE',
  ASSIGN_PERIOD_START = 'AssignCleanersPage/ASSIGN_PERIOD_START',
  ASSIGN_PERIOD_SUCCESS = 'AssignCleanersPage/ASSIGN_PERIOD_SUCCESS',
  ASSIGN_PERIOD_FAILED = 'AssignCleanersPage/ASSIGN_PERIOD_FAILED',

  DETACH_PERIOD = 'AssignCleanersPage/DETACH_PERIOD',
  DETACH_PERIOD_CLOSE = 'AssignCleanersPage/DETACH_PERIOD_CLOSE',
  DETACH_PERIOD_START = 'AssignCleanersPage/DETACH_PERIOD_CLOSE',
  DETACH_PERIOD_SUCCESS = 'AssignCleanersPage/DETACH_PERIOD_SUCCESS',
  DETACH_PERIOD_FAILED = 'AssignCleanersPage/DETACH_PERIOD_FAILED',
  UNASSIGN = 'AssignCleanersPage/UNASSIGN',
  CELL_SPINNER_START = 'AssignCleanersPage/CELL_SPINNER_START',

  SET_CLUB_ID = 'AssignCleanersPage/SET_CLUB_ID',

  IS_INSPECTION_CHOSEN = 'AssignCleanersPage/IS_INSPECTION_CHOSEN',

  SET_CLUB_ID_FOR_MODAL = 'AssignCleanersPage/SET_CLUB_ID_FOR_MODAL',
}

export default ActionTypes
