import React from 'react'
import {
  List,
  Datagrid,
  Filter,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin'
import ListActions from 'src/components/ListActions'
import EmptyList from 'src/components/EmptyList'

const SupplierFilter = (props) => (
  <Filter {...props}>

    <TextInput source="name||$contL" alwaysOn={true} variant={'outlined'} fullWidth={true} label={'Search'} resettable={true} />
    <TextInput source="phone" label={'Phone'} />
    <TextInput source="email" label={'Email'} />
    <TextInput source="accountNumber" label={'Account Number'} />

  </Filter>
)

export const SupplierList = (props) => (
  <List
    {...props}
    title="Supplies"
    filters={<SupplierFilter />}
    actions={<ListActions />}
    sort={{ field: 'name', order: 'ASC' }}
    bulkActionButtons={false}
    empty={(
      <EmptyList title={'Suppliers not found'} description={'You can add a supplier'} buttonText={'Create supplier'} />
    )}
  >
    <Datagrid rowClick={'edit'}>
      <TextField source="name" label={'Supplier Name'} />

      <TextField source="address" label={'Address'} />
      <TextField source="phone" label={'Phone'} />
      <TextField source="email" label={'Email'} />
      <TextField source="accountNumber" label={'Account Number'} />
      <TextField source="description" label={'Notes'} />
      <EditButton />
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Supplier {record.name}
    </span>
  )
}

export const SupplierEdit = (props) => (
  <Edit {...props} title={<TitleEdit />} undoable={false}>
    <SimpleForm redirect={'list'}>

      <TextInput source="name" label={'Supplier Name'} variant={'outlined'} fullWidth={true} />
      <TextInput multiline source="description" label={'Description'} variant={'outlined'} fullWidth={true} />
      <TextInput source="address" label={'Address'} variant={'outlined'} fullWidth={true} />
      <TextInput source="phone" label={'Phone'} variant={'outlined'} fullWidth={true} />
      <TextInput source="email" label={'Email'} variant={'outlined'} fullWidth={true} />
      <TextInput source="accountNumber" label={'Account Number'} variant={'outlined'} fullWidth={true} />
      <TextInput source="description" label={'Notes'} variant={'outlined'} fullWidth={true} multiline />
    </SimpleForm>
  </Edit>
)

export const SupplierCreate = (props) => (
  <Create title="Create Supplier" {...props}>

    <SimpleForm redirect={'list'}>

      <TextInput source="name" label={'Supplier Name'} variant={'outlined'} fullWidth={true} />
      <TextInput multiline source="description" label={'Description'} variant={'outlined'} fullWidth={true} />
      <TextInput source="address" label={'Address'} variant={'outlined'} fullWidth={true} />
      <TextInput source="phone" label={'Phone'} variant={'outlined'} fullWidth={true} />
      <TextInput source="email" label={'Email'} variant={'outlined'} fullWidth={true} />
      <TextInput source="accountNumber" label={'Account Number'} variant={'outlined'} fullWidth={true} />
      <TextInput source="description" label={'Notes'} variant={'outlined'} fullWidth={true} multiline />
    </SimpleForm>
  </Create>
)


