import makeStyles from "@material-ui/core/styles/makeStyles";
import React from 'react'
import Avatar from "src/components/Avatar";
import classNames from 'classnames';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0px 0px 0px',
    overflow: 'auto'
  },
  name: {
    fontSize: '14px',
    verticalAlign: 'middle',
    flex: 1,
    padding: '0px 14px'
  },

  image: {
    border: '2px solid #fff',
    width: '42px',
    height: '42px',
    borderRadius: '42px',
  },
  rating: {
    background: '#FAFAFA',
    border: '2px solid #EBEBEB',
    borderRadius: '12px',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '4px 12px',
    justifySelf: 'flex-end',
    display: 'flex',
    alignItems: 'center'
  },
  ratingIcon: {
    marginLeft: '5px'
  },
  statText: {
    color: '#FF4B2F'
  }
});

interface Props {
  name: string,
  image: string,
  rate: number | string,
  type?: string
}

function LeaderBoardGymItem({ name, image, rate, type }: Props) {
  const classes = useStyles();
  // const getImage = () => {
  //   switch (image) {
  //     case 'club':
  //       return clubImage
  //     case 'club2':
  //       return clubImageTwo
  //     case 'avatar':
  //       return avatarImage1
  //     case 'avatar2':
  //       return avatarImage2
  //     case 'avatar3':
  //       return avatarImage3
  //   }
  // }
  return (

    <div className={classes.root}>
      <div>
        <Avatar image={image} name={name} />
      </div>
      <div className={classes.name}>{name}</div>
      <div className={classNames(classes.rating, (type === 'stat' ? classes.statText : ''))}><span>{rate}</span>

        {(!type || type === 'rating') && <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.ratingIcon}>
          <path d="M13.9634 4.91196C13.8712 4.63728 13.6198 4.4428 13.3235 4.41682L9.28207 4.0613L7.68489 0.438008C7.56697 0.171808 7.29866 0 7.00001 0C6.70136 0 6.43294 0.171808 6.31576 0.438008L4.71859 4.0613L0.676563 4.41682C0.380263 4.44332 0.129359 4.63779 0.0366454 4.91196C-0.0555344 5.18665 0.0295957 5.48793 0.25369 5.67837L3.30865 8.27403L2.4079 12.1182C2.34199 12.4008 2.45522 12.6931 2.69725 12.8627C2.82735 12.9543 2.9802 13 3.13359 13C3.26539 13 3.39731 12.9661 3.51512 12.8977L7.00001 10.8787L10.4843 12.8977C10.7399 13.0457 11.0613 13.0322 11.3028 12.8627C11.5448 12.6931 11.658 12.4008 11.5921 12.1182L10.6914 8.27403L13.7463 5.67837C13.9703 5.48793 14.0556 5.18727 13.9634 4.91196Z" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient id="paint0_linear" x1="14" y1="-4" x2="-4" y2="20.5" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFA800" />
              <stop offset="1" stopColor="#FFB800" />
            </linearGradient>
          </defs>
        </svg>}

      </div>
    </div>
  )
}

export default LeaderBoardGymItem
