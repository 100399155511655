import React from 'react'
import { Title } from 'react-admin'
import { Box, Typography } from '@material-ui/core'
import AssignModal from "src/resources/AssignCleanersPage/components/AssignModal";
import DetachModal from "src/resources/AssignCleanersPage/components/DetachModal";

import CleanersTable from './components/CleanersTable'
import Controls from './components/Controls'
import Container from 'src/components/Container'


interface Props { }

function AssignCleanersPage(props: Props) {
  return (
    <Container>
      <Title title="Scheduling" />

      <Box m={2} />

      <Typography variant="h4" component="h4" color="textSecondary">
        Scheduling
      </Typography>

      <Box m={2} />

      <Controls />

      <Box m={6} />

      <CleanersTable />
      <AssignModal />
      <DetachModal />
    </Container>
  )
}

export default AssignCleanersPage
