import { green } from "@material-ui/core/colors";
import React from "react";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  createStyles,
  withStyles,
  Theme,
  IconButton,
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import * as materialColors from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  assignPeriod,
  setClubIdForModal,
  setIsInspectionChosen,
} from "src/resources/AssignCleanersPage/actions";
import { AppState } from "src/types";
import { eachDayOfInterval, format } from "date-fns";
import { timeToAmPm } from "src/utils/dateUtil";
import CleanersInnerTable from "./CleanersInnerTable";
import BigSpinner from "src/components/BigSpinner";
import DetachModal from "./DetachModal";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: materialColors.grey[700],
      color: theme.palette.common.white,
      fontWeight: "bold",
      border: "none",
    },
  })
)(TableCell);

interface Props {}

function CleanersTable(props: Props) {
  const defaultCellWidth = 100;
  const periodFrom = useSelector(
    (state: AppState) => state.assignCleaners.periodFrom
  );
  const periodTo = useSelector(
    (state: AppState) => state.assignCleaners.periodTo
  );
  const clubsFullList = useSelector(
    (state: AppState) => state.assignCleaners.clubs
  );
  const loading = useSelector(
    (state: AppState) => state.assignCleaners.loading
  );
  const clubFilter = useSelector((state: AppState) => state.clubs.clubFilter);
  const clubs = clubFilter
    ? clubsFullList.filter((item: any) => {
        if (item.id === clubFilter.id || item.parentId === clubFilter.id) {
          return true;
        }

        let numberOfParents = JSON.stringify(item.parents).split('"parentId":');
        numberOfParents.splice(0, 1);
        let ids = [];
        for (let i = 0; i < numberOfParents.length; i++) {
          let tempIds = numberOfParents[i].split(",")[0];
          if (tempIds !== "null") {
            ids.push(+tempIds);
          }
        }

        if (ids.includes(clubFilter.id)) {
          return true;
        }

        return false;
      })
    : clubsFullList;

  let days: Date[] = [];
  const dispatch = useDispatch();

  console.log({ periodFrom, periodTo });
  if (periodFrom && periodTo) {
    const startDate = new Date(periodFrom);
    const endDate = new Date(periodTo);
    if (
      !isNaN(startDate.getTime()) &&
      !isNaN(endDate.getTime()) &&
      startDate <= endDate
    ) {
      days = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });
    }
  }
  const handleAdd = (listId: any, isInspection: boolean, clubId: number) => {
    dispatch(assignPeriod(listId));
    dispatch(setIsInspectionChosen(isInspection));
    dispatch(setClubIdForModal(clubId));
  };

  return (
    <div>
      {loading && <BigSpinner />}
      {!loading &&
        clubs.map((club) => {
          return (
            <TableContainer
              key={club.id}
              component={Paper}
              style={{ minHeight: 100, marginBottom: 30 }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      colSpan={3}
                      rowSpan={2}
                      style={{ minWidth: defaultCellWidth * 3 }}
                    >
                      {club.name}
                    </StyledTableCell>
                    {days.map((day) => (
                      <StyledTableCell
                        align="center"
                        key={day.toString()}
                        style={{ minWidth: defaultCellWidth }}
                      >
                        {format(day, "eee MMM dd")}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {days.map((day) => (
                      <StyledTableCell
                        key={day.toString()}
                        style={{ minWidth: defaultCellWidth }}
                      >
                        <Table size="small">
                          <TableRow>
                            <StyledTableCell>Cleaner</StyledTableCell>
                            <StyledTableCell>Inspector</StyledTableCell>
                          </TableRow>
                        </Table>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {club.cleaningLists.map((list: any) => (
                    <TableRow key={list.id}>
                      <TableCell style={{ minWidth: defaultCellWidth }}>
                        {list.name}
                      </TableCell>
                      <TableCell style={{ minWidth: defaultCellWidth }}>
                        {timeToAmPm(list.startTime)} -{" "}
                        {timeToAmPm(list.endTime)}
                      </TableCell>
                      <TableCell style={{ minWidth: defaultCellWidth }}>
                        <IconButton
                          onClick={() =>
                            handleAdd(
                              list.id,
                              list?.type === "inspection",
                              list.clubId
                            )
                          }
                          size="small"
                          style={{ color: green[500] }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                      </TableCell>
                      {days.map((day) => (
                        <TableCell
                          key={day.toString()}
                          style={{ minWidth: defaultCellWidth }}
                        >
                          <CleanersInnerTable
                            jobs={list.schedule}
                            date={day}
                            listId={list.id}
                            onlyInspector={list?.type === "inspection"}
                            clubId={list.clubId}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
    </div>
  );
}

export default CleanersTable;
