import { takeLatest, put, select } from 'redux-saga/effects'
import { fetchList, fetchListRequest } from "src/resources/DashboardPage/actions";
import { AppState } from "src/types";
import { ActionType } from 'typesafe-actions'

import ActionTypes from './const'
function* dashboardSaga() {
  yield takeLatest(ActionTypes.FETCH_LIST, function* (action: ActionType<typeof fetchList>) {
    const filter = yield select((state: AppState) => state.dashboard.filter)
    yield put(fetchListRequest(filter));
  })

}

export default dashboardSaga
