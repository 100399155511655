import makeStyles from "@material-ui/core/styles/makeStyles";
import React from 'react'

import DashboardCard from './DashboardCard'

const useStyles = makeStyles({
  root: {
    overflowY: 'auto',
    height: 245,
    paddingRight: 30,
    paddingLeft: 10,
  },
});

interface Props {
  title: string
}

function LeaderBoardCleaners(props: Props) {
  const classes = useStyles();

  return (
    <DashboardCard title={props.title}>
      <div className={classes.root}>
        {/* {dashboard.} */}
        {/* <LeaderBoardGymItem name={'Tom'} rate={4.94} image={'avatar'} /> */}
      </div>
    </DashboardCard>
  )
}

export default LeaderBoardCleaners
