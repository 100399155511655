import React, { FC, Fragment, useState, useEffect, Children } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReferenceInput, SelectInput } from "react-admin";
import { FormWithRedirect } from "ra-core";
import { required, Button, FormInput } from "react-admin";

import IconContentAdd from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    alignItems: "flex-end",
  },
  gridItem: {
    width: "50%",
  },
}));

interface Props {
  basePath;
  resource;
  show;
  handleClose;
  to;
  listType;
}

const CreateCleaningListModal: FC<Props> = ({
  basePath,
  resource,
  show,
  handleClose,
  to,
  listType,
  ...rest
}) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const handleCloseClick = () => {
    handleClose();
  };

  const handleSubmit = (values) => {
    setShowDialog(false);

    history.push(
      `${basePath}/create?templateId=${values.cleaningListId}&type=${listType}`
    );
  };

  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label="Create Checklist"
    >
      <DialogTitle>Create Checklist</DialogTitle>

      <DialogContent>
        <div className={classes.grid}>
          <div className={classes.gridItem}>
            <Button component={Link} to={to} label={"Create from scratch"}>
              <IconContentAdd />
            </Button>
          </div>
          <div className={classes.gridItem}>
            <FormWithRedirect
              save={handleSubmit}
              version={1000}
              saving={false}
              redirect={""}
              render={({ handleSubmit }) => (
                <Fragment>
                  {Children.map(
                    [
                      <ReferenceInput
                        source={`cleaningListId`}
                        reference="cleaning-list"
                        allowEmpty
                        variant={"outlined"}
                        fullWidth={true}
                        filter={{ isTemplate: true }}
                        validate={required()}
                        label={"Checklist template"}
                      >
                        <SelectInput optionText="name" />
                      </ReferenceInput>,
                      <Button
                        label={"Create from template"}
                        type={"submit"}
                        onClick={handleSubmit}
                      >
                        <IconContentAdd />
                      </Button>,
                    ],
                    (input) =>
                      input && (
                        <FormInput
                          basePath={basePath}
                          input={input}
                          resource={resource}
                        />
                      )
                  )}
                </Fragment>
              )}
            ></FormWithRedirect>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCleaningListModal;
