import { action } from 'typesafe-actions'
import ActionTypes from './const'
import { IChatRequest } from "./types";

export const loadChats = (data: IChatRequest) => action(ActionTypes.LOAD_CHATS, data);

export const loadAllClubUsers = (params: any) => action(ActionTypes.LOAD_ALL_CLUB_USERS, params);

export const loadCurrentUser = (data: any) => action(ActionTypes.LOAD_CURRENT_USER, data);

export const loadMessages = (data: { chatId: number, fromThisChat: boolean }) => action(ActionTypes.LOAD_MESSAGES, data);

export const createChat = (chatInfo: any, callback?: any) => action(ActionTypes.CREATE_CHAT, { chatInfo, callback });

export const deleteChat = (chatId: number) => action(ActionTypes.DELETE_CHAT, chatId);

export const createMessage = (messageData: any) => action(ActionTypes.CREATE_MESSAGE, messageData);

export const updateMessageReadState = (ids: Array<number>) => action(ActionTypes.UPDATE_MESSAGE_READ_STATE, ids);
