import React, { Fragment, useEffect } from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  FormDataConsumer,
  PasswordInput,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin'
import ListActions from 'src/components/ListActions'
import EmptyList from 'src/components/EmptyList'
import NameWithImageField from 'src/components/NameWithImageField'
import S3ImageInput from 'src/components/S3FileUpload/S3ImageUpload'
import TimeDurationInput from 'src/components/TimeDurationInput'
import NotificationsInput from 'src/components/NotificationsInput'

const UserFiler = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} />
    <TextInput source="firstName||$contL&lastName||$contL&email||$contL" label={'Search'} alwaysOn={true}
      resettable={true} />
    <TextInput source="email||$contL" label={'Email'} />
    <TextInput source="phone||$contL" label={'Phone'} />
    <SelectInput
      source="role"
      label={'Position'}
      choices={[
        { id: 'cleaner', name: 'Cleaner' },
        { id: 'maintenance', name: 'Maintenance' },
        { id: 'assistantManager', name: 'Assistant Club Manager' },
        { id: 'manager', name: 'Club Manager' },
        { id: 'areaManager', name: 'Area Manager' },
        { id: 'owner', name: 'Club Owner' },
      ]}
    />

  </Filter>
)

export const UserList = (props: any) => {
  return (
    <List
      {...props}
      title="Users"
      filters={<UserFiler />}
      actions={<ListActions />}
      sort={{ field: 'firstName', order: 'ASC' }}
      bulkActionButtons={false}
      empty={<EmptyList title={'Users not found'} description={'You can add a user'} buttonText={'Create user'} />}
      perPage={25}
    >
      <Datagrid rowClick={'edit'}>
        <NameWithImageField isLink={false} />

        <TextField source="role" label={'Position'} />
        <TextField source="email" label={'Email'} />
        <TextField source="phone" label={'Mobile'} />
        <TextField source="id" label={'ID'} />
      </Datagrid>
    </List>
  )
}

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      User: {record.name}
    </span>
  )
}

export const UserForm = (props) => {
  const { record } = props

  const comparePasswords = (value: any, allValues: any) => {

    if (allValues.password !== allValues.passwordPreConfirm) {
      return 'Must be the same with "Password"'
    }

    return undefined
  }

  return (<Fragment>
    <TextInput source="firstName" inputProps={{ autocomplete: 'off' }} label={'First name'} variant={'outlined'} fullWidth={true} validate={required()} />
    <TextInput source="lastName" inputProps={{ autocomplete: 'off' }} label={'Last name'} variant={'outlined'} fullWidth={true} />
    <TextInput source="email" inputProps={{ autocomplete: 'off' }} label={'Email'} variant={'outlined'} fullWidth={true} validate={required()} />
    <TextInput source="phone" inputProps={{ autocomplete: 'off' }} label={'Phone'} variant={'outlined'} fullWidth={true} validate={required()} />

    {/* {!record?.id && <BooleanInput
      label={'Send email invite'}
      source="sendInvite"
    />} */}

    {record?.id && <BooleanInput
      label={'Change Password'}
      source="changePassword"
    />}

    {record?.id && <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.changePassword && (
          <TextInput source="setPassword" type={'password'} label={'New Password'} variant={'outlined'} fullWidth={true} />)
      }

    </FormDataConsumer>}
    {!record?.id && <FormDataConsumer>
      {({ formData, ...rest }) =>
        !formData.sendInvite && (
          <>
            <PasswordInput source="passwordPreConfirm" type={'password'} label={'Password'} variant={'outlined'} fullWidth={true} validate={required()} />
            <PasswordInput source="password" type={'password'} label={'Confirm password'} variant={'outlined'} fullWidth={true} validate={[required(), comparePasswords]} />
          </>
        )
      }
    </FormDataConsumer>}

    <ReferenceInput
      label="Country"
      source="countryId"
      reference="country"
      inputProps={{ autocomplete: 'off' }}
      variant={'outlined'}
      fullWidth={true}
      perPage={1000}
      allowEmpty={true}
      filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText } : {}) })}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput source="id" optionText="name" inputProps={{ autocomplete: 'off' }} />
    </ReferenceInput>

    <SelectInput
      source="language"
      variant={'outlined'}
      fullWidth={true}
      inputProps={{ autocomplete: 'off' }}
      label={'Language'}
      choices={[
        { id: 'en', name: 'English' },
      ]}
    />
    <SelectInput
      source="role"
      variant={'outlined'}
      fullWidth={true}
      label={'Position'}
      choices={[
        { id: 'cleaner', name: 'Cleaner' },
        { id: 'maintenance', name: 'Maintenance' },
        { id: 'clubStaff', name: 'Club Staff' },
        { id: 'assistantManager', name: 'Assistant Club Manager' },
        { id: 'manager', name: 'Club Manager' },
        { id: 'areaManager', name: 'Area Manager' },
        { id: 'owner', name: 'Club Owner' },
        { id: 'superAdmin', name: 'Super Admin ' },
      ]}
      validate={required()}
      inputProps={{ autocomplete: 'off' }}

    />

    {/* <RadioButtonGroupInput
      source="type"
      label={'Type'}
      variant={'outlined'}
      fullWidth={true}
      choices={[
        { id: 'staff', name: 'Staff' },
        { id: 'contract', name: 'Contractor' }
      ]}
      validate={required()}
      inputProps={{ autocomplete: 'off' }}
    /> */}
    <BooleanInput source="isTrainingMode" label={'Training mode'} />

    <ReferenceArrayInput label={'Clubs'}
      filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText } : {}) })}
      allowEmpty={true} source="clubs" reference="club"
      format={users => (users || []).map((club => club && club.id ? club.id : club))}
      variant={'outlined'} fullWidth={true}
      validate={required()} >
      <AutocompleteArrayInput source="id" optionText="name" variant={'outlined'} inputProps={{ autocomplete: 'off' }} />
    </ReferenceArrayInput>

    <NotificationsInput source="notificationSettings" choices={[]} label={'Notifications'}
      values={[
        { id: 'cleaningNoShow', name: 'Checklist No Show' },
        { id: 'lateCheckIn', name: 'Late Check in' },
        { id: 'earlyCheckOut', name: 'Early Check Out' },
        { id: 'outOfBoundsCheck', name: 'Out of Bounds Check in/out' },
        { id: 'noCheckOut', name: 'No Check Out' },
        { id: 'incompleteTasks', name: 'Incomplete Tasks' },
        { id: 'messages', name: 'Messages' },
        { id: 'newIssues', name: 'New Issues' },
        { id: 'newSupplyRequest', name: 'New Supply Request' },
        { id: 'inspectionDue', name: 'Inspection Due' },
        { id: 'inspectionNoShow', name: 'Inspection No Show' },
      ]}
      columns={[
        { id: 'push', name: 'Mobile push' },
        { id: 'email', name: 'Email' },
        { id: 'sms', name: 'SMS' },
        { id: 'alerts', name: 'Alert inbox' },
      ]}
    />
    <TimeDurationInput source={'noShowAlertsCleaning'} label={'Alert will be sent this long after checklist begins:'}
      variant={'outlined'} note={''} /><br />
    <TimeDurationInput source={'noShowAlertsInspection'}
      label={'Alert will be sent this long after Inspection is due:'}
      variant={'outlined'} />
    <br />
    <TimeDurationInput source={'noCheckOutShow'}
      label={'Alert will be sent this long after checklist Ends:'}
      variant={'outlined'} />

    <S3ImageInput
      source="avatar"
      fileCoverImg="someImgURL" // cover img for non-img files
      // allaw to save multiple files for that source
      label={'Avatar'}
      uploadOptions={{
        multiple: false, // for selecting multiple files from file system
      }}
    >
      <ImageField source="src" />
    </S3ImageInput>
  </Fragment>)
}

export const UserEdit = (props) => {
  return (
    <Edit {...props} title={<TitleEdit />} undoable={false} >
      <SimpleForm redirect={'list'} >
        <FormDataConsumer>
          {formDataProps => (
            <UserForm {...formDataProps} />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export const UserCreate = (props) => (
  <Create title="Create user" {...props}>
    <SimpleForm redirect={'list'}>
      <FormDataConsumer>
        {formDataProps => (
          <UserForm {...formDataProps} />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
)
