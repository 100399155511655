import React from 'react'
import { CircularProgress } from '@material-ui/core'

interface Props {}

function CellSpinner(props: Props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={22} />
    </div>
  )
}

export default CellSpinner
