enum ActionTypes {
  LOAD_CHATS = 'LOAD_CHATS',

  LOAD_ALL_CLUB_USERS = 'LOAD_ALL_CLUB_USERS',

  LOAD_CURRENT_USER = 'LOAD_CURRENT_USER',

  LOAD_MESSAGES = 'LOAD_MESSAGES',

  CREATE_CHAT = 'CREATE_CHAT',

  DELETE_CHAT = 'DELETE_CHAT',

  CREATE_MESSAGE = 'CREATE_MESSAGE',

  UPDATE_MESSAGE_READ_STATE = 'UPDATE_MESSAGE_READ_STATE',
}

export default ActionTypes
