import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  Data,
  GoogleMapProps,
  DataProps,
  Marker,
  useLoadScript,
  Circle,
} from "@react-google-maps/api";

import FormHelperText from '@material-ui/core/FormHelperText'
import { InputHelperText } from 'react-admin'
import Geocode from "react-geocode";
import { useInput, InputProps } from 'ra-core';
import Button from '@material-ui/core/Button';
const defaultCenter = {
  lat: 38.805470223177466,
  lng: -118.76220703125
};

interface IGoogleMapsProps {
  apiKey?: string;
  address?: string;
  center?: typeof defaultCenter;
  ignoreUserLocation?: boolean;
  mapContainerStyle?: any;
  mapProps?: Partial<GoogleMapProps>;
  dataProps?: Partial<DataProps>;
  children?: any;
  onClick?: (e: any) => void;
  onLoad?: (map: any) => void;
  radius?: number,
}

const GoogleMapInput: React.FC<IGoogleMapsProps & InputProps> = (props: IGoogleMapsProps & InputProps) => {

  const {
    apiKey,
    center,
    address,
    children,
    mapProps,
    dataProps,
    onClick,
    onLoad,
    helperText,
    ignoreUserLocation,
    format,
    parse,
    resource,
    onChange,
    source,
    validate,
    radius,
    ...rest
  } = props;
  const {
    input: { onChange: onChangeInput, value },
    meta: { error, touched },
  } = useInput({
    format,

    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });

  useEffect(() => {
    console.log("set radius", radius);
  }, [radius])
  const [position, setPosition] = useState(null)
  // console.log(process.env.REACT_APP_GOOGLE_API_KEY, 'process.env.GOOGLE_API_KEY')
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBO3DJ56hnon2sPqp1euENPXKgd1nNuwPw"
  });
  const G = React.useRef<any>(undefined);
  const MAP = React.useRef<any>(undefined);
  if (!G.current) {
    G.current = (window as any).google;
  }
  const centerValid = typeof center === "object" && center.lat && center.lng;
  React.useMemo(() => {
    if (centerValid || ignoreUserLocation) return;
    navigator.geolocation.getCurrentPosition(
      pos => {
        if (MAP.current)
          MAP.current.panTo({
            lat: pos.coords.latitude,
            lng: pos.coords.longitude
          });
        else {
          defaultCenter.lat = pos.coords.latitude;
          defaultCenter.lng = pos.coords.longitude;
        }
      },
      error => {
        console.error("ERROR RETRIEVING USER LOCATION", error);
      }
    );
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  useEffect(() => {
    if (typeof value === "object" && value.lat && value.lng) {
      setPosition(value);
    }
  })

  const handleRightClick = useCallback((e) => {
    console.log("handle dbl ", e.latLng)
    setPosition(e.latLng)
    console.log("Set val", { lat: e.latLng.lat(), lng: e.latLng.lng() })
    onChangeInput({ lat: e.latLng.lat(), lng: e.latLng.lng() })
    // eslint-disable-next-line
  }, []);

  const onDragEnd = (e) => {
    setPosition(e.latLng)
    console.log("Set val", { lat: e.latLng.lat(), lng: e.latLng.lng() })
    onChangeInput({ lat: e.latLng.lat(), lng: e.latLng.lng() })
  }
  const handleClickGeocode = useCallback(() => {
    if (!address) {
      return;
    }
    Geocode.setApiKey("AIzaSyBO3DJ56hnon2sPqp1euENPXKgd1nNuwPw");

    Geocode.setLanguage("en");
    Geocode.fromAddress(address).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log("{ lat, lng }", { lat, lng })
        setPosition({ lat, lng })
        onChangeInput({ lat, lng })
        MAP.current.panTo({ lat, lng });
      },
      error => {
        console.error(error);
      }
    );
    // eslint-disable-next-line
  }, [address]);

  return isLoaded ? (
    <>
      <Button onClick={handleClickGeocode} >
        Location from address
      </Button>
      <FormHelperText>
        <InputHelperText
          touched={touched}
          error={error}
          helperText={helperText || 'Right click on the map to add o point or click on button. You also can drag a marker'}
        />
      </FormHelperText>
      <GoogleMap
        // bootstrapURLKeys={}
        zoom={6}
        id="google-map"
        center={centerValid ? center : defaultCenter}
        mapContainerStyle={{ width: "100%", height: "300px" }}
        {...mapProps}
        onRightClick={handleRightClick}

        onLoad={map => {
          MAP.current = map;
          onLoad && onLoad(MAP.current);
        }}
      >
        <Data {...dataProps} />
        {position ? <Marker
          draggable={true}
          onLoad={onLoad}

          onDragEnd={onDragEnd}
          position={position}
        /> : null}
        {position ? <Circle
          radius={radius || 100}
          center={position}
        /> : null}
      </GoogleMap>

    </>
  ) : (
    <div>loadError</div>
  );
};

export default GoogleMapInput;
