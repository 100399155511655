import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchList } from './actions'

interface Props { }

function Clubs(props: Props) {
  const dispatch = useDispatch()

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('thisUser'))?.id;

    dispatch(fetchList(userId))
    // eslint-disable-next-line
  }, [])

  return null
}

export default Clubs
