import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
  PropTypes as MuiPropTypes,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Record } from 'ra-core';

import { Button, ButtonProps } from 'react-admin'

interface Props {
  alignIcon?: 'left' | 'right';
  children?: ReactElement;
  classes?: object;
  className?: string;
  color?: MuiPropTypes.Color;
  disabled?: boolean;
  label?: string;
  size?: 'small' | 'medium' | 'large';
}

type ButtonProps = Props & MuiButtonProps;
const AddSubClubButton: FC<EditButtonProps> = ({
  basePath = '',
  label = 'Add club',
  record,
  icon = defaultIcon,
  ...rest
}) => {
  console.log("Record", record)
  return (
    record.isGroup ?
      <Button
        component={Link}
        to={`${basePath}/create?parentId=${record && record.id}`}
        label={label}
        onClick={stopPropagation}
        {...rest as any}
      >
        {icon}
      </Button> : <></>
  )
};

const defaultIcon = <AddIcon />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

interface Props {
  basePath?: string;
  record?: Record;
  icon?: ReactElement;
}

export type EditButtonProps = Props & ButtonProps & MuiButtonProps;

AddSubClubButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
};

export default AddSubClubButton;
