import { IUser } from "src/types";
import ActionTypes from './const'
import { action } from 'typesafe-actions'
import { dateToStr } from 'src/utils/dateUtil'
import { IRole } from 'src/components/users/types'

export const periodFrom = (date: Date) => action(ActionTypes.PERIOD_FROM, date)

export const periodTo = (date: Date) => action(ActionTypes.PERIOD_TO, date)

export const fetchList = (data: { startDate: Date, endDate: Date, clubId?: number }, quiet?: boolean,) => action(ActionTypes.FETCH_LIST, {
  api: `/admin/api/schedule/?startDate=${dateToStr(data.startDate)}&endDate=${dateToStr(data.endDate)}`,
  quiet,
})

export const setClubId = (id: number) => action(ActionTypes.SET_CLUB_ID, id)

export const assign = (role: IRole, date: Date, listId: number, clubId: number) =>
  action(ActionTypes.ASSIGN, { role, date, listId, clubId })

export const unassign = (role: IRole, date: Date, listId: number) =>
  action(ActionTypes.UNASSIGN, { role, date, listId })

export const assignPeriod = (listId: number) =>
  action(ActionTypes.ASSIGN_PERIOD, { listId })
export const assignPeriodStart = (listId: any, data: any) =>
  action(ActionTypes.ASSIGN_PERIOD_START, { listId, ...data })
export const assignPeriodClose = () => action(ActionTypes.ASSIGN_PERIOD_CLOSE)
export const assignPeriodSuccess = () =>
  action(ActionTypes.ASSIGN_PERIOD_SUCCESS)
export const assignPeriodFailed = () =>
  action(ActionTypes.ASSIGN_PERIOD_FAILED)
export const detachPeriod = (listId, date: Date, type, user: IUser | null = null, clubId: any) =>
  action(ActionTypes.DETACH_PERIOD, { listId, user, type, date, clubId })
export const detachPeriodStart = (listId, data) =>
  action(ActionTypes.DETACH_PERIOD_START, { listId, ...data })
export const detachPeriodClose = () => action(ActionTypes.DETACH_PERIOD_CLOSE)
export const detachPeriodSuccess = () =>
  action(ActionTypes.DETACH_PERIOD_SUCCESS)
export const detachPeriodFailed = () =>
  action(ActionTypes.DETACH_PERIOD_FAILED)

export const cellSpinnerStart = (role: IRole, date: Date, listId: number) =>
  action(ActionTypes.CELL_SPINNER_START, { role, date, listId })

export const setIsInspectionChosen = (isChosen: boolean) => action(ActionTypes.IS_INSPECTION_CHOSEN, { isChosen })

export const setClubIdForModal = (id: number) => action(ActionTypes.SET_CLUB_ID_FOR_MODAL, id)
