import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

const ScoreField = ({
  source, record = {}
}: any) =>
  <Typography variant={'body2'}> {record[source] ? record[source].toFixed(2) : ''}</Typography>

ScoreField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default ScoreField
