import React, { Fragment } from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  ReferenceField,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  ReferenceInput,
  ImageField,
} from 'react-admin'
import ListActions from 'src/components/ListActions'
import EmptyList from 'src/components/EmptyList'
import S3ImageInput from "src/components/S3FileUpload/S3ImageUpload";
import S3ImageField from "src/components/S3ImageField";

const SupplyFilter = (props) => (
  <Filter {...props}>

    <TextInput source="name||$contL" alwaysOn={true} variant={'outlined'} fullWidth={true} label={'Search'} resettable={true} />
    <TextInput source="supplierSku" label={'Supplier Code'} />
    <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

export const SupplyList = (props) => (
  <List
    {...props}
    title="Supplies"
    filters={<SupplyFilter />}
    actions={<ListActions />}
    sort={{ field: 'name', order: 'ASC' }}
    bulkActionButtons={false}
    empty={(
      <EmptyList title={'Supplies not found'} description={'You can add a supply'} buttonText={'Create supply'} />
    )}
  >
    <Datagrid>
      <S3ImageField source="image" />
      <TextField source="name" label={'Name'} />
      <ReferenceField label="Supplier Code" source="supplierId" reference="supplier">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="supplierSku" label={'Supplier Code'} />
      <TextField source="unit" label={'Units'} />
      <TextField source="cost" label={'Cost'} />
      <EditButton />
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Supply {record.name}
    </span>
  )
}


const SupplyForm = (props: any) => {

  return (
    <Fragment>

      <TextInput source="name" label={'Item name'} variant={'outlined'} fullWidth={true} inputProps={{ autocomplete: 'off' }} />

      <TextInput multiline source="description" label={'Description'} variant={'outlined'} fullWidth={true} inputProps={{ autocomplete: 'off' }} />
      <ReferenceInput
        label="Supplier"
        source="supplierId"
        reference="supplier"
        variant={'outlined'}
        fullWidth={true}
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="brandName" label={'Brand'} variant={'outlined'} fullWidth={true} inputProps={{ autocomplete: 'off' }} />
      <TextInput source="sku" label={'Barcode ID/SKU'} variant={'outlined'} fullWidth={true} inputProps={{ autocomplete: 'off' }} />
      <TextInput source="barcode" label={'Universal Barcode'} variant={'outlined'} fullWidth={true} inputProps={{ autocomplete: 'off' }} />
      <TextInput source="supplierSku" label={'Supplier Item No.'} variant={'outlined'} fullWidth={true} inputProps={{ autocomplete: 'off' }} />
      <TextInput source="unit" label={'Unit'} variant={'outlined'} fullWidth={true} inputProps={{ autocomplete: 'off' }} />
      <TextInput source="currency" label={'Currency'} variant={'outlined'} fullWidth={true} inputProps={{ autocomplete: 'off' }} />
      <TextInput source="cost" label={'Cost'} variant={'outlined'} fullWidth={true} inputProps={{ autocomplete: 'off' }} />

      <S3ImageInput
        source="image"
        fileCoverImg="someImgURL"
        label={'Image'}
        uploadOptions={{
          multiple: false
        }}
      >
        <ImageField source="src" />
      </S3ImageInput>
    </Fragment>);
};

export const SupplyEdit = (props: any) => (
  <Edit {...props} title={<TitleEdit />} undoable={false}>
    <SimpleForm redirect={'list'}>
      <SupplyForm />
    </SimpleForm>
  </Edit>
)

export const SupplyCreate = (props: any) => (
  <Create title="Create Supply" {...props}>
    <SimpleForm redirect={'list'}>
      <SupplyForm />
    </SimpleForm>
  </Create>
)


