import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  CheckboxGroupInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  BooleanInput,
  GET_ONE,
} from "react-admin";
import { parse } from "query-string";
import { dataProvider } from "src/common/dataProvider";
import { CleaningTaskStepCreateForm } from "src/resources/cleaningTaskStep/CleaningTaskStepCreate";
import { InspectionStepCreateForm } from "src/resources/inspectionStep/InspectionStepCreate";
import { getByPath } from "src/utils/getByString";

export const CleaningListTaskCreateForm = (props: any) => {
  const { isNested, cleaningListType, children, ...rest } = props;

  console.log("...rest", props);
  const checkDaily = (val: any) => {
    // console.log("formData", val, getByPath(val, `${sourceForSearch}frequency`), `${sourceForSearch}frequency`)
    return ["daily"].indexOf(val) >= 0;
  };
  const checkMonth = (val: any) => {
    return ["yearly"].indexOf(val) >= 0;
  };
  const checkWeek = (val: any) => {
    // console.log("checkWeek", val)
    return ["yearly", "monthly"].indexOf(val) >= 0;
  };
  const checkDayOfWeek = (val: any) => {
    return ["yearly", "monthly", "weekly"].indexOf(val) >= 0;
  };
  const source = props.source || "";
  const sourceForSearch = `${props.source}` || "";
  // console.log("Source fq", `${sourceForSearch}frequency`)
  // console.log(cleaningListType, 'cleaningListType')
  return (
    <Fragment>
      {!isNested ? (
        <ReferenceInput
          source={`${source}cleaningListId`}
          reference="cleaning-list"
          allowEmpty
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      ) : null}

      <TextInput
        source={`${source}name`}
        label={"Task name"}
        fullWidth={true}
        variant={"outlined"}
      />

      {cleaningListType === "maintenance" && (
        <TextInput
          multiline
          source={`${source}description`}
          label={"Description"}
          fullWidth={true}
          variant={"outlined"}
        />
      )}

      <BooleanInput
        {...rest}
        source={`${source}isPhotoRequired`}
        label="Photo Required"
      />

      <BooleanInput
        {...rest}
        source={`${source}isTimerRequired`}
        label="Timer Required"
      />

      {(cleaningListType === "cleaning" ||
        cleaningListType === "inspection") && (
        <RadioButtonGroupInput
          {...rest}
          source={`${source}frequency`}
          label={"Frequency"}
          variant={"outlined"}
          validate={required()}
          fullWidth={true}
          choices={[
            { id: "daily", name: "Daily" },
            { id: "weekly", name: "Weekly" },
            { id: "monthly", name: "Monthly" },
            { id: "yearly", name: "Yearly" },
          ]}
        />
      )}

      <FormDataConsumer fullWidth={true} variant={"outlined"}>
        {({ formData, ...rest }) =>
          checkDaily(formData) && (
            <RadioButtonGroupInput
              source={`${source}hourOfDay`}
              variant={"outlined"}
              label={"On these hours"}
              choices={[
                { id: 0, name: "12 AM" },
                { id: 1, name: "01 AM" },
                { id: 2, name: "02 AM" },
                { id: 3, name: "03 AM" },
                { id: 4, name: "04 AM" },
                { id: 5, name: "05 AM" },
                { id: 6, name: "06 AM" },
                { id: 7, name: "07 AM" },
                { id: 8, name: "08 AM" },
                { id: 9, name: "09 AM" },
                { id: 10, name: "10 AM" },
                { id: 11, name: "11 AM" },
                { id: 12, name: "12 PM " },
                { id: 13, name: "01 PM" },
                { id: 14, name: "02 PM" },
                { id: 15, name: "03 PM" },
                { id: 16, name: "04 PM" },
                { id: 17, name: "05 PM" },
                { id: 18, name: "06 PM" },
                { id: 19, name: "07 PM" },
                { id: 20, name: "08 PM" },
                { id: 21, name: "09 PM" },
                { id: 22, name: "10 PM" },
                { id: 23, name: "11 PM" },
              ]}
              {...rest}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={"outlined"}>
        {({ formData, ...rest }) =>
          checkMonth(getByPath(formData, `${sourceForSearch}frequency`)) && (
            <SelectInput
              source={`${source}month`}
              label={"In this month"}
              variant={"outlined"}
              choices={[
                { id: 1, name: "January" },
                { id: 2, name: "February" },
                { id: 3, name: "March" },
                { id: 4, name: "April" },
                { id: 5, name: "May" },
                { id: 6, name: "June" },
                { id: 7, name: "July" },
                { id: 8, name: "August" },
                { id: 9, name: "September" },
                { id: 10, name: "October" },
                { id: 11, name: "November" },
                { id: 12, name: "December" },
              ]}
              {...rest}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={"outlined"}>
        {({ formData, ...rest }) =>
          checkWeek(getByPath(formData, `${sourceForSearch}frequency`)) && (
            <SelectInput
              source={`${source}week`}
              label={"On this week of the month"}
              variant={"outlined"}
              choices={[
                { id: 1, name: "1st week" },
                { id: 2, name: "2nd week" },
                { id: 3, name: "3rd week" },
                { id: 4, name: "4th week" },
                { id: 5, name: "Last week" },
              ]}
              {...rest}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={"outlined"}>
        {({ formData, ...rest }) =>
          checkDayOfWeek(
            getByPath(formData, `${sourceForSearch}frequency`)
          ) && (
            <CheckboxGroupInput
              source={`${source}dayOfWeek`}
              variant={"outlined"}
              label={"On this day or shift of the week"}
              choices={[
                { id: 1, name: "1st day" },
                { id: 2, name: "2nd day" },
                { id: 3, name: "3rd day" },
                { id: 4, name: "4th day" },
                { id: 5, name: "5th day" },
                { id: 6, name: "6th day" },
                { id: 7, name: "7th day" },
              ]}
              {...rest}
            />
          )
        }
      </FormDataConsumer>

      {(cleaningListType === "cleaning" ||
        cleaningListType === "inspection") && (
        <ArrayInput source={`${source}cleaningSteps`} label={"Instructions"}>
          <SimpleFormIterator>
            <CleaningTaskStepCreateForm isNested={true} />
          </SimpleFormIterator>
        </ArrayInput>
      )}

      {(cleaningListType === "cleaning" ||
        cleaningListType === "maintenance") && (
        <BooleanInput
          {...rest}
          source={`${source}hasInspection`}
          label="Has inspection"
        />
      )}

      {cleaningListType === "cleaning" && (
        <FormDataConsumer fullWidth={true} variant={"outlined"}>
          {({ formData, ...rest }) =>
            getByPath(formData, `${sourceForSearch}hasInspection`) && (
              <SelectInput
                source={`${source}inspectionFrequency`}
                label={"Inspection frequency"}
                variant={"outlined"}
                choices={[
                  { id: 1, name: "each clean" },
                  { id: 2, name: "2nd clean" },
                  { id: 3, name: "3d clean" },
                  { id: 4, name: "4th clean" },
                ]}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
      )}

      {cleaningListType === "cleaning" && (
        <ArrayInput
          source={`${source}inspectionSteps`}
          label={"Inspection instructions"}
        >
          <SimpleFormIterator>
            <InspectionStepCreateForm isNested={true} />
          </SimpleFormIterator>
        </ArrayInput>
      )}
    </Fragment>
  );
};
CleaningListTaskCreateForm.propTypes = {
  isNested: PropTypes.bool,
  cleaningListType: PropTypes.string,
};

CleaningListTaskCreateForm.defaultProps = {
  isNested: false,
  cleaningListType: null,
};
export const CleaningListTaskCreate = (props) => {
  const { cleaningListId: cleaningListIdString } = parse(props.location.search);

  const cleaningListId = cleaningListIdString
    ? parseInt(cleaningListIdString, 10)
    : "";

  const redirect = cleaningListId
    ? `/cleaning-list/${cleaningListId}/show/tasks`
    : false;
  const [cleaningListType, setCleaningListType] = useState(null);
  useEffect(() => {
    dataProvider(GET_ONE, "cleaning-list", { id: cleaningListId })
      .then(({ data }) => {
        console.log("getCleaningList", data);
        setCleaningListType(data.type);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [cleaningListId]);
  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ cleaningListId }} redirect={redirect}>
        <CleaningListTaskCreateForm cleaningListType={cleaningListType} />
      </SimpleForm>
    </Create>
  );
};
