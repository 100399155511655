import React from "react";
import { parse } from "query-string";
import {
  required,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  useDataProvider,
} from "react-admin";
import { useSelector } from "react-redux";
import { AppState } from "src/types";

const TitleEdit = ({ record }: any) => {
  return <span>Club Supply {record.name}</span>;
};

const Item = (props) => {
  const { choices } = props;

  if (!choices || choices.length === 0) {
    return <SelectInput {...props} optionText="No Options" disabled />;
  }

  return <SelectInput optionText="name" {...props} />;
};

export const ClubSupplyEdit = (props) => {
  const { location } = props;
  const redirect =
    location.state && location.state.previous ? location.state.previous : false;

  const state = useSelector(
    (state: AppState) => state.admin.resources.supply.data
  );

  return (
    <Edit {...props} title={<TitleEdit />} undoable={false}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label={
            !!Object.values(state).length ? "Supply *" : "No created supplies"
          }
          source="supplyId"
          reference="supply"
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        >
          <Item />{" "}
        </ReferenceInput>
        <TextInput
          source="minQuantity"
          label={"Min quantity"}
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        />
        <TextInput
          source="maxQuantity"
          label={"Max quantity"}
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export const ClubSupplyCreate = (props) => {
  const { clubId: clubIdString } = parse(props.location.search);
  const { location } = props;
  const clubId = clubIdString ? parseInt(clubIdString, 10) : "";

  const state = useSelector(
    (state: AppState) => state.admin.resources.supply.data
  );

  const redirect =
    location.state && location.state.previous ? location.state.previous : false;

  return (
    <Create title="Create Club supply" {...props}>
      <SimpleForm redirect={redirect} initialValues={{ clubId }}>
        <ReferenceInput
          label="Club"
          source="clubId"
          reference="club"
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label={
            !!Object.values(state).length ? "Supply *" : "No created supplies"
          }
          source="supplyId"
          reference="supply"
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        >
          <Item />
        </ReferenceInput>
        <TextInput
          source="minQuantity"
          label={"Min quantity"}
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        />
        <TextInput
          source="maxQuantity"
          label={"Max quantity"}
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
