import React from 'react'
import {
  List,
  Datagrid,
  Filter,
  SelectInput,
  Show,
  ReferenceManyField,
  ReferenceField,
  SimpleShowLayout,
  useNotify,
  Edit,
  SimpleForm,
  DateField,
  TextField,
  TextInput,
  ReferenceInput,
  DateInput,
  ImageField,
  showNotification,
  TopToolbar,
  Button,
  UPDATE,
  useRefresh,
  Pagination
} from 'react-admin'
import { dataProvider } from "src/common/dataProvider";
import ListActions from 'src/components/ListActions'
import EmptyList from 'src/components/EmptyList'
import NameWithImageField from "src/components/NameWithImageField";
import RelatedList from "src/components/RelatedList";
import S3ImageInput from "src/components/S3FileUpload/S3ImageUpload";
import StatusField from "src/components/StatusField";


const SupplyRequestFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput label="Club" source="clubId" reference="club" variant={'outlined'} fullWidth={true}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="User" source="userId" reference="user" variant={'outlined'} fullWidth={true}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <DateInput label="Created" source="createdAt" />
  </Filter>
)

export const SupplyRequestList = (props: any) => (
  <List
    {...props}
    title="Supplies"
    filters={<SupplyRequestFilter />}
    actions={<ListActions showCreate={false} />}
    sort={{ field: 'createdAt', order: 'ASC' }}
    bulkActionButtons={false}
    hasCreate={false}

    empty={(
      <EmptyList title={'Supply request not found'} description={'You can add a supply Supply request'}
        buttonText={'Create supply'} />
    )}
  >
    <Datagrid rowClick={'show'}>
      <TextField source="id" />
      <StatusField source={'status'} label={'Status'} />
      <ReferenceField label="Club" source="clubId" reference="club" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>

      <ReferenceField label="User" source="userId" reference="user" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>

      <DateField source={'createdAt'} label={'Created'} />
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Supply Request # {record.id}
    </span>
  )
}

export const SupplyRequestEdit = (props: any) => (
  <Edit {...props} title={<TitleEdit />} undoable={false}>
    <SimpleForm redirect={'list'}>

      <TextInput source="name" label={'Title'} variant={'outlined'} fullWidth={true} />
      <TextInput multiline source="description" label={'Description'} variant={'outlined'} fullWidth={true} />
      <TextInput source="brandName" label={'Brand'} variant={'outlined'} fullWidth={true} />
      <TextInput source="sku" label={'Barcode ID/SKU'} variant={'outlined'} fullWidth={true} />
      <TextInput source="barcode" label={'Universal Barcode'} variant={'outlined'} fullWidth={true} />
      <TextInput source="supplierSku" label={'Supplier Item No.'} variant={'outlined'} fullWidth={true} />
      <TextInput source="unit" label={'Unit'} variant={'outlined'} fullWidth={true} />
      <TextInput source="cost" label={'Cost'} variant={'outlined'} fullWidth={true} />
      <TextInput source="currency" label={'Currency'} variant={'outlined'} fullWidth={true} />

      <S3ImageInput
        source="image"
        fileCoverImg="someImgURL"
        label={'Image'}
        uploadOptions={{
          multiple: false
        }}
      >
        <ImageField source="src" />
      </S3ImageInput>
    </SimpleForm>
  </Edit>
)

const CloseRequestButton = (props: any) => {
  const { data, resource } = props;

  const notify = useNotify();
  const refresh = useRefresh();
  const handleClick = () => {
    console.log("HandleClick", data.status)


    const updatedRecord = { status: 'closed' };
    dataProvider(UPDATE, resource, { id: data.id, data: updatedRecord })
      .then(() => {
        console.log("Updated")
        notify('Request closed', 'info')
        refresh();

      })
      .catch((e) => {
        console.error(e);
        showNotification('Error: request not closed', 'warning')
      });
  }
  return (<Button label="Close Request" onClick={handleClick} disabled={!data || data.status === 'closed'} size={'lg'} />);
}

const SupplyRequestShowActions = (props: any) => (
  <TopToolbar>
    <CloseRequestButton {...props} />
  </TopToolbar>
);

export const SupplyRequestShow = (props: any) => (
  <Show {...props} title={<TitleEdit />} actions={<SupplyRequestShowActions />}>
    <SimpleShowLayout>
      <DateField source="createdAt" />
      <ReferenceField label="Club" source="clubId" reference="club">
        <NameWithImageField />
      </ReferenceField>
      <ReferenceField label="User" source="userId" reference="user">
        <NameWithImageField />
      </ReferenceField>

      <StatusField source={'status'} label={'Status'} />
      <ReferenceManyField reference="supply-request-item" target="requestId" addLabel={false} sort={{ field: 'id', order: 'ASC' }} pagination={<Pagination />} perPage={25}>
        <RelatedList emptyTitle={'Requests empty'} hasCustomCreate={false}>
          <TextField source={'supply.supplierName'} label={'Supplier name'} />
          <TextField source="supply.name" label={'Item'} />
          <TextField source="quantity" label={'Quantity'} />
        </RelatedList>

      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)



