import React, { useState, useEffect } from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Show,
  ReferenceManyField,
  ReferenceField,
  SimpleShowLayout,
  Create,
  SimpleForm,
  DateField,
  TextField,
  TextInput,
  ReferenceInput,
  DateInput,
  Pagination,
} from 'react-admin'
import { useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { TableHead, TableRow, TableCell } from '@material-ui/core';

import DistanceField from "src/components/DistanceField";
import DurationField from "src/components/DurationField";
import ListActions from 'src/components/ListActions'
import EmptyList from 'src/components/EmptyList'
import NameWithImageField from "src/components/NameWithImageField";
import RelatedList from "src/components/RelatedList";
import StatusField from "src/components/StatusField";
import TimeField from "src/components/TimeField";
import TimeWithTimezoneField from "src/components/TimeWithTimezoneField";
import { DateTimeInput } from 'src/components/DateInput';
import { padDigits } from "src/utils/dateUtil";

const TimeTrackingFilter = (props: any) => {
  const me = JSON.parse(localStorage.getItem('thisUser'));

  useEffect(() => {
    if (!!props?.onLoad) {
      props?.onLoad(props.filterValues)
    }
  }, [props])

  return <>{me ? (
    <Filter {...props}>
      <ReferenceInput
        label="Club"
        source="clubId"
        reference="club"
        variant={'outlined'}
        fullWidth={true}
        alwaysOn={true}
        sort={{ field: 'name', order: 'ASC', forSelector: true }}
        filter={{ userIdForClubs: me?.role && me.role !== 'superAdmin' ? me.id : null }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="User"
        source="userId"
        reference="user"
        variant={'outlined'}
        fullWidth={true}
        alwaysOn={true}
        sort={{ field: 'firstName', order: 'ASC', forSelector: true }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput source="dateStart||lte" label="From" alwaysOn={true} />
      <DateInput source="dateEnd||gte" label="To" alwaysOn={true} />
    </Filter >) : (<></>)}
  </>
}

const TitleList = (props: any) => {
  return (
    <span>
      {props.title}
    </span>
  )
}

const DatagridHeader = (props: any, filters?: any, user?: any, times?: any) => {
  const { children, classes } = props;

  const calcTotalTime = () => {
    const keys = Object.keys(props?.data);

    const time = props?.data[keys[0]].worked;

    const hours = padDigits(Math.floor(time / 60 / 60), 2);
    const min = time < 60 ? '01' : padDigits((Math.floor(time / 60) - Math.floor(time / 60 / 60) * 60), 2);

    return `${hours}:${min}`
  }

  return (
    <>
      <TableHead className={classes?.thead}>
        {!!filters
          && !!filters?.userId
          && !!filters?.['scheduleDate||lte'] && <TableRow className={`${classes?.row} ${classes?.headerRow}`}>
            <TableCell className={classes?.headerCell} colSpan={children.length}>
              <div>User {user} spent {calcTotalTime()} hours from {filters?.['scheduleDate||lte']} {!!filters?.['scheduleDate||gte'] ? `to ${filters?.['scheduleDate||gte']}` : ''}</div>
            </TableCell>
          </TableRow>}

        <TableRow className={`${classes?.row} ${classes?.headerRow}`}>
          {children.map((child: any, index: number) => (
            <TableCell key={`${child?.props?.source}_${index}`} className={classes?.headerCell}>
              {child?.props?.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead >
    </>
  )
};


export const TimeTrackingCheckInTimesList = (props: any) => {
  const [filters, setFilters] = useState<any>([])
  const [user, setUser] = useState<any>([])

  return (
    <List
      {...props}
      title={<TitleList {...props} title={'Check-in Times'} />}
      filters={<TimeTrackingFilter onLoad={(filters: any) => {
        setFilters(filters);
      }} />}
      actions={<ListActions />}
      sort={{ field: 'scheduleDate', order: 'ASC' }}
      bulkActionButtons={false}
      perPage={25}
      empty={(
        <EmptyList title={'No data'} />
      )}
    >
      <Datagrid
        rowClick={'show'}
        header={(headerProps: any) => DatagridHeader(headerProps, filters, user)}
      >
        <ReferenceField label="User" source="userId" reference="user" linkType={false}>
          <NameWithImageField isLink={false} onUserChange={(user: any) => { setUser(user) }} />
        </ReferenceField>
        <ReferenceField label="Club" source="clubId" reference="club" linkType={false}>
          <NameWithImageField isLink={false} />
        </ReferenceField>
        <DurationField sourceStartTime="agreedDuration" label={'Total agreed hours'} />
        <DurationField sourceStartTime="duration" label={'Total actual hours'} />
        <DurationField sourceStartTime="overtime" label={'Overtime/Undertime'} />
      </Datagrid>
    </List>
  )
}

export const TimeTrackingLateCheckinList = (props: any) => (
  <List
    {...props}
    title={<TitleList {...props} title={'Late Check-ins'} />}
    filters={<TimeTrackingFilter />}
    actions={<ListActions />}
    sort={{ field: 'scheduleDate', order: 'DESC' }}
    bulkActionButtons={false}
    empty={(
      <EmptyList title={'No data'} />
    )}
    perPage={25}
  >
    <Datagrid rowClick={'show'}>
      <ReferenceField label="User" source="userId" reference="user" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>
      <ReferenceField label="Club" source="clubId" reference="club" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>
      <ReferenceField label="Checklist" source="cleaningListId" reference="cleaning-list" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>
      <TimeWithTimezoneField source="listStartTime" label={'Agreed Check-In Time'} />
      <TimeField source="checkInTime" label={'Check-In Time'} />
      <DurationField sourceStartTime="lateDuration" label={'Hours/Min Late'} />
    </Datagrid>
  </List>
)


export const TimeTrackingEarlyCheckoutList = (props: any) => (
  <List
    {...props}
    title={<TitleList {...props} title={'Early Check-outs'} />}
    filters={<TimeTrackingFilter />}
    actions={<ListActions />}
    sort={{ field: 'scheduleDate', order: 'DESC' }}
    bulkActionButtons={false}
    empty={(
      <EmptyList title={'No data'} />
    )}
    perPage={25}
  >
    <Datagrid rowClick={'show'}>
      <ReferenceField label="User" source="userId" reference="user" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>
      <ReferenceField label="Club" source="clubId" reference="club" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>
      <ReferenceField label="Checklist" source="cleaningListId" reference="cleaning-list" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>
      <TimeWithTimezoneField source="listEndTime" label={'Agreed Check-Out Time'} />
      <TimeField source="checkOutTime" label={'Check-Out Time'} />
      <DurationField sourceStartTime="earlyDuration" label={'Hours/Min Early'} />
    </Datagrid>
  </List>
)

export const TimeTrackingOutOfBoundsList = (props: any) => (
  <List
    {...props}
    title={<TitleList {...props} title={'Out of bounds'} />}
    filters={<TimeTrackingFilter />}
    actions={<ListActions />}
    sort={{ field: 'scheduleDate', order: 'DESC' }}
    bulkActionButtons={false}
    empty={(
      <EmptyList title={'No data'} />
    )}
  >
    <Datagrid rowClick={'show'}>
      <ReferenceField label="User" source="userId" reference="user" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>
      <ReferenceField label="Club" source="clubId" reference="club" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>
      <ReferenceField label="Checklist" source="cleaningListId" reference="cleaning-list" linkType={false}>
        <NameWithImageField isLink={false} />
      </ReferenceField>
      <DateField showTime={true} source="checkInTime" label={'Check-In Time'} />
      <DateField showTime={true} source="checkOutTime" label={'Check-Out Time'} />
      <DistanceField source="distance" label={'Distance from location'} />
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      History for {1}
    </span>
  )
}

export const timeTrackingShow = (props: any) => {
  return (
    <Show {...props} title={<TitleEdit />}>
      <SimpleShowLayout>

        <ReferenceField label="Club" source="clubId" reference="club">
          <NameWithImageField />
        </ReferenceField>
        <TextField source="cleaningList.name" label={'Checklist'} />
        <StatusField source="status" label={'Status'} />
        <DateField source={'scheduleDate'} label={'Date'} />

        <ReferenceManyField reference="cleaning-list-job-task" target="cleaningListJobId" addLabel={false} pagination={<Pagination />} perPage={25}>
          <RelatedList
            expand={<TimeTrackingItemShow />}
            emptyTitle={'Checklist Tasks empty'}
            hasCustomCreate={false}>

            <TextField source="cleaningListTask.name" label={'Task name'} />

          </RelatedList>

        </ReferenceManyField>

      </SimpleShowLayout>
    </Show>
  )
}

export const TimeTrackingItemShow = (props: any) => (
  <Show {...props} >
    <SimpleShowLayout>

      <ReferenceManyField reference="inspection-step-job" target="cleaningListJobTaskId" addLabel={false} pagination={<Pagination />} perPage={50}>
        <RelatedList
          emptyTitle={'Inspections steps history empty'}
          hasCreate={false}>

          <TextField source="inspectionStep.name" label={'Inspection Step Name'} />
          <StatusField source="status" label={'Status'} />
          <DateField showTime={true} source="createdAt" label={'Time'} />
          <ReferenceField label="Inspector" source="inspectorId" reference="user">
            <NameWithImageField />
          </ReferenceField>
        </RelatedList>

      </ReferenceManyField>
      <ReferenceManyField reference="issue" target="jobTaskId" addLabel={false} pagination={<Pagination />} perPage={50}>
        <RelatedList
          emptyTitle={'Issues empty'}
          hasCreate={false}>

          <TextInput source="spotName" label={'Issue Location'} />

        </RelatedList>

      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

export const TimeTrackingCreate = (props: any) => {
  const { type, templateId, cloneId } = parse(props.location.search)
  const [addValues] = useState({})
  const location = useLocation();

  useEffect(() => {
    if (templateId || cloneId) {
      // dataProvider(GET_ONE, 'cleaning-list', { id: templateId || cloneId })
      //   .then(({ data }) => {
      //     console.log("getted", data)
      //     const { tasks, type, startTime, endTime } = data;
      //     setAddValues({ tasks, type, startTime, endTime })
      //   })
      //   .catch((e: any) => {
      //     console.error(e);
      //     showNotification('Error: request not closed', 'warning')
      //   });
    }
    // eslint-disable-next-line
  }, [location.search])

  const redirect = {
    pathname: '/time-tracking',
    search: stringify({
      page: 1,
      perPage: 25,
      sort: 'id',
      order: 'DESC',
      filter: JSON.stringify({ isTemplate: true, ...(type ? { type } : (addValues as any).type ? { type: (addValues as any).type } : {}) }),
    }),
  }

  return (
    <Create title={'Time Tracking'} {...props}>

      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="User"
          source="userId"
          reference="user"
          variant={'outlined'}
          fullWidth={true}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          label="Club"
          source="clubId"
          reference="club"
          variant={'outlined'}
          fullWidth={true}
          filter={{ isGroup: false }}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          label="Checklist"
          source="cleaningListId"
          reference="cleaning-list"
          variant={'outlined'}
          fullWidth={true}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        {location.pathname.includes('checkin-times') &&
          <div>
            <DateTimeInput
              source='checkInTime'
              variant={'outlined'}
              fullWidth={true}
              validate={required()}
            />
            <DateTimeInput
              source={'checkOutTime'}
              variant={'outlined'}
              fullWidth={true}
              // label={"Check out time"}
              validate={required()}
            />

            <div style={{ marginTop: 20 }}>
              <TextInput source="agreedHours" label={'Agreed Hours'} validate={required()} />
              <TextInput source="actualHours" label={'Actual Hours'} validate={required()} />
            </div>
          </div>
        }

        {location.pathname.includes('late-checkins') &&
          <div>
            <DateTimeInput
              source='checkInTime'
              variant={'outlined'}
              fullWidth={true}
              validate={required()}
            />
            <DateTimeInput
              source={'manualStartTime'}
              variant={'outlined'}
              fullWidth={true}
              validate={required()}
            />
          </div>
        }

        {location.pathname.includes('early-checkouts') &&
          <div>
            <DateTimeInput
              source='checkOutTime'
              variant={'outlined'}
              fullWidth={true}
              validate={required()}
            />
            <DateTimeInput
              source={'manualEndTime'}
              variant={'outlined'}
              fullWidth={true}
              validate={required()}
            />
          </div>
        }

      </SimpleForm>
    </Create>
  )
}
