import ActionTypes from './const'
import { action } from 'typesafe-actions'
import { FormData } from './RegistrationForm'
export const getUserByInvite = (inviteToken) => action(ActionTypes.GET_USER_BY_INVITE, {inviteToken})
export const getUserByInviteFinished = (payload: { id: number, email: string, name: string, phone: string }) => action(ActionTypes.GET_USER_BY_INVITE_FINISHED, payload)
export const getUserByInviteFailed = (payload: { error: string }) => action(ActionTypes.GET_USER_BY_INVITE_FAILED, payload)

export const registration = (formData: FormData) => action(ActionTypes.REGISTRATION, formData)
export const registrationFinished = (payload: { accessToken: string }) => (
  action(ActionTypes.REGISTRATION_FINISHED, payload)
)

export const registrationFailed = (payload: { error: string }) => action(ActionTypes.REGISTRATION_FAILED, payload)
