import React, {
  FunctionComponent,
  Children,
  cloneElement,
  isValidElement,
  ReactElement, useState,
} from 'react'
import PropTypes from 'prop-types'
import { shallowEqual } from 'recompose'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import classnames from 'classnames'
import { useInput, useTranslate, InputProps } from 'ra-core'
import FileInputPreview from './FileInputPreview'
import sanitizeRestProps from '../sanitizeRestProps'
import { InputHelperText, Labeled } from 'ra-ui-materialui'
import Cookies from 'js-cookie'

const useStyles = makeStyles(
  theme => ({
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(
        theme.palette.background.default,
      ),
    },
    previews: {
      display: 'flex',
    },
    preview: {},
    removeButton: {},
    root: { width: '100%' },
  }),
  { name: 'RaFileInput' },
)

export interface FileInputProps {
  accept?: string
  labelMultiple?: string
  labelSingle?: string
  maxSize?: number
  minSize?: number
  multiple?: boolean
}

export interface FileInputOptions extends DropzoneOptions {
  inputProps?: any
  // tslint:disable-next-line:ban-types
  onRemove?: Function
}

const FileInput: FunctionComponent<
  FileInputProps & InputProps<FileInputOptions>
> = props => {
  const {
    accept,
    children,
    className,
    classes: classesOverride,
    format,
    helperText,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    uploadOptions,
    options: {
      inputProps: inputPropsOptions,
      ...options
    } = {} as FileInputOptions,
    parse,
    placeholder,
    resource,
    source,
    validate,
    record,
    title,
    ...rest
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)

  const [isLoading, setIsLoading] = useState(false)

  // turn a browser dropped file structure into expected structure
  const transformFile = (file: any) => {
    if (!(file instanceof File)) {
      return file
    }

    // tslint:disable-next-line:no-shadowed-variable
    const { source, title } = (Children.only(children) as ReactElement<
      any
    >).props

    const preview = URL.createObjectURL(file)
    const transformedFile = {
      rawFile: file,
      [source]: preview,
    }

    if (title) {
      transformedFile[title] = file.name
    }

    return transformedFile
  }

  // tslint:disable-next-line:no-shadowed-variable
  const transformFiles = (files: any[]) => {
    if (!files) {
      return multiple ? [] : null
    }

    if (Array.isArray(files)) {
      return files.map(transformFile)
    }

    return transformFile(files)
  }

  const {
    id,
    input: { onChange, value },
    meta,
    isRequired,
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    type: 'file',
    validate,
    ...rest,
  })
  const { touched, error } = meta

  const files = value ? (Array.isArray(value) ? value.map((file) => file?.path || file) : [value]) : []

  const onDrop = async (newFiles: any, rejectedFiles: any, event: any) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles]

    setIsLoading(true)

    if (multiple) {
      onChange(updatedFiles)
    } else {
      onChange(updatedFiles[0])
    }

    for (const file of newFiles) {
      if (file.hasOwnProperty('rawFile')) {
        await saveFiles(file.rawFile)
      } else {
        await saveFiles(file)
      }
    }

    if (multiple) {
      onChange(updatedFiles)
    } else {
      onChange(updatedFiles[0])
    }

    setIsLoading(false)
  }

  const saveFiles = async (file: any) => {
    const token = Cookies.get('auth-token')
    const newFilePath = file.path.replace(/ /g, '_')
    const getRequest = new Request(`${process.env.REACT_APP_API_URL}/api/s3/sign?objectName=${newFilePath}&contentType=${file.type}&path=clubtasker/files`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
    })

    const getResponseData = await fetch(getRequest).then((getResponse: any) => {
      return getResponse.json()
    }).catch((e: any) => {
      console.log('Error: ', e)
    })

    const formDataBody = new FormData()

    formDataBody.append('file', newFilePath)

    const putRequest = new Request(getResponseData.signedUrl, {
      method: 'PUT',
      headers: new Headers({ 'Content-Type': file.type }),
      body: file,
    })

    fetch(putRequest)
      .then((response: any) => {
        return getResponseData.fileKey
      })
      .catch((e: any) => {
        console.log('Error: ', e)
      })

    return getResponseData.filename
  }

  const onRemove = (file: any) => () => {
    if (multiple) {
      const filteredFiles = files.filter(
        stateFile => !shallowEqual(stateFile, file),
      )
      onChange(filteredFiles as any)
    } else {
      onChange(null)
    }

    if (options.onRemove) {
      options.onRemove(file)
    }
  }

  const childrenElement = isValidElement(Children.only(children))
    ? (Children.only(children) as ReactElement)
    : undefined

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  })
  // console.log(rawFile, 'raw')
  return (
    <Labeled
      id={id}
      label={label}
      className={classnames(classes.root, className)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      meta={meta}
      {...sanitizeRestProps(rest)}
    >
      <>
        <div
          data-testid="dropzone"
          className={classes.dropZone}
          {...getRootProps()}
        >
          <input
            id={id}
            {...getInputProps()}
          />
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <>
              {label === 'File'
                ? <p>Drop documents to upload, or click to select it.</p>
                : <p>{translate(labelMultiple)}</p>}
            </>
          ) : (
            <>
              {label === 'File'
                ? <p>Drop a document to upload, or click to select it.</p>
                : <p>{translate(labelSingle)}</p>
              }
            </>
          )}
        </div>
        <FormHelperText>
          <InputHelperText
            touched={touched}
            error={error}
            helperText={helperText}
          />
        </FormHelperText>
        {children && (
          <div className={classes.previews}>
            {(Array.isArray(files) ? files : [files]).map((file, index) => {
              // console.log('files: ', file)
              const newFilePath = file.rawFile ? null : file.replace(/ /g, '_')

              return (
                <FileInputPreview
                  key={index}
                  file={file}
                  loading={isLoading}
                  progress={0}
                  onRemove={onRemove(file)}
                  className={classes.removeButton}
                  canRemove={label !== 'File'}
                >
                  {console.log(`https://admin.dev.clubtasker.com/api/s3/uploads/${newFilePath}`)}
                  {cloneElement(childrenElement, {
                    record: file.rawFile
                      ? file
                      : {
                        src: `https://admin.dev.clubtasker.com/api/s3/uploads/${newFilePath}`,
                      },
                    className: classes.preview,
                    title: !!title ? title : typeof file === 'string' ? file?.split('/')[file?.split('/').length - 1] : 'title',
                  })}
                </FileInputPreview>
              )
            })}
          </div>
        )}
      </>
    </Labeled>
  )
}

FileInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelMultiple: PropTypes.string,
  labelSingle: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.node,
}

export default FileInput
