const sanitizeRestProps = ({
                              allowEmpty,
                              alwaysOn,
                              basePath,
                              component,
                              defaultValue,
                              formClassName,
                              initialValue,
                              initializeForm,
                              input,
                              isRequired,
                              label,
                              limitChoicesToValue,
                              locale,
                              meta,
                              options,
                              optionText,
                              optionValue,
                              record,
                              resource,
                              source,
                              textAlign,
                              translate,
                              hasCreate,
                              translateChoice,
                              ...rest
                          }: any) => rest
export default sanitizeRestProps
