import React, { useEffect, useRef } from 'react'
import { Login } from 'react-admin'


const MyLoginPage = () => {
    
    const login = useRef(null);
    useEffect(() => {
        console.log("Ref", login.current.childNodes[0].style.justifyContent = "center");
    }, [login])

    return (
        <>
            <div style={{ position: "absolute", left: '50%', top: '80%', marginBottom: '100px', transform: 'translate(-50%)' }}>
                <img src="/logo.png" alt="" />
            </div>
            <div className="login" ref={login}>
                <Login backgroundImage="/background_two.jpg" />
            </div>
        </>
    )
}
export default MyLoginPage
