import React, { useEffect, ReactNode, FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import IconButton from '@material-ui/core/IconButton'
import { useTranslate } from 'ra-core'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';

const useStylesLoader = makeStyles(
  theme => ({
    text: {
      color: 'white'
    }
  }),
  { name: 'RaFileInputPreview' },
)

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  const classes = useStylesLoader(props)
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" className={classes.text}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
const useStyles = makeStyles(
  theme => ({
    removeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px'
    },
    root: {
      position: 'relative',
      margin: '15px 0px'
    },
    removeIcon: {
      color: theme.palette.error.main,
    },
    loader: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
      background: 'rgba(0, 0, 0, 0.3)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }),
  { name: 'RaFileInputPreview' },
)

interface Props {
  children: ReactNode
  className?: string
  classes?: object
  progress?: number,
  loading?: boolean,
  onRemove: () => void
  file: any
  canRemove: boolean
}

const FileInputPreview: FunctionComponent<Props> = props => {
  const {
    children,
    classes: classesOverride,
    className,
    onRemove,
    progress,
    loading,
    file,
    canRemove,
    ...rest
  } = props
  const classes = useStyles(props)
  const translate = useTranslate()

  useEffect(() => {
    return () => {
      const preview = file && file.rawFile ? file.rawFile.preview : file.preview

      if (preview) {
        window.URL.revokeObjectURL(preview)
      }
    }
  }, [file])

  return (
    <div className={classes.root} {...rest}>

      {canRemove && <IconButton
        className={classes.removeButton}
        onClick={onRemove}
        aria-label={translate('ra.action.delete')}
        title={translate('ra.action.delete')}
      >
        <RemoveCircle className={classes.removeIcon} />
      </IconButton>}
      {loading && <div className={classes.loader}>
        {progress > 0 ? <CircularProgressWithLabel value={progress || 0} /> : <CircularProgress />}
      </div>}
      {children}
    </div>
  )
}

FileInputPreview.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  file: PropTypes.any,
  onRemove: PropTypes.func.isRequired,
  canRemove: PropTypes.bool,
}

FileInputPreview.defaultProps = {
  file: undefined,
}

export default FileInputPreview
