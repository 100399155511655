import React from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  TextField,
  EditButton,
  Pagination,
} from 'react-admin'
import OrderButtons from "src/components/OrderButtons";
import RelatedList from "src/components/RelatedList";
export const CleaningListTaskShow = props => {

  console.log("sdddd", props)
  return (
    <Show {...props} >
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="name" />
          <TextField source="Description" />
        </Tab>
        <Tab label="Instructions" path="steps">
          <ReferenceManyField reference="cleaning-task-step" target="cleaningTaskId" addLabel={false} sort={{ field: 'sort', order: 'ASC' }} pagination={<Pagination />} perPage={50}>
            <RelatedList rowClick={'show'}
              hasCustomCreate={true}
              emptyTitle={'Task cleaning instructions empty'}
              createButtonLabel={'Create cleaning instruction'}
              createButtonTo={props.id ? `/cleaning-task-step/create?cleaningTaskId=${props.id}` : null}>

              <TextField source="name" label={'Instruction name'} />
              <EditButton />
              <OrderButtons source="sort" />
            </RelatedList>
          </ReferenceManyField>

        </Tab>
        <Tab label="Inspection instructions" path="inspection-steps">
          <ReferenceManyField reference="inspection-step" target="cleaningTaskId" addLabel={false} sort={{ field: 'sort', order: 'ASC' }} pagination={<Pagination />} perPage={50}>
            <RelatedList rowClick={'show'}
              emptyTitle={'Inspection instructions empty'}
              createButtonLabel={'Create inspection instruction'}
              createButtonTo={props.id ? `/inspection-step/create?cleaningTaskId=${props.id}` : null}>

              <TextField source="name" label={'Instruction name'} />
              <EditButton />
              <OrderButtons source="sort" />
            </RelatedList>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
