import apiSaga from './apiSaga'
import registrationSaga from 'src/resources/registration/sagas'
import assignCleanersSaga from 'src/resources/AssignCleanersPage/sagas'
import dashboardSaga from 'src/resources/DashboardPage/sagas'
import chatSaga from 'src/resources/Messages/sagas'

export default [
  apiSaga,
  registrationSaga,
  assignCleanersSaga,
  dashboardSaga,
  chatSaga
]
