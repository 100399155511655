import { format } from "date-fns";
import React, { useState, Fragment, useEffect } from "react";
import { cloneElement } from "react";
import { parse } from "query-string";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Show,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  Edit,
  Create,
  FormDataConsumer,
  SimpleForm,
  TextField,
  BooleanField,
  EditButton,
  useListParams,
  TextInput,
  ReferenceInput,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  Pagination,
  ArrayInput,
  SimpleFormIterator,
  showNotification,
  GET_ONE,
  BooleanInput,
  FunctionField,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { Link, useLocation } from "react-router-dom";
import { stringify } from "query-string";

import { dataProvider } from "src/common/dataProvider";
import { DateTimeInput, TimeInput } from "src/components/DateInput";
import EmptyList from "src/components/EmptyList";
import NameWithImageField from "src/components/NameWithImageField";
import OrderButtons from "src/components/OrderButtons";
import RelatedList from "src/components/RelatedList";
import CreateCleaningListModal from "src/resources/cleaningList/components/CreateCleaningListModal";
import { CleaningListTaskCreateForm } from "src/resources/cleaningListTask/CleaningListTaskCreate";
import { timeToDate } from "src/utils/dateUtil";
import moment from "moment";

const formatTime = (time: string) => {
  const isTimeFormat = moment(time, "h:mmA", true).isValid();
  if (isTimeFormat) {
    return time;
  }
  return "--";
};

const validateNotEmptyOrWhitespace = (value: string): string | undefined => {
  if (!value || !value.trim()) {
    return "This field cannot be empty or contain only spaces";
  }
  return undefined;
};

const CleaningFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={"id"} />
    <TextInput source="name" alwaysOn={true} label={"Search"} />
    <ReferenceInput
      label="Club"
      source="clubId"
      reference="club"
      variant={"outlined"}
      filter={{ isGroup: false }}
      fullWidth={true}
      validate={required()}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const LinkToTaskSteps = ({ record }: any) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/cleaning-list-task/${record.id}/show/steps`,
      }}
    >
      Instructions
    </Button>
  ) : null;
};
const LinkToTaskInspectionsSteps = ({ record }: any) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/cleaning-list-task/${record.id}/show/inspection-steps`,
      }}
    >
      Inspection instructions
    </Button>
  ) : null;
};
const CreateFromTemplateButton = ({ basePath, resource, to, listType }) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = (e: any) => {
    setShowDialog(true);
    e.preventDefault();
  };
  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <Fragment>
      <CreateButton onClick={handleClick} />
      <CreateCleaningListModal
        show={showDialog}
        basePath={basePath}
        resource={resource}
        handleClose={handleClose}
        to={to}
        listType={listType}
      />
    </Fragment>
  );
};
const ListActions = (props) => {
  const {
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    type,
    ...rest
  } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {!filterValues.isTemplate ? (
        <CreateFromTemplateButton
          resource={resource}
          basePath={basePath}
          listType={type}
          to={`${basePath}/create/?${stringify({
            ...(filterValues ? { type: filterValues.type || type } : {}),
            templateId: filterValues.id,
          })}`}
        />
      ) : (
        <CreateButton
          basePath={basePath}
          to={`${basePath}/create?${type ? `type=${type}` : "isTemplate=1"}`}
        />
      )}
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const TitleList = (props) => {
  const location = useLocation();
  const [query] = useListParams({ ...props, location });
  const { filterValues } = query;
  const type = filterValues && filterValues.type ? filterValues.type : null;
  const TitleMap = {
    cleaning: "Cleaning Checklist",
    inspection: "Inspection Checklist",
    maintenance: "One Off Checklist",
  };

  return <span>{TitleMap[type] ? TitleMap[type] : "Checklist"}</span>;
};
export const CleaningList = (props: any) => {
  const location = useLocation();
  const [query] = useListParams({ ...props, location });
  const { filterValues } = query;
  const [type, setType] = useState<string>();

  useEffect(() => {
    setType(new URLSearchParams(location.search).get("type"));
  }, [location]);

  return (
    <List
      {...props}
      title={<TitleList {...props} />}
      filters={<CleaningFilter />}
      actions={<ListActions type={type} />}
      sort={{ field: "name", order: "ASC" }}
      bulkActionButtons={false}
      empty={
        <EmptyList
          title={"Checklist not found"}
          description={"You can add a Checklist"}
          buttonText={"Create Checklist"}
        />
      }
    >
      <Datagrid
        rowClick={(id: number) => `cleaning-list/${id}/show?type=${type}`}
      >
        {type !== "template" && (
          <ReferenceField
            label="Club"
            source="clubId"
            reference="club"
            linkType={false}
          >
            <NameWithImageField isLink={false} />
          </ReferenceField>
        )}
        <TextField
          source="name"
          label={filterValues.isTemplate ? "Template Name" : "Checklist name"}
        />
        {/* <TextField source="startTime" label={"Start time"} />
        <TextField source="endTime" label={"Finish time"} /> */}
        <FunctionField
          label="Start time"
          render={(record) => formatTime(record.startTime)}
        />
        <FunctionField
          label="Finish time"
          render={(record) => formatTime(record.endTime)}
        />
      </Datagrid>
    </List>
  );
};

const TitleEdit = ({ record }: any) => {
  return <span>Checklist {record.name}</span>;
};
const CleaningListShowActions = (props) => {
  const { basePath, data } = props;

  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <CreateButton
        component={Link}
        to={`${props.basePath}/create?cloneId=${props.id}`}
        label={"Copy"}
      >
        <IconContentAdd /> Copy
      </CreateButton>
    </TopToolbar>
  );
};

export const CleaningListShow = (props: any) => {
  const [cleaningListType, setCleaningListType] = useState(null);

  useEffect(() => {
    dataProvider(GET_ONE, "cleaning-list", { id: props.id })
      .then(({ data }) => {
        console.log("getCleaningList", data);
        setCleaningListType(data.type);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }, [props.id]);

  return (
    <Show
      {...props}
      title={<TitleEdit />}
      actions={<CleaningListShowActions {...props} />}
    >
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="name" />
          <ReferenceField label="Club" source="clubId" reference="club">
            <NameWithImageField />
          </ReferenceField>
          <TextField source="startTime" label={"Start time"} />
          <TextField source="endTime" label={"Finish time"} />

          <ReferenceManyField
            reference="cleaning-list-task"
            target="cleaningListId"
            addLabel={false}
            sort={{ field: "sort", order: "ASC" }}
            pagination={<Pagination />}
            perPage={25}
          >
            <RelatedList
              hasCustomCreate={true}
              emptyTitle={"Checklist Tasks empty"}
              createButtonLabel={"Create checklist task"}
              createButtonTo={
                props.id
                  ? `/cleaning-list-task/create?cleaningListId=${props.id}`
                  : "/create"
              }
            >
              <TextField source="name" label={"Task name"} />
              <TextField source="frequency" label={"Frequency"} />
              <BooleanField source="hasInspection" label={"Inspection"} />
              <TextField
                source="inspectionFrequency"
                label={"Inspection frequency"}
              />
              <LinkToTaskSteps />
              {cleaningListType !== "inspection" && (
                <LinkToTaskInspectionsSteps />
              )}
            </RelatedList>
          </ReferenceManyField>
        </Tab>
        <Tab label="TASKS AND INSTRUCTIONS" path="tasks">
          <ReferenceManyField
            reference="cleaning-list-task"
            target="cleaningListId"
            addLabel={false}
            sort={{ field: "sort", order: "ASC" }}
            pagination={<Pagination />}
            perPage={25}
            hasCreate={true}
          >
            <RelatedList
              emptyTitle={"Checklist Tasks empty"}
              createButtonLabel={"Create checklist task"}
              createButtonTo={
                props.id
                  ? `/cleaning-list-task/create?cleaningListId=${props.id}`
                  : null
              }
            >
              <TextField source="name" label={"Task name"} />
              <TextField source="frequency" label={"Frequency"} />
              <BooleanField source="hasInspection" label={"Inspection"} />
              <TextField
                source="inspectionFrequency"
                label={"Inspection frequency"}
              />
              <LinkToTaskSteps />
              <LinkToTaskInspectionsSteps />
              <EditButton />
              <OrderButtons source="sort" />
            </RelatedList>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export const CleaningListEdit = (props: any) => {
  // const validateDate = (values) => {
  //   let errors: any = {};
  //   if(values.startTime && values.endTime){
  //     const currentTime = moment().format("HH:mm");
  //     console.log('current time', currentTime)
  //     if(values.startTime <= currentTime){
  //       errors.startTime = 'Start time must be less than current time'
  //     }else if(values.endTime <= values.startTime){
  //       errors.endTime = 'End time can not be more than start time'
  //     }
  //   }
  //   return errors
  // }

  return (
    <Edit {...props} title={<TitleEdit />} undoable={false}>
      <SimpleForm
        redirect={"show"}
        // validate={validateDate}
      >
        <TextInput
          source="name"
          label={"Checklist name"}
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        />
        <SelectInput
          source="type"
          label={"Type"}
          variant={"outlined"}
          fullWidth={true}
          choices={[
            { id: "cleaning", name: "Cleaning" },
            { id: "inspection", name: "Inspection" },
            { id: "maintenance", name: "One Off" },
          ]}
        />

        <FormDataConsumer fullWidth={true} variant={"outlined"}>
          {({ formData, ...rest }) =>
            !formData.isTemplate && (
              <ReferenceInput
                label="Club"
                source="clubId"
                reference="club"
                variant={"outlined"}
                fullWidth={true}
                filter={{ isGroup: false }}
                validate={required()}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <TimeInput
          type={"time"}
          source="startTime"
          label="Start time"
          options={{ format: "hh:mm a" }}
          format={(val: any) => timeToDate(val)}
          parse={(val: any) => format(new Date(val), "HH:mm:00xx")}
          fullWidth={true}
          variant={"outlined"}
          // validate={required()}
        />
        <TimeInput
          source="endTime"
          label="End time"
          options={{ format: "hh:mm a" }}
          format={(val: any) => timeToDate(val)}
          parse={(val: any) => format(new Date(val), "HH:mm:00xx")}
          fullWidth={true}
          variant={"outlined"}
          // validate={required()}
        />

        <FormDataConsumer fullWidth={true} variant={"outlined"}>
          {({ formData, ...rest }) =>
            formData.type === "maintenance" && (
              <DateTimeInput
                source="dueBy"
                label="Due By date and time"
                fullWidth={true}
                variant={"outlined"}
                options={{ format: "dd.MM.yyyy hh:mm a" }}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
const TitleCreate = ({ record, type, isTemplate }: any) => {
  let title;
  switch (type) {
    case "cleaning":
      title = "Create Cleaning Checklist";
      break;
    case "inspection":
      title = "Create Inspection Checklist";
      break;
    case "maintenance":
      title = "Create One Off Checklist";
      break;
    default:
      if (isTemplate) {
        title = "Create Checklist Template";
      } else {
        title = "Create Checklist";
      }
  }
  return <span>{title}</span>;
};

export const CleaningListCreate = (props: any) => {
  const { type, isTemplate, templateId, cloneId } = parse(
    props.location.search
  );
  const { search } = useLocation();
  const [addValues, setAddValues] = useState({});

  const location = useLocation();

  useEffect(() => {
    if (templateId || cloneId) {
      dataProvider(GET_ONE, "cleaning-list", { id: templateId || cloneId })
        .then(({ data }) => {
          const { tasks, type, startTime, endTime } = data;
          setAddValues({ tasks, type, startTime, endTime });
        })
        .catch((e: any) => {
          console.error(e);
          showNotification("Error: request not closed", "warning");
        });
    }
    // eslint-disable-next-line
  }, [search]);

  const redirect = {
    pathname: "/cleaning-list",
    search: stringify({
      page: 1,
      perPage: 25,
      sort: "id",
      order: "DESC",
      filter: JSON.stringify({
        isTemplate: false,
        type: new URLSearchParams(location.search).get("type") || "cleaning",
      }),
    }),
  };

  // const validateDate = (values) => {
  //   let errors: any = {};
  //   if(values.startTime && values.endTime){
  //     const currentTime = moment().format("HH:mm");
  //     console.log('current time', currentTime)
  //     if(values.startTime <= currentTime){
  //       errors.startTime = 'Start time must be less than current time'
  //     }else if(values.endTime <= values.startTime){
  //       errors.endTime = 'End time can not be more than start time'
  //     }
  //   }
  //   return errors
  // }

  return (
    <Create
      title={<TitleCreate type={type} isTemplate={isTemplate} />}
      {...props}
    >
      <SimpleForm
        redirect={redirect}
        // validate={validateDate}
        initialValues={{
          ...(type ? { type } : {}),
          ...(isTemplate ? { isTemplate: true } : {}),
          ...addValues,
          isTemplate: false,
        }}
      >
        {!isTemplate && (
          <ReferenceInput
            label="Club"
            source="clubId"
            reference="club"
            variant={"outlined"}
            fullWidth={true}
            filter={{ isGroup: false }}
            validate={required()}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <TextInput
          source="name"
          label={"Checklist name"}
          variant={"outlined"}
          fullWidth={true}
          validate={[required(), validateNotEmptyOrWhitespace]}
        />
        <SelectInput
          source="type"
          label={"Type"}
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
          choices={[
            { id: "cleaning", name: "Cleaning" },
            { id: "inspection", name: "Inspection" },
            { id: "maintenance", name: "One Off" },
          ]}
        />
        <TimeInput
          type={"time"}
          source="startTime"
          label="Start time"
          options={{ format: "hh:mm a" }}
          format={(val: any) => timeToDate(val)}
          parse={(val: any) => format(new Date(val), "HH:mm:00xx")}
          fullWidth={true}
          variant={"outlined"}
          // validate={required()}
        />
        <TimeInput
          type={"time"}
          source="endTime"
          label="End time"
          options={{ format: "hh:mm a" }}
          format={(val: any) => timeToDate(val)}
          parse={(val: any) => format(new Date(val), "HH:mm:00xx")}
          fullWidth={true}
          variant={"outlined"}
          // validate={required()}
        />

        <FormDataConsumer fullWidth={true} variant={"outlined"}>
          {({ formData, ...rest }) =>
            formData.type === "maintenance" && (
              <DateTimeInput
                source="dueBy"
                label="Due By date and time"
                fullWidth={true}
                variant={"outlined"}
                options={{ format: "dd.MM.yyyy hh:mm a" }}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true} variant={"outlined"}>
          {({ formData, ...rest }) => (
            <ArrayInput source="tasks">
              <SimpleFormIterator>
                <CleaningListTaskCreateForm
                  isNested={true}
                  cleaningListType={new URLSearchParams(location.search).get(
                    "type"
                  )}
                  {...rest}
                />
              </SimpleFormIterator>
            </ArrayInput>
          )}
        </FormDataConsumer>

        <BooleanInput label="Save as template" source="isTemplate" />
      </SimpleForm>
    </Create>
  );
};
