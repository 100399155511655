import { takeLatest, put } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import requestGen from 'src/utils/requestGen'
import {
  getUserByInvite, getUserByInviteFailed,
  getUserByInviteFinished,
  registration,
  registrationFailed,
  registrationFinished
} from './actions'
import ActionTypes from './const'
import { showNotification } from 'ra-core'
import { push } from 'connected-react-router'
import * as Cookies from 'js-cookie'
function* registrationSaga() {
  yield takeLatest(ActionTypes.REGISTRATION, function* (action: ActionType<typeof registration>) {
    console.log('CALL REGISTER')

    const res = yield* requestGen({
      url: '/api/user/register',
      method: 'POST',
      data: { ...action.payload },
    })

    if (res.data) {
      console.log('DATA', res.data)
      yield put(registrationFinished({
        accessToken: res.data.accessToken,
      }))
      Cookies.set('auth-token', res.data.accessToken)
      yield put(push('/'))
    } else {
      console.log('Err', res.err)
      yield put(showNotification(res.err && res.err.errors && Array.isArray(res.err.errors) && res.err.errors.length > 0 ? res.err.errors[0] : (res.err && res.err.errors && res.err.errors ? res.err.errors : 'Error happened'), 'error'))
      yield put(registrationFailed({
        error: res.err,
      }))
    }
  })

  yield takeLatest(ActionTypes.GET_USER_BY_INVITE, function* (action: ActionType<typeof getUserByInvite>) {
    console.log('CALL GET_USER_BY_INVITE')

    const res = yield* requestGen({
      url: `/api/auth/user/${action.payload.inviteToken}`,
      method: 'GET'
    })

    if (res.data) {
      console.log('DATA', res.data)
      yield put(getUserByInviteFinished(res.data))
    } else {
      console.log('Err', res.err)
      yield put(showNotification('Error reg', 'error'))
      yield put(getUserByInviteFailed({
        error: res.err,
      }))
    }
  })
}

export default registrationSaga
