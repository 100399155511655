import { ActionType } from 'typesafe-actions'
import ActionTypes from './const'
import * as appActions from './actions'
import globalActionTypes from 'src/constants'
import { IClub } from './types'

export interface State {
  loading: boolean
  list: IClub[]
  clubFilter: IClub
}

const initialState: State = {
  loading: false,
  list: [],
  clubFilter: null,
}

function reducer(state = { ...initialState }, action: ActionType<typeof appActions>) {
  switch (action.type) {
    case ActionTypes.FETCH_LIST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.FETCH_LIST + globalActionTypes.SUCCESS:
      let data = action.payload as any

      if (data?.result) {
        data = data.result
      };

      return {
        ...state,
        loading: false,
        list: data?.map((item: any) => (
          {
            id: item?.id,
            name: item?.name,
          }
        )),
      }
    case ActionTypes.SET_FILTER:
      return {
        ...state,
        clubFilter: action.payload,
      }
    default:
      return state
  }
}

export default reducer
