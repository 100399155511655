import { IUser } from "src/types";
import { ActionType } from 'typesafe-actions'
import { add } from 'date-fns'
import ActionTypes from './const'
import * as appActions from './actions'
import globalActionTypes from 'src/constants'
import { IClub } from './types'
import { strToDate } from 'src/utils/dateUtil'
import { IRole } from 'src/components/users/types'

export interface State {
  periodFrom: Date
  periodTo: Date
  loading: boolean
  assignOpen: boolean
  assignPeriodSaving: boolean,
  assignPeriodListId?: number,
  detachOpen: boolean
  detachPeriodSaving: boolean,
  detachPeriodListId?: number,
  detachPeriodType?: string,
  detachPeriodUser?: IUser,
  detachPeriodDate?: Date,
  clubs: IClub[],
  cellSpinner?: { listId: number, date: Date, role: IRole },
  clubId?: number,
  isInspectionChosen?: boolean,
  clubIdForModal?: number,
}

const initialState: State = {
  periodFrom: new Date(),
  periodTo: add(new Date(), { days: 7 }),
  loading: false,
  clubs: [],
  cellSpinner: null,
  assignOpen: false,
  assignPeriodSaving: false,
  detachPeriodSaving: false,
  detachOpen: false,
  clubId: undefined,
  isInspectionChosen: false,
  clubIdForModal: null
}

function reducer(state = { ...initialState }, action: ActionType<typeof appActions>) {
  switch (action.type) {
    case ActionTypes.SET_CLUB_ID:
      return {
        ...state,
        clubId: action.payload,
      }
    case ActionTypes.PERIOD_FROM:
      return {
        ...state,
        periodFrom: action.payload,
      }
    case ActionTypes.PERIOD_TO:
      return {
        ...state,
        periodTo: action.payload,
      }
    case ActionTypes.FETCH_LIST:
      return {
        ...state,
        loading: !action.payload.quiet,
      }
    case ActionTypes.FETCH_LIST + globalActionTypes.SUCCESS:
      const data = (action as any).payload.data
      const clubs: IClub[] = data?.map(club => ({
        id: club.id,
        name: club.name,
        parentId: club.parentId,
        parents: club.parent,
        cleaningLists: club.cleaningLists.map((item: any) => ({
          ...item,
          jobs: item.jobs.map((job: any) => ({
            ...job,
            parsedDate: strToDate(job.scheduleDate),
          })),
        })),
      }))
      return {
        ...state,
        loading: false,
        cellSpinner: null,
        clubs,
      }
    case ActionTypes.CELL_SPINNER_START:
      return {
        ...state,
        cellSpinner: action.payload,
      }
    case ActionTypes.ASSIGN_PERIOD:
      return {
        ...state,
        assignOpen: true,
        assignPeriodListId: action.payload.listId
      }
    case ActionTypes.ASSIGN_PERIOD_CLOSE:
      return {
        ...state,
        assignOpen: false
      }
    case ActionTypes.ASSIGN_PERIOD_START:
      return {
        ...state,
        assignPeriodSaving: true
      }
    case ActionTypes.ASSIGN_PERIOD_SUCCESS:
      return {
        ...state,
        assignOpen: false,
        assignPeriodSaving: false
      }
    case ActionTypes.ASSIGN_PERIOD_FAILED:
      return {
        ...state,
        assignOpen: false,
        assignPeriodSaving: false
      }
    case ActionTypes.DETACH_PERIOD:
      return {
        ...state,
        detachOpen: true,
        detachPeriodListId: action.payload.listId,
        detachPeriodUser: action.payload.user,
        detachPeriodDate: action.payload.date,
        detachPeriodType: action.payload.type,
        clubId: action.payload.clubId,
      }
    case ActionTypes.DETACH_PERIOD_CLOSE:
      return {
        ...state,
        detachOpen: false
      }
    case ActionTypes.DETACH_PERIOD_START:
      return {
        ...state,
        detachOpen: false,
        detachPeriodSaving: true
      }
    case ActionTypes.DETACH_PERIOD_SUCCESS:
      return {
        ...state,
        detachOpen: false,
        detachPeriodSaving: false
      }
    case ActionTypes.DETACH_PERIOD_FAILED:
      return {
        ...state,
        detachOpen: false,
        detachPeriodSaving: false
      }
    case ActionTypes.IS_INSPECTION_CHOSEN:
      return {
        ...state,
        isInspectionChosen: action.payload.isChosen
      }
    case ActionTypes.SET_CLUB_ID_FOR_MODAL:
      return {
        ...state,
        clubIdForModal: action.payload,
      }
    default:
      return state
  }
}

export default reducer
