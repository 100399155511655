import React, { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslate } from 'ra-core'
import { registration } from './actions'
import { useDispatch, useSelector } from 'react-redux'

interface Props {
  redirectTo?: string,
  inviteToken?: string,
}

export interface FormData {
  company: string
  email: string
  firstName: string
  lastName: string
  phone: string
  password: string
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' },
)

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const LoginForm: FunctionComponent<Props> = props => {
  const { inviteToken } = props
  const loading = useSelector((state: any) => state.registration.loading)
  const user = useSelector((state: any) => state.registration.user)

  const translate = useTranslate()
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const validate = (values: FormData) => {
    const errors: any = {}

    if (!values.firstName) {
      errors.firstName = translate('ra.validation.required')
    }
    if (!values.lastName) {
      errors.lastName = translate('ra.validation.required')
    }
    if (!values.company) {
      errors.company = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  const submit = values => {

    dispatch(registration({ ...values, inviteToken }))
    /*  login(values, redirectTo)
        .then(() => {
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
            'warning'
          );
        });*/
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={user}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            {!user && <div className={classes.input}>
              <Field
                autoFocus
                id="company"
                name="company"
                component={Input}
                label={'Company'}
                disabled={loading}
              />
            </div>}
            <div className={classes.input}>
              <Field
                autoFocus={!user}
                id="email"
                name="email"
                component={Input}
                label={'Email'}
                disabled={loading || user}
              />
            </div>
            <div className={classes.input}>
              <Field
                autoFocus={user}
                id="phone"
                name="phone"
                component={Input}
                label={'Phone'}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="firstName"
                name="firstName"
                component={Input}
                label={'First name'}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="lastName"
                name="lastName"
                component={Input}
                label={'Last name'}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
}

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
}

export default LoginForm
