import { makeStyles } from "@material-ui/core";
import { parse } from "query-string";
import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-final-form";
import {
  required,
  List,
  // Labeled,
  Filter,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  ReferenceInput,
  EditButton,
  SelectInput,
  FormDataConsumer,
  Button,
  ReferenceManyField,
  ReferenceArrayInput,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  AutocompleteArrayInput,
  Pagination,
  FileField,
  Datagrid,
} from "react-admin";

import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import GoogleMapInput from "src/components/GoogleMapInput";
import ListActions from "src/components/ListActions";
import EmptyList from "src/components/EmptyList";
import NameWithImageField from "src/components/NameWithImageField";
import RelatedList from "src/components/RelatedList";
import S3ImageField from "src/components/S3ImageField";
import S3ImageInput from "src/components/S3FileUpload/S3ImageUpload";
import TreeDatagrid from "src/components/tree/TreeDatagrid";
import ClubGroupCreateButton from "src/resources/clubs/components/ClubGroupCreateButton";

const ClubFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={"ID"} />
    <TextInput
      source="name||$contL"
      alwaysOn={true}
      variant={"outlined"}
      fullWidth={true}
      label={"Search"}
      resettable={true}
    />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));
const ShowSupplyField = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Button
        component={Link}
        to={`/club/${props.record.id}/show/supply`}
        label={"Supplies"}
      >
        <ShoppingBasketIcon />
      </Button>
    </div>
  );
};
const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);
export const ClubList = (props: any) => {
  const me = JSON.parse(localStorage.getItem("thisUser"));

  return me ? (
    <List
      {...props}
      title="Clubs"
      filters={<ClubFilter />}
      actions={<ListActions />}
      sort={{ field: "name", order: "ASC" }}
      filter={{
        userIdForClubs: me?.role && me.role !== "superAdmin" ? me.id : null,
      }}
      bulkActionButtons={false}
      pagination={<PostPagination />}
      perPage={10}
      empty={
        <EmptyList
          title={"Clubs not found"}
          description={"You can add a Club"}
          buttonText={"Create Club"}
        />
      }
    >
      <TreeDatagrid>
        <NameWithImageField source={"name"} label="Club name" />
        <TextField source="address" label="Address" />
        <TextField source="email" label="Email" />
        <TextField source="phone" label="Phone" />
        <TextField source="mobile" label="Mobile" />
        <ShowSupplyField />
      </TreeDatagrid>
    </List>
  ) : (
    <></>
  );
};

const TitleEdit = ({ record }: any) => {
  return <span>Club {record.name}</span>;
};

export const ClubEdit = (props: any) => (
  <Edit {...props} title={<TitleEdit />} undoable={false}>
    <SimpleForm redirect={"list"}>
      <FormDataConsumer>
        {(formDataProps: any) => <ClubCreateFields {...formDataProps} />}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
// const UsersField = ({ record = null, source, label = 'Users' }) => (
//   <div>
//     <Labeled label={label}>
//       <Fragment>
//         {record[source].map(item => (

//           <Link to={`/user/${item.id}/show`}>
//             <NameWithImageField record={item} />
//           </Link>
//         ))}
//       </Fragment>
//     </Labeled>
//   </div>
// );

export const ClubShow = (props) => (
  <Show {...props} title={<TitleEdit />}>
    <TabbedShowLayout>
      <Tab label="Club supplies" path="supply">
        <ReferenceManyField
          reference="club-supply"
          target="clubId"
          addLabel={false}
          sort={{ field: "id", order: "ASC" }}
          pagination={<Pagination />}
          perPage={50}
        >
          <RelatedList
            emptyTitle={"Supply list"}
            createButtonLabel={"Add supply"}
            createButtonTo={
              props.id ? `/club-supply/create?clubId=${props.id}` : null
            }
          >
            <S3ImageField label="Photo" source="supply.image" />
            <ReferenceField label="Supply" source="supplyId" reference="supply">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="minQuantity" label={"Min quantity"} />
            <TextField source="maxQuantity" label={"Max quantity"} />
            <EditButton />
          </RelatedList>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const ClubGroupInput = ({ onCreate, ...props }) => (
  <Fragment>
    <ReferenceInput {...props}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ClubGroupCreateButton onCreate={onCreate} />
  </Fragment>
);

const ClubCreateFields = (props: any) => {
  const form = useForm();

  const onCreate = (data: any) => {
    form.change("parentId", data.data.id);
  };

  if (props.record?.isGroup) {
    return (
      <Fragment>
        <TextInput
          source="name"
          label={"Group Name"}
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
          inputProps={{ autocomplete: "off" }}
        />
        <ClubGroupInput
          label="Parent Group"
          source="parentId"
          reference="club"
          onCreate={onCreate}
          filter={{ isGroup: true, excludeId: props.record.id }}
          variant={"outlined"}
          sort={{ field: "firstName", order: "ASC", forSelector: true }}
        >
          <SelectInput optionText="name" />
        </ClubGroupInput>
        <ReferenceArrayInput
          label={"Users"}
          source="users"
          reference="user"
          format={(users: any) =>
            (users || []).map((user: any) => (user && user.id ? user.id : user))
          }
          sort={{ field: "firstName", order: "ASC", forSelector: true }}
          variant={"outlined"}
          fullWidth={true}
          validate={required()}
        >
          <AutocompleteArrayInput
            source="id"
            optionText="name"
            variant={"outlined"}
          />
        </ReferenceArrayInput>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <ClubGroupInput
        label="Group name"
        source="parentId"
        reference="club"
        onCreate={onCreate}
        filter={{ isGroup: true, excludeId: props.record.id }}
        variant={"outlined"}
        sort={{ field: "firstName", order: "ASC", forSelector: true }}
      >
        <SelectInput optionText="name" />
      </ClubGroupInput>
      <TextInput
        source="name"
        label={"Club Name"}
        variant={"outlined"}
        fullWidth={true}
        validate={required()}
        inputProps={{ autocomplete: "off" }}
      />
      <ReferenceArrayInput
        label={"Users"}
        source="users"
        reference="user"
        format={(users: any) =>
          (users || []).map((user: any) => (user && user.id ? user.id : user))
        }
        sort={{ field: "firstName", order: "ASC", forSelector: true }}
        variant={"outlined"}
        fullWidth={true}
        validate={required()}
      >
        <AutocompleteArrayInput
          source="id"
          optionText="name"
          variant={"outlined"}
        />
      </ReferenceArrayInput>

      <TextInput
        source="address"
        label={"Address 1"}
        variant={"outlined"}
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />
      <TextInput
        source="secondAddress"
        label={"Address 2"}
        variant={"outlined"}
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />
      <TextInput
        source="locationTolerance"
        label={"Out-of-bounds tolerance"}
        variant={"outlined"}
        type="number"
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <GoogleMapInput
            source={"coordinates"}
            address={formData.address}
            radius={parseFloat(formData.locationTolerance)}
          />
        )}
      </FormDataConsumer>

      <TextInput
        source="city"
        label={"City"}
        variant={"outlined"}
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />
      <TextInput
        source="state"
        label={"State"}
        variant={"outlined"}
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />
      <TextInput
        source="zip"
        label={"Zip / Post code"}
        variant={"outlined"}
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />

      <TextInput
        source="email"
        label={"Email"}
        variant={"outlined"}
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />
      <TextInput
        source="phone"
        label={"Phone"}
        variant={"outlined"}
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />
      <TextInput
        source="mobile"
        label={"Mobile"}
        variant={"outlined"}
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />
      <TextInput
        multiline
        source="note"
        label={"Note"}
        variant={"outlined"}
        fullWidth={true}
        inputProps={{ autocomplete: "off" }}
      />

      <FormDataConsumer fullWidth={true}>
        {({ formData }) =>
          !formData.isGroup && (
            <S3ImageInput
              source="image"
              fileCoverImg="someImgURL"
              label={"Image"}
              uploadOptions={{
                multiple: false,
              }}
            >
              <ImageField source="src" />
            </S3ImageInput>
          )
        }
      </FormDataConsumer>

      <ArrayInput source="documents">
        <SimpleFormIterator>
          <S3ImageInput
            source="path"
            fileCoverImg="someImgURL"
            label={"File"}
            uploadOptions={{
              multiple: false,
            }}
          >
            <FileField source="src" />
          </S3ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </Fragment>
  );
};

export const ClubCreate = (props: any) => {
  const { parentId: parentIdString } = parse(props.location.search);

  const parentId = parentIdString ? parseInt(parentIdString, 10) : "";

  return (
    <Create title="Create Club" {...props}>
      <SimpleForm
        redirect={"list"}
        initialValues={{ locationTolerance: 100 }}
        defaultValue={{ parentId: null }}
      >
        <FormDataConsumer>
          {(formDataProps) => (
            <ClubCreateFields {...formDataProps} isAccaunt={props.isAccaunt} />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
