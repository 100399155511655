import { makeStyles } from "@material-ui/core/styles";
import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle, InputProps } from 'ra-core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Labeled } from 'ra-ui-materialui'
import sanitizeRestProps from './sanitizeRestProps';
import MuiTextField from '@material-ui/core/TextField';
function padDigits(number: number, digits: number) {
  return ('' + number).padStart(digits, "0");
}
export type TextInputProps = InputProps<TextFieldProps> &
  Omit<TextFieldProps, 'label' | 'helperText'>;


const useStyles = makeStyles(
  theme => ({

    inputsWrapper: {
      display: 'flex'
    },
    separator: {

      lineHeight: '56px',
      padding: '0px 3px'
    },
  }),
  { name: 'RaFileInput' },
)

/**
 * An Input component for a string
 *
 * @example
 * <TimeDurationInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TimeDurationInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
const TimeDurationInput: FunctionComponent<TextInputProps> = (props) => {
  const {
    label,
    format,
    helperText,
    onBlur,
    onFocus,
    onChange,
    variant,
    margin,
    handleFocus,
    handleBlur,
    options,
    disabled,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const {
    input: { onChange: onChangeInput, value }
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });
  const classes = useStyles(props)
  const [hour, setHour] = useState('0')

  const [min, setMin] = useState('0')
  const [sec, setSec] = useState('0')
  useEffect(() => {
    setHour('' + padDigits(Math.floor(value / 60 / 60), 2));
    setMin('' + padDigits((Math.floor(value / 60) - Math.floor(value / 60 / 60) * 60), 2));
    setSec('' + padDigits(value - (Math.floor(value / 60) * 60), 2));

  }, [value])

  // const changeValue = useCallback(() => {
  //   onChangeInput(parseInt(min, 10) * 60 + parseInt(sec, 10))
  // }, [min, sec])
  const onChangeMin = (e: any) => {
    console.log("onChangeMin", e.target.value)
    const formatted = formatTime(e.target.value);

    setMin(formatted);
    onChangeInput(parseInt(formatted, 10) * 60 + parseInt(sec, 10) + parseInt(hour, 10) * 60 * 60)
    // changeValue();
  }
  const onChangeHour = (e: any) => {
    console.log("onChangeHour", e.target.value)
    const formatted = formatTime(e.target.value);

    setMin(formatted);
    onChangeInput(parseInt(formatted, 10) * 60 * 60 + parseInt(min, 10) * 60 + parseInt(sec, 10))
    // changeValue();
  }
  const onChangeSec = (e: any) => {
    const formatted = formatTime(e.target.value);
    setSec(formatTime(formatted));
    if (parseInt(formatted) > 59) {
      return;
    }
    onChangeInput(parseInt(hour, 10) * 60 * 60 + parseInt(min, 10) * 60 + parseInt(e.target.value, 10))
    //changeValue();
  }
  const formatTime = (val: string) => {
    if (!val) {
      return '00'
    }
    if (val.length > 2) {
      return padDigits(parseInt(val.substr(val.length - 2, val.length - 1), 10), 2);
    }
    return padDigits(parseInt(val, 10), 2);
  }

  return <>
    <Labeled
      label={<FieldTitle
        label={label}
      />}

      {...sanitizeRestProps(rest)}
    >
      <div className={classes.inputsWrapper}>

        <MuiTextField
          onChange={onChangeHour}
          value={hour}
          disabled={disabled}
          variant={variant}
          margin={margin}
          {...rest}
          onFocus={handleFocus}
          onBlur={handleBlur}
          label={<FieldTitle
            label={'Hours'}
          />}
        />
        <div className={classes.separator}>:</div>
        <MuiTextField
          onChange={onChangeMin}
          value={min}
          disabled={disabled}
          variant={variant}
          margin={margin}
          {...rest}
          label={<FieldTitle
            label={'Mins'}
          />}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div className={classes.separator}>:</div>
        <MuiTextField
          onChange={onChangeSec}
          value={sec}
          disabled={disabled}
          variant={variant}
          margin={margin}
          {...rest}
          label={<FieldTitle
            label={'Secs'}
          />}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </Labeled>
  </>
};

TimeDurationInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
};

TimeDurationInput.defaultProps = {
  options: {},
};

export default TimeDurationInput;
