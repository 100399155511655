import { format } from "date-fns";
import React from 'react'
import PropTypes from 'prop-types'

const TimeField = ({
  source, record = {},
}: any) => {

  const time = new Date(record[source]);
  // console.log("TZTIME", time)
  return (
    <>
      {!!record[source] ? format(time, 'hh:mm a') : ''}
    </>)
}
TimeField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default TimeField
