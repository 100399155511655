import registration from 'src/resources/registration/reducer'
import dashboard from 'src/resources/DashboardPage/reducer'
import assignCleaners from 'src/resources/AssignCleanersPage/reducer'
import clubs from 'src/components/clubs/reducer'
import users from 'src/components/users/reducer'
import chat from 'src/resources/Messages/reducer'

const reducers = {
  registration,
  dashboard,
  assignCleaners,
  clubs,
  users,
  chat
}

export default reducers
