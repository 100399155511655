import Cookies from 'js-cookie'
import { HttpError } from 'react-admin'
import ApiProvider from './ApiProvider'
import { objectToFormData } from 'object-to-formdata'

export interface Options extends RequestInit {
  user?: {
    authenticated?: boolean;
    token?: string;
  }
}

export const createHeadersFromOptions = (options: Options): Headers => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    })) as Headers
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json')
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token)
  }

  return requestHeaders
}

const fetchJson = (url, options: Options = {}) => {

  const requestHeaders = createHeadersFromOptions(options)

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      })),
    )
    .then(({ status, statusText, headers, body }) => {
      let json
      try {
        json = JSON.parse(body)
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        // console.log("Json res", json)
        return Promise.reject(
          new HttpError(
            (json && json.errors && Array.isArray(json.errors) && json.errors.length > 0) ? json.errors[0]
              : (json && json.errors && !Array.isArray(json.errors) ? json.errors : ((json && json.message) || statusText)),

            status,
            json,
          ),
        )
      }
      return Promise.resolve({ status, headers, body, json })
    }).catch(e => { console.log(e) })
}

const httpClient = (url: string, options: any = {}) => {

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const token = Cookies.get('auth-token')
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchJson(url, options)
}

// by default
export const dataProvider = ApiProvider(`${process.env.REACT_APP_API_URL || ''}/admin/api`, httpClient)

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */

const addUploadFeature = (requestHandler: any) => (type: string, resource: any, params: any) => {

  if (type === 'UPDATE' || type === 'CREATE') {
    // notice that following condition can be true only when `<ImageInput source="pictures" />`
    // component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    // const newPictures = []

    const newData: any = {}
    let hasFile = false
    for (const key of Object.keys(params.data)) {
      if (params.data[key] && params.data[key].rawFile instanceof File) {
        newData[key] = params.data[key].rawFile
        hasFile = true
      } else {
        newData[key] = params.data[key]
      }
    }

    if (newData.photos) {
      params.data.photos = []
      newData.photos.forEach((photo: { rawFile: { path: any; }, path: string }) => {
        if (photo.rawFile) {
          params.data.photos.push(`clubtasker/files/${photo.rawFile.path}`)
        } else {
          params.data.photos.push(`${photo.path}`)
        }
      })
    }
    if (hasFile && newData.image) {
      console.log('HasFile', hasFile, newData)
      params.data.image = `clubtasker/files/${newData.image.path}`
    }
    if (hasFile && newData.avatar) {
      console.log('HasFile', hasFile, newData)
      params.data.avatar = `clubtasker/files/${newData.avatar.path}`
    }

  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params)
}

export const uploadCapableDataProvider = addUploadFeature(dataProvider)

export default addUploadFeature(dataProvider)
