import React from 'react'
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  ImageField,
  required,
} from 'react-admin'
import S3ImageInput from 'src/components/S3FileUpload/S3ImageUpload'

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Cleaning task Inspection instruction {record.name}
    </span>
  )
}
export const InspectionStepEdit = props => {
  const { location } = props
  const redirect = location.state && location.state.previous ? location.state.previous : false

  return (
    <Edit {...props} title={<TitleEdit/>} undoable={false}>
      <SimpleForm
        redirect={redirect}
      >
        <ReferenceInput
          source="cleaningTaskId"
          reference="cleaning-list-task"
          disabled={true}
          validate={required()}
          variant={'outlined'}
          fullWidth={true}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <TextInput source="name" label={'Instruction name'}   variant={'outlined'}
                   fullWidth={true}/>
        <TextInput multiline source="description" label={'Note'} variant={'outlined'} fullWidth={true}/>
        <S3ImageInput
          source="image"
          fileCoverImg="someImgURL" // cover img for non-img files
           label={'Image'}
          uploadOptions={{
            multiple: false, // for selecting multiple files from file system
          }}
        >
          <ImageField source="src" title="title" />
        </S3ImageInput>
      </SimpleForm>
    </Edit>
  )
}
