import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, fetchList, userSelected } from './actions'
import { AppState } from 'src/types'
import { Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Avatar from '../Avatar'

interface Props { }

function Users(props: Props) {
  const dispatch = useDispatch()
  const users = useSelector((state: AppState) => state.users)
  const [list, setList] = useState([])

  useEffect(() => {
    dispatch(fetchList())
    // eslint-disable-next-line
  }, [props])

  useEffect(() => {
    let list = users.list
    if (users.selectUserFilter) {
      if (users.selectUserFilter?.clubId) {
        list = list.filter((userItem: any) => userItem.clubs.some((clubItem: any) => clubItem.id === users.selectUserFilter.clubId))
      }
    }
    const sortedList = list.sort((a: any, b: any) => {
      if (a.name < b.name) { return -1 }
      if (a.name > b.name) { return 1 }
      return 0
    })

    setList(sortedList)
  }, [users, props])

  return (
    <Dialog
      open={users.selectUserOpen}
      onClose={() => {
        dispatch(userSelected(null))
        dispatch(closeModal())
      }}
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle>Select user</DialogTitle>
      <List>
        {list.map(user => (
          <ListItem
            key={user.id}
            button
            onClick={() => {
              dispatch(userSelected(user))
              dispatch(closeModal())
            }}
          >
            <ListItemAvatar>
              <Avatar
                image={user.avatar}
                name={user.name}
              />
            </ListItemAvatar>
            <ListItemText primary={`${user.name}`} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default Users
