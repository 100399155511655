import React from 'react'
import PropTypes from 'prop-types'
import { padDigits } from "src/utils/dateUtil";

const DurationField = ({
  sourceStartTime,
  sourceEndTime,
  record = {},
}: any) => {
  const minus = parseInt(record[sourceStartTime], 10) < 0;
  const value = !minus ? parseInt(record[sourceStartTime], 10) : parseInt(record[sourceStartTime], 10) * -1;
  const hours = padDigits(Math.floor(value / 60 / 60), 2);
  const min = value < 60 ? '01' : padDigits((Math.floor(value / 60) - Math.floor(value / 60 / 60) * 60), 2);

  if (!!!record[sourceEndTime]) {
    return <>{ (value / 3600).toFixed(2) }</>
  } else {
    return (
      <>
        {!!value ? `${minus ? '-' : ''}${hours}:${min}` : null}
      </>)
  }
}
DurationField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  sourceStartTime: PropTypes.string.isRequired,
  sourceEndTime: PropTypes.string,
}

export default DurationField
