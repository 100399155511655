import { ActionType } from 'typesafe-actions'
import ActionTypes from './const'
import * as appActions from './actions'
import globalActionTypes from 'src/constants'
interface IFilter {
  clubId?: number,
  userId?: number,
  startTime?: Date,
  endTime?: Date
}
export interface State {
  filter: IFilter,
  cleaningListCompletionByLocation: any[],
  cleaningListCompletionByUser: any[],
  inspectionScore: any[],
  inspectionCompletionByLocation: any[],
  inspectionCompletionByUser: any[],
  totalIssuesByLocation: any[],
  totalIssuesByUser: any[],
  timeToCloseByLocation: any[],
  timeToCloseByUser: any[],
}

const initialState: State = {
  filter: {},
  cleaningListCompletionByLocation: [],
  cleaningListCompletionByUser: [],
  inspectionScore: [],
  inspectionCompletionByLocation: [],
  inspectionCompletionByUser: [],
  totalIssuesByLocation: [],
  totalIssuesByUser: [],
  timeToCloseByLocation: [],
  timeToCloseByUser: [],
}

function reducer(state = { ...initialState }, action: ActionType<typeof appActions>) {
  switch (action.type) {
    case ActionTypes.SET_FILTER:
      return {
        ...state,
        filter: action.payload
      }
    case ActionTypes.FETCH_LIST_REQUEST + globalActionTypes.SUCCESS:
      return {
        ...state,
        ...(action.payload as any),
      }
    default:
      return state
  }
}

export default reducer
