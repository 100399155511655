import { parseISO } from "date-fns";
import React, { useEffect } from 'react'
import {
  Table,
  TableBody,
  TableRow,
} from '@material-ui/core'
import { dateToStr } from 'src/utils/dateUtil'
import CleanersCell from 'src/resources/AssignCleanersPage/components/CleanersCell'
import { IScheduleItem } from 'src/resources/AssignCleanersPage/types'
import { useDispatch, useSelector } from 'react-redux'
import { assign, detachPeriod } from 'src/resources/AssignCleanersPage/actions'
import { AppState } from 'src/types'

interface Props {
  jobs: IScheduleItem[]
  date: Date
  listId: number
  onlyInspector: boolean
  clubId: number
}

function CleanersInnerTable(props: Props) {
  //(schedule.startDate >= :startDate AND schedule.startDate <= :endDate) OR schedule.endDate is  NULL OR (schedule.endDate IS NOT NULL AND schedule.startDate <= :startDate AND schedule.endDate >= :startDate)
  const dispatch = useDispatch()

  const date = new Date(props.date)
  const findByDate = (startDate: any, endDate: any) => {
    // console.log("find", startDate, endDate, date)
    return ((!endDate && startDate.getTime() <= date.getTime()) || (endDate && startDate.getTime() <= date.getTime() && endDate.getTime() >= date.getTime()))
  }

  const assignedCleaner = (props.jobs || []).find(({ type, startDate, endDate }) => type === 'cleaner' && findByDate(parseISO(startDate), endDate ? parseISO(endDate) : null))
  const assignedInspector = (props.jobs || []).find(({ type, startDate, endDate }) => type === 'inspector' && findByDate(parseISO(startDate), endDate ? parseISO(endDate) : null))
  const cellSpinner = useSelector((state: AppState) => state.assignCleaners.cellSpinner)

  // console.log("assignedInspector", assignedInspector)
  // console.log("Props", props.clubId)

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <CleanersCell
            cleaner={assignedCleaner?.user}
            date={props.date}
            spinner={(
              cellSpinner?.listId === props.listId &&
              dateToStr(cellSpinner?.date) === dateToStr(props.date) &&
              cellSpinner?.role === 'cleaner'
            )}
            onAdd={() => {
              dispatch(assign('cleaner', props.date, props.listId, props.clubId))
            }}
            onRemove={() => {
              dispatch(detachPeriod(props.listId, props.date, 'cleaner', assignedCleaner?.user, props.clubId))
            }}
            isActive={!props.onlyInspector}
          />
          <CleanersCell
            inspector={assignedInspector?.user}
            date={props.date}
            spinner={(
              cellSpinner?.listId === props.listId &&
              dateToStr(cellSpinner?.date) === dateToStr(props.date) &&
              cellSpinner?.role === 'manager'
            )}
            onAdd={() => {
              dispatch(assign('manager', props.date, props.listId, props.clubId))
            }}
            onRemove={() => {
              dispatch(detachPeriod(props.listId, props.date, 'inspector', assignedInspector?.user, props.clubId))
            }}
            isActive={!props.onlyInspector}
          />
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default CleanersInnerTable
