import React, { useEffect, useRef, useState } from "react";
import Picker from "emoji-picker-react";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie'

import SearchIcon from "../../assets/images/searchIcon.png";
import SmileFace from "../../assets/images/smileFace.png";
import CrossInCircle from "../../assets/images/crossInCircle.png";
import AttachFile from "../../assets/images/attachFile.png";

import {
  createChat,
  createMessage,
  // eslint-disable-next-line
  deleteChat,
  loadAllClubUsers,
  loadChats,
  loadCurrentUser,
  loadMessages,
  // updateMessageReadState
} from "./actions";

import { AppState } from "src/types";

import "./styles.css";

const defaultSrcLink = 'https://admin.dev.clubtasker.com/api/s3/uploads/';

export const Messages = (props: any) => {
  const dispatch = useDispatch()
  const chats = useSelector((state: AppState) => state.chat.chats)
  const currentUser = useSelector((state: AppState) => state.chat.currentUser)
  const users = useSelector((state: AppState) => state.chat.users)
  const messages = useSelector((state: AppState) => state.chat.messages)
  const currentChatId = useSelector((state: AppState) => state.chat.activeChat)

  const [msgs, setMsgs] = useState(null);

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [newMsg, setNewMsg] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [allChats, setAllChats] = useState([{ type: '', data: {} }]);
  const [filteredChats, setFilteredChats] = useState<any>();
  const [filteredMessages, setFilteredMessages] = useState<any>();
  const [messagesTimer, setMessagesTimer] = useState<any>();
  const [chatsTimer, setChatsTimer] = useState<any>();
  const [isUpdatingChat, setIsUpdatingChat] = useState(false);
  const [uploadingFileType, setUploadingFileType] = useState<any>();
  const [uploadingFileName, setUploadingFileName] = useState<any>();

  const lastMsg = useRef(null);
  const inputFile = useRef(null);
  const newMessageInput = useRef(null);

  useEffect(() => {
    dispatch(loadCurrentUser({}))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!messages) {
      // const unreadMsgs = messages.filter((item: any) => item.userId !== currentUser.id && item.isRead === false);

      // if (!!unreadMsgs.length) {
      //   const ids = unreadMsgs.map((item: any) => item.id);

      //   dispatch(updateMessageReadState([currentChatId]));
      // }

      const formatedMessages = messages?.map((item: any) => {
        return {
          msg: item?.message,
          time: moment(item?.createdAt).format('HH:mm'),
          day: moment(item?.createdAt).format('DD MMM, YYYY'),
          from:
            item?.userId === currentUser.id
              ? 'me'
              : 'oponent',
          ...item
        }
      })

      setMsgs(formatedMessages);
    }
    // eslint-disable-next-line
  }, [messages])

  useEffect(() => {
    if (!!currentUser?.clubs) {
      updateChats();

      dispatch(loadAllClubUsers({ role: currentUser?.role, clubId: [...currentUser.clubs.map((clubItem: any) => clubItem.id)].join(',') }))
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    let newChatsList: any = [];
    let numberOfSavedChat = 0

    if (!!chats && chats?.length) {
      for (let i = 0; i < chats.length; i++) {
        newChatsList.push({ type: 'chats', data: chats[i] })
        numberOfSavedChat++;
      }
    }

    if (!!users && users.length) {
      let chatsUsers = [];

      for (let i = 0; i < newChatsList.length; i++) {
        if (newChatsList[i].type === 'chats' && !newChatsList[i].isGroup) {
          if (newChatsList[i].data.participant?.id === currentUser?.id && newChatsList[i].data.user) {
            chatsUsers.push(newChatsList[i].data.user)
          } else if (newChatsList[i].data.user?.id === currentUser?.id && newChatsList[i].data.participant) {
            chatsUsers.push(newChatsList[i].data.participant)
          }
        }
      }

      for (let i = 0; i < users.length; i++) {
        if (numberOfSavedChat === 0 && currentUser.id !== users[i].id) {
          newChatsList.push({ type: 'users', data: users[i] })
        } else if (currentUser.id !== users[i].id && !chatsUsers.some((chatListItem: any) => chatListItem.id === users[i].id)) {
          newChatsList.push({ type: 'users', data: users[i] })
        }
      }
    }

    setAllChats(newChatsList)
    // eslint-disable-next-line
  }, [users, chats])

  useEffect(() => {
    if (!!lastMsg && !!msgs && !isUpdatingChat) {
      lastMsg?.current?.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [lastMsg, msgs]);


  const updateChats = () => {
    clearTimeout(chatsTimer);

    let clubsId = currentUser.clubs?.map((item: any) => item.id);

    dispatch(loadChats({ page: 1, limit: 1000, clubsId: clubsId, myRole: currentUser?.role, myId: currentUser?.id }));

    const timer = setTimeout(() => { updateChats() }, 30000);

    setChatsTimer(timer);
  }

  const updateMessages = (chatId: number, fromThisChat: boolean, isStart?: boolean) => {
    setIsUpdatingChat(!isStart);

    clearTimeout(messagesTimer);

    dispatch(loadMessages({ chatId, fromThisChat }));

    const timer = setTimeout(() => { updateMessages(chatId, fromThisChat, false) }, 10000);

    setMessagesTimer(timer);
  }

  const loadFile = () => {
    if (!!msgs) {
      inputFile.current.click();
    }
  };

  const setAvatar = (type?: string, data?: any) => {
    if (type === 'chats') {
      if (!checkIsUserFromThisChat(data?.userId, data?.participantId)) {
        return <div className="initials">{data?.participant?.firstName?.[0]}{data?.user?.firstName?.[0]}</div>
      }

      if (currentUser?.id === data?.userId) {
        if (!!data?.participant?.avatar) {
          return <img alt='Avatar' src={`${defaultSrcLink}${data?.participant?.avatar}`} className="userImage" />
        } else {
          return <div className="initials">{data?.participant?.firstName?.[0]}{data?.participant?.lastName?.[0]}</div>
        }
      } else if (currentUser?.id === data?.participantId) {
        if (!!data?.user?.avatar) {
          return <img alt='Avatar' src={`${defaultSrcLink}${data?.user?.avatar}`} className="userImage" />
        } else {
          return <div className="initials">{data?.user?.firstName?.[0]}{data?.participant?.lastName?.[0]}</div>
        }
      }
    } else {
      if (!!data?.avatar) {
        return <img alt='Avatar' src={`${defaultSrcLink}${data?.avatar}`} className="userImage" />
      } else {
        return <div className="initials">{data?.firstName?.[0]}{data?.lastName?.[0]}</div>
      }
    }
  }

  const convertTime = (fullTime: string) => {
    const date = fullTime?.split('T')[0];

    if (date === moment().format('YYYY-MM-DD')) {
      return moment(fullTime).format('HH:mm');
    } else {
      return date;
    }
  }

  const setUserName = (data: any) => {
    if (data?.isGroup) {
      return data?.name
    }

    if (currentUser?.id === data?.userId) {
      return data?.participant?.name;
    } else if (currentUser?.id === data?.participantId) {
      return data?.user?.name;
    } else {
      return data?.name
    }
  }

  const saveFile = async (file: any) => {
    const token = Cookies.get('auth-token')

    const getRequest = new Request(`${process.env.REACT_APP_API_URL}/api/s3/sign?objectName=${file?.name}&contentType=${file.type}&path=clubtasker/files`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
    })

    const getResponseData = await fetch(getRequest).then((getResponse: any) => {
      return getResponse.json();
    }).catch((e: any) => {
      console.log('Error: ', e);
    });

    const putRequest = new Request(getResponseData.signedUrl, {
      method: 'PUT',
      headers: new Headers({ 'Content-Type': file.type }),
      body: file,
    })

    const putResponseData = await fetch(putRequest)
      .then((response: any) => {
        return getResponseData.fileKey
      })
      .catch((e: any) => {
        console.log('Error: ', e)
      })

    const type = file.type.includes('jpg')
      || file.type.includes('jpeg')
      || file.type.includes('png') ? 'img' : 'doc';

    inputFile.current.value = ''

    setUploadingFileName(putResponseData);
    setUploadingFileType(type);
  }

  const checkIsUserFromThisChat = (userId: number, participntId: number) => {
    if (currentUser?.id !== userId && currentUser?.id !== participntId) {
      return false
    } else {
      return true
    }
  }

  const setChats = (chats: any) => {
    return chats?.map((chat: any, chatIndex: number) => {
      return (
        <div
          key={`chat-${chatIndex}`}
          className="userItem unselectable"
          onClick={() => {
            setUploadingFileName(null);
            setUploadingFileType(null);
            setNewMsg("");

            if (chat.type === 'chats') {
              if (checkIsUserFromThisChat(chat.data.userId, chat.data?.participantId)) {
                updateMessages(chat.data.id, true, true)
              } else {
                updateMessages(chat.data.id, false, true)
              }
              // dispatch(deleteChat(chat.data.id))
            } else {
              clearTimeout(messagesTimer);

              const myClubsId = currentUser?.clubs?.map((userItem: any) => userItem.id)
              const userClubsId = chat.data?.clubs?.map((userItem: any) => userItem.id)

              let sameClubs = []

              if (myClubsId.length > userClubsId.length) {
                sameClubs = myClubsId?.filter((clubItem: any) => userClubsId.includes(clubItem))
              } else {
                sameClubs = userClubsId?.filter((clubItem: any) => myClubsId.includes(clubItem))
              }
              dispatch(createChat({
                users: [currentUser.id, chat.data.id],
                name: `Chat between ${currentUser?.name} and ${chat.data?.name}`,
                isGroup: false,
                clubId: sameClubs?.[0]
              }, (response: any) => {
                clearTimeout(chatsTimer);
                updateChats();
                updateMessages(response.id, true, true)
              }))
            }
          }}
        >
          <div className="userItemLeftSide">
            <div className="userImgWrapper">
              {setAvatar(chat?.type, chat?.data)}
            </div>
            <div style={{ width: '100%' }}>
              <div className="userItemNameWrapper" >
                <div className="userItemName">
                  {chat.type === 'users'
                    ? chat.data?.name
                    : setUserName(chat.data)}
                </div>
                {chat.type === 'chats' && <div className="userItemLastMsgTime">
                  {convertTime(chat?.data?.lastMessageAt)}
                </div>}
              </div>
              {chat.type === 'chats' &&
                <div className="userItemLastMsgWrapper">
                  <div className="userItemLastMsg">
                    {chat?.data?.lastMessage === '' && !!chat?.data?.lastMessageAt ? "File" : chat?.data?.lastMessage}
                  </div>
                  {!!chat.data.totalUnread && chat.data.totalUnread !== '0' && <div className="unreadMessage"></div>}
                </div>
              }
            </div>
          </div>

        </div>
      );
    })
  }

  const setMessages = (messages: any) => {
    return messages.map((msgItem: any, index: number) => (
      <div key={`msgItem-${index}`}>
        {msgItem?.day !== messages?.[index - 1]?.day &&
          <div className="dayWrapper">
            {msgItem?.day}
          </div>
        }
        <div className={msgItem.from !== 'admin' ? `msgsWrapper` : 'adminMsgWrapper'}>
          {msgItem.from === 'admin'
            ?
            <div className="singleAdminMsgWrapper" ref={index === msgs?.length - 1 ? lastMsg : null}>
              <div className="adminMsgTitle">Message from {msgItem?.user?.roleName}</div>
              {!!msgItem?.files && !!msgItem.files?.length && <>{setMsgFile(msgItem.files[0])}</>}
              <div className="adminMsg">{msgItem.msg}</div>
              <div className="adminMsgTime">{msgItem.time}</div>
            </div>
            : <>
              {msgItem.from === "me" && <div />}
              <div
                className={
                  msgItem.from === "me"
                    ? "singleMsgWrapperFromMe"
                    : "singleMsgWrapper"
                }
                ref={index === msgs?.length - 1 ? lastMsg : null}
              >
                {!!msgItem?.user?.name && <div className="msgSender">{msgItem.user?.name}:</div>}
                {!!msgItem?.files && !!msgItem.files?.length && <>{setMsgFile(msgItem.files[0])}</>}
                {!!msgItem?.msg && <div className="msgText">{msgItem.msg}</div>}
                <div className="msgTime">{msgItem?.time}</div>
              </div>
              {msgItem.from === "oponent" && <div />}
            </>}
        </div>
      </div>
    ))
  }

  const setMsgFile = (file: any) => {
    if (file?.path) {
      if (file.path.includes('jpg')
        || file.path.includes('jpeg')
        || file.path.includes('png')) {
        return <div>
          <img
            alt='Attached'
            src={`${defaultSrcLink}${file.path}`}
            className='imageSingleMsg'
          />
        </div>
      } else {
        return <div className='uploadDocWrapper'>
          <img alt='File' src={require('../../assets/images/file.png')} className="fileImageSingleMsg" />
          <a
            href={`${defaultSrcLink}${file.path}`}
            className='uploadDocNameSingleMsg'
            download={true}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            {file.path?.split('/')[2]}
          </a>
        </div>
      }
    } else {
      return <></>
    }
  }

  return (
    <div className="massagesWrapper">
      <div className="usersListWrapper">
        <div className="searchWrapper">
          <img alt='search-icon' src={SearchIcon} className="searchIcon" />
          <input className="searchUserInput" placeholder="Search chat" onChange={(event: any) => {
            const value = event.target.value;

            if (value === '') {
              setFilteredChats(null)
            } else {
              setFilteredChats(allChats.filter((item: any) => {
                let userName = '';
                if (item.type === 'chats') {
                  userName = setUserName(item.data);
                } else {
                  userName = item.data?.name
                }

                if (userName?.toLowerCase()?.includes(value.toLowerCase())) {
                  return true;
                }
                else {
                  return false
                }
              }))
            }
          }} />
        </div>
        <div className="usersList">
          {!!filteredChats ? setChats(filteredChats) : !!allChats ? setChats(allChats) : <></>}
        </div>
      </div>
      <div className="chatWrapper">
        <div className="chatHeaderWrapper">
          {!!currentChatId ? <div className="chatReceiverWrapper">
            <div className="receiverImgWrapper">
              {setAvatar('chats', chats[chats.findIndex((object: any) => {
                return object.id === currentChatId;
              })])}
            </div>
            <div className="receiverName">{setUserName(chats[chats.findIndex((object: any) => {
              return object.id === currentChatId;
            })])}</div>
          </div> : <div></div>}
          <div className="chatHeaderButtons">
            {!showSearch && (
              <div
                className="chatSearchIconWrapper"
                onClick={() => {
                  if (!!currentChatId)
                    setShowSearch(true)

                  setFilteredMessages(null)
                }}
              >
                <img alt='search-icon' src={SearchIcon} className="chatSearchIcon" />
              </div>
            )}
            {showSearch && (
              <div className="searchMessageInputWrapper">
                <input className="searchMessageInput" onChange={(event: any) => {
                  const value = event.target.value;

                  if (value === '') {
                    setFilteredMessages(null)
                  } else {
                    setFilteredMessages(msgs.filter((item: any) => {
                      if (item.msg?.toLowerCase()?.includes(value.toLowerCase())) {
                        return true;
                      }
                      else {
                        return false
                      }
                    }))
                  }
                }} />
                <div
                  className="searchCloseIconWrapper"
                  onClick={() => setShowSearch(false)}
                >
                  <img alt='Search' src={CrossInCircle} className="searchCloseIcon" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="chatBodyWrapper">
          <div className="chatBody">
            {!!msgs && setMessages(!!filteredMessages ? filteredMessages : msgs)}
          </div>
        </div>

        <div className="chatBottomWrapper">
          {!!uploadingFileType && !!uploadingFileName &&
            <div className={uploadingFileType === 'img'
              ? "uploadImageFileWrapper"
              : 'uploadDocFileWrapper'}>
              {uploadingFileType === 'img'
                ? <>
                  <img alt='Uploaded' src={`${defaultSrcLink}${uploadingFileName}`} className='uploadImage' />
                  <img alt='Close' src={require('../../assets/images/crossInCircle.png')} className="closeImage" onClick={() => {
                    setUploadingFileName(null);
                    setUploadingFileType(null);
                  }} />
                </>
                : <div className='uploadDocWrapper'>
                  <img alt='File' src={require('../../assets/images/file.png')} className="fileImage" />
                  <img alt='Close' src={require('../../assets/images/crossInCircle.png')} className="closeImageDoc" onClick={() => {
                    setUploadingFileName(null);
                    setUploadingFileType(null);
                  }} />
                  <div className='uploadDocName'>{uploadingFileName?.split('/')[2]}</div>
                </div>
              }
            </div>
          }
          {isEmojiPickerOpen && (
            <Picker
              onEmojiClick={(event, emojiObject) => {
                setNewMsg(newMsg + `${emojiObject.emoji} `);
                setIsEmojiPickerOpen(!isEmojiPickerOpen);
                newMessageInput.current.focus()
              }}
              pickerStyle={{
                position: "absolute",
                marginLeft: "10px",
                marginBottom: "400px",
              }}
              groupVisibility={{
                flags: false,
                objects: false,
                animals_nature: false,
                food_drink: false
              }}
            />
          )}
          <div
            className="smileIconWrapper"
            onClick={() => {
              if (!!currentChatId)
                setIsEmojiPickerOpen(!isEmojiPickerOpen);
            }}
          >
            <img alt='Emoji' src={SmileFace} className="smileIcon" />
          </div>
          <div className="attachIconWrapper" onClick={loadFile}>
            <img alt='Attach' src={AttachFile} className="attachIcon" />
          </div>
          <input
            value={newMsg}
            onChange={(value) => {
              setNewMsg(value.currentTarget.value);
            }}
            className="newMsgInput"
            placeholder="Type a message"
            disabled={!!!msgs}
            onKeyPress={(event) => {
              if (event.key === "Enter" && newMsg.trim()) {
                dispatch(createMessage({
                  chatId: currentChatId,
                  newMessage: newMsg,
                  files: !!uploadingFileName ? [uploadingFileName] : null
                }))
                setNewMsg("");

                let clubsId = [];

                if (currentUser.role === 'owner') {
                  clubsId = currentUser.clubs?.map((item: any) => item.id);
                }

                dispatch(loadChats({ page: 1, limit: 1000, clubsId: clubsId, myRole: currentUser?.role, myId: currentUser?.id }));

                setTimeout(() => {
                  dispatch(loadChats({ page: 1, limit: 1000, clubsId: clubsId, myRole: currentUser?.role, myId: currentUser?.id }));
                  lastMsg?.current?.scrollIntoView({ block: "start", behavior: "smooth" });
                }, 50)

                setUploadingFileName(null);
                setUploadingFileType(null);
              }
            }}
            ref={newMessageInput}
          />
        </div>
      </div>
      <input
        type="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={(event) => {
          if (!!event.target.files[0]) {
            saveFile(event.target.files[0])
          }
        }}
      />
    </div>
  );
};
