import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useChoices } from 'ra-core';

const useStyles = makeStyles(
  {
    checkbox: {
      height: 32,
    },
  },
  { name: 'RaCheckboxGroupInputItem' }
);

const CheckboxGroupInputItem = props => {
  const {
    classes: classesOverride,
    id,
    choice,
    onChange,
    optionText,
    optionValue,
    options,
    translateChoice,
    value,
    ...rest
  } = props;
  const classes = useStyles(props);
  const { getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  return (
    <FormControlLabel
      htmlFor={`${id}_${getChoiceValue(choice)}`}
      key={getChoiceValue(choice)}
      onChange={onChange}
      label={''}
      control={
        <Checkbox
          id={`${id}_${getChoiceValue(choice)}`}
          color="primary"
          className={classes.checkbox}
          checked={
            value

          }
          value={String(getChoiceValue(choice))}
          {...options}
          {...rest}
        />
      }
    />
  );
};

export default CheckboxGroupInputItem;
