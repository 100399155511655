import React from 'react'
import { CircularProgress } from '@material-ui/core'

interface Props {}

function BigSpinner(props: Props) {
  return (
    <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  )
}

export default BigSpinner
