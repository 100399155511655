import { makeStyles } from "@material-ui/core/styles";
import React, {
  isValidElement,
  useMemo,
  Children,
  useState,
  useEffect,
} from "react";
import { DatagridHeaderCell } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { listToTree, dataObjToArr } from "./utils";
import { INode } from "./types";
import { linkToRecord } from "ra-core";
import { Link } from "react-router-dom";
/* --- Example ---
  <List {...props} title="Clubs" pagination={null}>
    <TreeDatagrid>
      <TextField source="name" label="Name" />
      <TextField source="city" label="City" />
    </TreeDatagrid>
  </List>
*/

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: "inherit",
      textDecoration: "none",
      "&:visited": {
        color: "inherit",
      },
      "&:hover": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  }),
  { name: "RaImageInput" }
);

interface Props {
  children: React.ReactNode;
  ids?: number[];
  data?: object;
  resource?: string;
  basePath?: string;
  currentSort?: any;
  setSort?: any;
}

function TreeDatagrid(props: Props) {
  const classes = useStyles(props);

  const [openedIds, setOpenedIds] = useState<number[]>([]);

  const tree = useMemo(() => {
    const arr = dataObjToArr(props.data, props.ids);

    return listToTree(arr);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setOpenedIds(props.ids);
  }, [props.ids]);

  const handleClick = (record: INode) => () => {
    if (record.children.length === 0) {
      return null;
    }

    const exist = openedIds.includes(record.id);

    if (exist) {
      setOpenedIds(openedIds.filter((item) => item !== record.id));
    } else {
      setOpenedIds([...openedIds, record.id]);
    }
  };

  const handleSort = (field: string) => {
    if (props.currentSort.field === field) {
      props.setSort(field, props.currentSort.order === "ASC" ? "DESC" : "ASC");
    } else {
      props.setSort(field, "ASC");
    }
  };
  const RowLink = React.forwardRef((props, ref) => (
    <a ref={ref as any} className={classes.link} {...props}>
      {props.children}
    </a>
  ));
  const getRow = (record: INode, isOpened: boolean, lvl: number) => {
    const hasChildren: boolean = !!record.children.length;
    const visibleSubRows: boolean = isOpened && hasChildren;

    return (
      <>
        <TableRow key={record.id} style={{ cursor: "pointer" }}>
          {Children.map(props.children, (field, index) => {
            const isName = (field as any)?.props?.source === "name";
            return isValidElement(field) ? (
              <TableCell key={index}>
                <div
                  style={{
                    paddingLeft: isName ? 20 * lvl : 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {isName &&
                    hasChildren &&
                    (visibleSubRows ? (
                      <ArrowDropUpIcon onClick={handleClick(record)} />
                    ) : (
                      <ArrowDropDownIcon onClick={handleClick(record)} />
                    ))}
                  <Link
                    component={RowLink}
                    to={`${linkToRecord(
                      props.basePath,
                      record && record.id
                    )}/edit`}
                  >
                    {React.cloneElement(field, {
                      record,
                      basePath: field.props.basePath || props.basePath,
                      resource: props.resource,
                    })}
                  </Link>
                </div>
              </TableCell>
            ) : null;
          })}
        </TableRow>
        {visibleSubRows &&
          record.children.map((subRow) =>
            getRow(subRow, openedIds.includes(subRow.id), lvl + 1)
          )}
      </>
    );
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          {Children.map(props.children, (field, index) =>
            isValidElement(field) ? (
              <DatagridHeaderCell
                currentSort={props.currentSort}
                field={field}
                isSorting={true}
                key={field.props.source || index}
                resource={props.resource}
                onClick={() => handleSort(field.props.source)}
              />
            ) : null
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {tree.map((record) => getRow(record, openedIds.includes(record.id), 0))}
      </TableBody>
    </Table>
  );
}

export default TreeDatagrid;
