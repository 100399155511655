import React from 'react'
import { Box, Container } from '@material-ui/core'
import { useAuthenticated } from 'react-admin'
import { useSelector } from 'react-redux'
import { Title } from 'react-admin'
import CleaningCompletedByLocation from "src/resources/DashboardPage/components/CleaningCompletedByLocation";
import CleaningCompletedByUser from "src/resources/DashboardPage/components/CleaningCompletedByUser";
import InspectionCompletedByLocation from "src/resources/DashboardPage/components/InspectionCompletedByLocation";
import InspectionCompletedByUser from "src/resources/DashboardPage/components/InspectionCompletedByUser";
import IssuesByLocation from "src/resources/DashboardPage/components/IssuesByLocation";
import IssuesByUser from "src/resources/DashboardPage/components/IssuesByUser";
import LeaderBoardCleaners from "src/resources/DashboardPage/components/LeaderBoardCleaners";
import TimeToCloseIssuesByLocation from "src/resources/DashboardPage/components/TimeToCloseIssuesByLocation";
import TimeToCloseIssuesByUser from "src/resources/DashboardPage/components/TimeToCloseIssuesByUser";
import Controls from './components/Controls'
import DashboardSection from 'src/resources/DashboardPage/components/DashboardSection'
import InspectionsScore from 'src/resources/DashboardPage/components/InspectionsScore'
import { AppState } from 'src/types'

interface Props { }

function DashboardPage(props: Props) {
  useAuthenticated()

  const dashboard = useSelector((state: AppState) => state.dashboard)


  return (
    <Container>
      <Title title="Dashboard" />

      <Box m={2} />

      <Controls dashboard={dashboard} />

      <Box m={8} />

      <div>
        <DashboardSection
          left={<InspectionsScore title="INSPECTION SCORE" />}
          right={<LeaderBoardCleaners title="LEADER BOARD" />}
        />
        <DashboardSection
          left={<CleaningCompletedByLocation title="CLEANING LIST COMPLETION BY LOCATION" />}
          right={<CleaningCompletedByUser title="CLEANING LIST COMPLETION BY USER" />}
        />
        <DashboardSection
          left={<InspectionCompletedByLocation title="INSPECTION COMPLETION BY LOCATION" />}
          right={<InspectionCompletedByUser title="INSPECTION COMPLETION BY USER" />}
        />
        <DashboardSection
          left={<IssuesByLocation title="ISSUES BY LOCATION" />}
          right={<IssuesByUser title="ISSUES BY USER" />}
        />

        <DashboardSection
          left={<TimeToCloseIssuesByLocation title="TIME TO CLOSE ISSUES BY LOCATION" />}
          right={<TimeToCloseIssuesByUser title="TIME TO CLOSE ISSUES BY USER" />}
        />
      </div>

      <Box m={8} />
    </Container>
  )
}

export default DashboardPage
