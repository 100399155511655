import { format, parseISO } from "date-fns";
import React from 'react'
import PropTypes from 'prop-types'

const TimeWithTimezoneField = ({
  source, record = {},
}: any) => {
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const time = parseISO(`${currentDate}T${record[source]}`);

  return (
    <>
      {!!record[source] ? format(time, 'hh:mm a') : ''}
    </>)
}
TimeWithTimezoneField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default TimeWithTimezoneField
