import { ActionType } from 'typesafe-actions'
import ActionTypes from './const'
import * as appActions from './actions'


export interface User {
  id: number,
  name: string,
  email: string,
  phone: string,

}
export interface State {
  loading: boolean,
  user?: User,

}

const initialState: State = {
  loading: false,
  user: null
}

function registrationReducer(state = { ...initialState }, action: ActionType<typeof appActions>) {
  switch (action.type) {
    case ActionTypes.REGISTRATION:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.REGISTRATION_FINISHED:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.REGISTRATION_FAILED:
      return {
        ...state,
        loading: false,
        err: action.payload.error,
      }
    case ActionTypes.GET_USER_BY_INVITE:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.GET_USER_BY_INVITE_FINISHED:
      return {
        ...state,
        loading: false,
        user: action.payload
      }
    case ActionTypes.GET_USER_BY_INVITE_FAILED:
      return {
        ...state,
        loading: false,
        err: action.payload.error,
      }
    default:
      return state
  }
}

export default registrationReducer
