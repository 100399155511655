import { makeStyles } from "@material-ui/core/styles";
import React from 'react'
import * as colors from '@material-ui/core/colors'

interface Props {
  image: string
  name?: string
}

const useStyles = makeStyles(
  theme => ({
    link: {
      color: 'inherit',
      textDecoration: 'none',
      '&:visited': {
        color: 'inherit',
      },
      '&:hover': {
        color: 'inherit',
        textDecoration: 'none !important',
      },

    }
  }),
  { name: 'Avatar' },
)
function Avatar(props: Props) {
  const classes = useStyles(props)
  // console.log("PROPS AVA", props)
  return (
    <div
      className={classes.link}
      style={{
        minWidth: 42,
        minHeight: 42,
        width: 42,
        height: 42,
        borderRadius: '100%',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#fafafa',
        padding: 2,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: props.image ? `url(${process.env.REACT_APP_API_URL}/api/s3/uploads/${props.image})` : '',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: colors.grey[300],
          borderRadius: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!props.image && props.name && (
          <div
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: colors.grey[500],
            }}
          >
            {props.name && props.name.substr ? props.name.substr(0, 1).toUpperCase() : null}
          </div>
        )}
      </div>
    </div>
  )
}

export default Avatar
