import { ActionType } from 'typesafe-actions'
import ActionTypes from './const'
import * as appActions from './actions'
import globalActionTypes from 'src/constants'
import { IUser, IUserFilter } from './types'

export interface State {
  loading: boolean
  list: IUser[]
  userFilter: IUser
  selectUserOpen: boolean
  selectUserFilter: IUserFilter
}

const initialState: State = {
  loading: false,
  list: [],
  userFilter: null,
  selectUserOpen: false,
  selectUserFilter: null,
}

function reducer(state = { ...initialState }, action: ActionType<typeof appActions>) {
  switch (action.type) {
    case ActionTypes.FETCH_LIST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.FETCH_LIST + globalActionTypes.SUCCESS:
      const data = (action as any).payload
      console.log("Filter role", (action as any).payload);
      return {
        ...state,
        loading: false,
        list: data?.map((item: any) => (
          {
            id: item.id,
            name: item.name,
            email: item.email,
            role: item.role,
            avatar: item.avatar,
            clubs: item.clubs
          }
        )),
      }
    case ActionTypes.SET_FILTER:
      console.log("Filter filter", action.payload);
      return {
        ...state,
        userFilter: action.payload,
      }
    case ActionTypes.SELECT_USER:
      console.log("Filter user", action.payload);
      return {
        ...state,
        selectUserOpen: true,
        selectUserFilter: action.payload,
      }
    case ActionTypes.CLOSE_MODAL:
      return {
        ...state,
        selectUserOpen: false,
        selectUserFilter: null,
      }
    default:
      return state
  }
}

export default reducer
